import {
  Box,
  Typography,
  Button,
  AppBar,
  CardMedia,
  Divider,
  IconButton,
  Grid,
  Tooltip,
  LinearProgress,
  Link,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Applicant,
  ApplicationProps,
  Document,
  esignApplicationResponse,
  getDetailsByRefrenceType,
  RiskProfileMaster,
  DocumentType,
  RiskProfileMasterType,
} from '../../redux-store/types/api-types';
import {
  esignApplication,
  getApplicationDetailsWithRefId,
  sendEsignEmail,
} from '../../redux-store/actions/onBoarding';
import { useHistory } from 'react-router';
import { RootStateType } from '../../redux-store/reducers';
import { Footer, NavigationThrowErrorPopup } from '../commonComponents';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {
  APMIFlow,
  APPLICATION_LISTING_STATUS,
  ESIGN_STATUS,
  IMAGE_UPLOAD_FORMATS,
  INVESTOR_IMAGE_UPLOAD_FORMATS,
  USER_ROLES,
  YesNoMaster,
} from '../../utils/constant';
import {
  FormValidationForDisplayCompleteApplicationButtonForInvestor,
  getDocuments,
  getInvestorEditSections,
  isFormValidForSubmission,
  sendTypedDeclaration,
  updateApplication,
  validateDisclosureOfInterest,
  validateDisclusionOfExclusion,
  validateDocuments,
} from '../../redux-store/actions/application';
import { logout, showError } from '../../redux-store/actions/auth';
import { ApplicationDetailsCommonLayout } from '../ApplicationDetails/index';
import {
  InvestorCustodianTypes,
  getNationalityList,
  riskProfileMasterData,
} from '../../redux-store/actions';
import { nationaliyType } from '../../redux-store/types/mdms';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { MFTextField } from '../../lib/formik';
import { typeDeclarationScheme } from '../../utils/schema';
import React from 'react';
import { investorEditRiskProfileObj, Notes, SubHeading } from '../investors/components';
import { GetStrategiesResponseBody } from '../../redux-store/types/strategies';
import { getStrategies } from '../../redux-store/actions/strategies';
import { getApplicant } from '../ApplicationDetails/typeDeclaration';
import { DeclarationErrors, RiskProfileErrors } from '../../redux-store/middleware';
import { InvestorEditErrorObj } from '../../redux-store/reducers/investorEditSections';
import MFCheckbox from '../../lib/formik/Checkbox';
import { riskProfileDeclaration } from '../../utils/declaration';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import { FilePond } from 'react-filepond';
import { enableDisableEsignBasedOnDocId } from '../../utils/utilityFunctions';
import { useCustodianData } from '../../utils/useCustodianData';
import UseRadioGroup from '../../lib/formik/Radio';

export const Item = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  paddingTop: '10px',
  marginLeft: '2px',
  color: theme.palette.text.secondary,
  fontSize: '15px',
  fontWeight: 500,
  letterSpacing: '0.5px',
}));
export const ItemDetails = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  fontSize: '16px',
  marginLeft: '2px',
  fontWeight: 700,
  letterSpacing: '0.5px',
  maxWidth: '100%',
  overflowWrap: 'anywhere',
}));
export const Gridstyles = styled('div')(({ theme }) => ({
  paddingLeft: '10%',
  [theme.breakpoints.only('xs')]: {
    paddingLeft: 0,
  },
}));

export type Values = {
  isFileUploadAtTypedDeclaration: boolean;
  typeDeclarationFile: DocumentType[];
  typedDeclaration: string;
  fatcaDeclaration: boolean;
  riskProfileDeclaration: boolean;
  typedDeclarationCheck: string;
};

const initialValues: Values = {
  isFileUploadAtTypedDeclaration: false, // @isFileUploadAtTypedDeclaration keep this true if want to upload type declaration document
  typeDeclarationFile: [],
  typedDeclaration: '',
  fatcaDeclaration: false,
  riskProfileDeclaration: false,
  typedDeclarationCheck: 'yes',
};
export const declarationTypes: {
  FATCA: string;
  typedDeclaration: string;
  typedDeclarationCheck: string;
  isFileUploadAtTypedDeclaration: string;
} = {
  FATCA: 'fatcaDeclaration',
  typedDeclaration: 'typedDeclaration',
  typedDeclarationCheck: 'typedDeclarationCheck',
  isFileUploadAtTypedDeclaration: 'isFileUploadAtTypedDeclaration',
};
export const getDeclaration = (
  applicationToGetFatca: ApplicationProps,
  loggedInInvestor: getDetailsByRefrenceType,
  declarationType: string
): boolean | string => {
  const applicant = applicationToGetFatca?.applicants?.find(
    (applicant) => Number(applicant.applicant_type) === Number(loggedInInvestor.applicant_type)
  );
  switch (declarationType) {
    case declarationTypes.FATCA:
      return applicant?.fatcaDeclaration || initialValues.fatcaDeclaration;
    case declarationTypes.isFileUploadAtTypedDeclaration:
      return (
        applicant?.isFileUploadAtTypedDeclaration || initialValues.isFileUploadAtTypedDeclaration
      );
    case declarationTypes.typedDeclaration:
      return applicant?.typedDeclaration || initialValues.typedDeclaration;
    case declarationTypes.typedDeclarationCheck:
      return applicant?.typedDeclarationCheck || initialValues.typedDeclarationCheck;
    default:
      return false;
  }
};
const investorStatusCheck = (status: string): boolean => {
  return [
    APPLICATION_LISTING_STATUS.draft,
    APPLICATION_LISTING_STATUS.sent_to_amc_approver,
    APPLICATION_LISTING_STATUS.rejected,
  ].includes(APPLICATION_LISTING_STATUS[status]);
};
const DeclarationUpload = ({
  doc_Index,
  setValues,
  values,
  getFirstApplicantDocuments,
  setGetFirstApplicantDocuments,
  disabled,
  onFileUpload,
  onFileUploaded,
}: {
  doc_Index: number;
  setValues: (values: React.SetStateAction<Values>, shouldValidate?: boolean) => void;
  values: Values;
  getFirstApplicantDocuments: DocumentType;
  setGetFirstApplicantDocuments: React.Dispatch<React.SetStateAction<DocumentType>>;
  disabled: boolean;
  onFileUpload: () => void;
  onFileUploaded: () => void;
}): JSX.Element => {
  const [files, setFiles] = useState<any>([]);
  const authToken = useSelector((store: RootStateType) => store.auth.token);
  const { token: investorAuthToken } = useSelector((store: RootStateType) => store.investor);
  const { application } = useSelector((store: RootStateType) => store.application);
  const dispatch = useDispatch();

  const handleFileChange = async (documentId: string) => {
    setValues({
      ...values,
      typeDeclarationFile: values.typeDeclarationFile.map((_doc, index) => {
        if (index === doc_Index) {
          return {
            ..._doc,
            documentId,
          };
        }
        return _doc;
      }),
    });
    onFileUploaded();
  };
  return (
    <Grid container sx={{ alignItems: 'center', mb: 1 }}>
      <Grid item xs={12} sm={6}>
        <Typography
          sx={{
            color: 'primary.main',
            fontWeight: 500,
          }}>
          Typed Declaration Document *
        </Typography>
        <Typography sx={{ fontSize: 12 }}>Min Size: 2KB, Max Size: 2MB</Typography>
        <Typography sx={{ fontSize: 12 }}>Supports: png, jpg, jpeg</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            '.filepond--credits': { display: 'none' },
            '.filepond--label-action': {
              textDecoration: 'none',
              fontSize: '14px',
              cursor: disabled ? 'not-allowed' : 'pointer',
            },
            '.filepond--drop-label': {
              pointerEvents: 'all',
            },
            '.filepond--file': {
              color: '#000',
              flexWrap: 'wrap',
              fontWeight: 500,
            },
            // pt: 4,
          }}>
          <FilePond
            files={files}
            server={{
              process: {
                url: '/api/files/files',
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${authToken || investorAuthToken}`,
                },
                onload: (response): string => {
                  const result = JSON.parse(response);
                  result?.name && result?.id && handleFileChange(result.id);
                  return response;
                },
              },
            }}
            onaddfilestart={() => {
              onFileUpload();
            }}
            onaddfile={(error, file) => {
              if (error) {
                onFileUploaded();
              }
            }}
            onprocessfile={(error, file) => {
              if (error) {
                if (error.code === 401) {
                  dispatch(logout());
                } else {
                  onFileUploaded();
                }
              }
            }}
            onupdatefiles={(fileItems) => {
              setFiles(fileItems);
            }}
            onprocessfilerevert={() => {
              if (
                getFirstApplicantDocuments &&
                Object.keys(getFirstApplicantDocuments).length > 0
              ) {
                const { file, ...rest } = getFirstApplicantDocuments;
                setGetFirstApplicantDocuments({ ...rest });
              }

              setValues({
                ...values,
                typeDeclarationFile: values.typeDeclarationFile.map((_doc, index) => {
                  if (index === doc_Index) {
                    return {
                      ..._doc,
                      documentId: '',
                    };
                  }
                  return _doc;
                }),
              });
            }}
            name="file"
            maxFileSize={'2MB'}
            allowMultiple={false}
            maxFiles={1}
            acceptedFileTypes={INVESTOR_IMAGE_UPLOAD_FORMATS}
            labelIdle={`<span class="filepond--label-action">Upload</span>`}
            labelFileTypeNotAllowed={'Invalid file type'}
            labelMaxFileSize={'Max file size is 2 MB'}
            fileValidateTypeLabelExpectedTypesMap={{
              'image/jpeg': '.jpeg',
              'image/jpg': '.jpg',
              'image/png': '.png',
              'application/pdf': '.pdf',
            }}
            imagePreviewHeight={100}
            disabled={disabled}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export const getLoggedInInvestor = (
  application: ApplicationProps,
  loggedInInvestor: getDetailsByRefrenceType
): Partial<Applicant> | undefined => {
  return application?.applicants?.find(
    (applicant) => Number(applicant.applicant_type) === Number(loggedInInvestor.applicant_type)
  );
};
export const checkTypedDeclaration = (typedDeclarationCheck: string | undefined): boolean => {
  return YesNoMaster[typedDeclarationCheck || ''] === YesNoMaster.no;
};
export const checkConsentRejectStatus = (
  applicant: Partial<Applicant> | undefined
): { status: boolean; text: string; investorText: string } => {
  const consentObj = { status: true, text: '' };
  if (
    applicant &&
    applicant?.investorConsent &&
    checkTypedDeclaration(applicant?.typedDeclarationCheck)
  ) {
    return {
      ...consentObj,
      status: true,
      text: `Consent Rejected by ${
        applicant.applicant_type?.toString() === '2'
          ? '2nd'
          : applicant.applicant_type?.toString() === '3'
          ? '3rd'
          : 'joint'
      } applicant`,
      investorText: `The application is halted because the fee declaration was acknowledged as No by the ${
        applicant.applicant_type?.toString() === '2'
          ? '2nd'
          : applicant.applicant_type?.toString() === '3'
          ? '3rd'
          : 'joint'
      } applicant`,
    };
  }
  return { ...consentObj, status: false, text: '', investorText: '' };
};

export const checkTypeDeclarationDoc = (applicants: Partial<Applicant>[]): DocumentType => {
  return applicants
    .find((applicant) => applicant.applicant_type === '1')
    ?.documents?.find((document) => document.documentType === 'typedDeclaration') as DocumentType;
};

export default function InvestorApplication(): JSX.Element {
  const { referenceId } = useParams<{ referenceId: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { investor, auth } = useSelector((store: RootStateType) => store);
  const [application, setApplication] = useState<ApplicationProps>();
  const [tooltip, setTooltip] = useState('Click Text');
  const [loading, setLoading] = useState(false);
  // const [fatcaDetails, setFatcaDetails] = useState(initialValues);
  const applicationDetailRef = useRef<HTMLDivElement>();
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCompleteApplicationSubmitting, setIsCompleteApplicationSubmitting] = useState(false);
  const [investorDeclaration, setInvestorDeclaration] = useState(initialValues);
  const [documentsResponse, setDocumentResponse] = useState<Document>();
  const [riskProfileMasterResponse, setRiskProfileMasterResponse] = useState<RiskProfileMaster>();
  const { investorEditObj } = useSelector((store: RootStateType) => store.investorEditObj);
  const [readmoreDisclaimer, setReadmoreDisclaimer] = useState(false);
  const [getFirstApplicantDocuments, setGetFirstApplicantDocuments] = useState<DocumentType>();
  const [documentUpload, setDocumentUpload] = useState(false);
  const [nationalityMaster, setNationalityMaster] = useState<nationaliyType>();
  const [strategiesMaster, setStrategiesMaster] = useState<GetStrategiesResponseBody[]>([]);
  const [consentInfo, setConsentInfo] = useState('');
  const [issClicked, setIsClicked] = useState(false);
  const [custodianData] = useCustodianData();
  const [submitPreview, setSubmitPreview] = useState(false);
  const [errorOpen, setErrorOpen] = useState({ message: '', open: false });
  const handleErrorPopupClose = () => {
    setErrorOpen({ message: '', open: false });
  };

  const handleUpload = () => {
    setDocumentUpload(true);
  };
  const handleUploaded = () => {
    setDocumentUpload(false);
  };
  const updateInvestorLoginDeclarations = (
    _application: ApplicationProps,
    getApplicantDocuments?: DocumentType
  ) => {
    setInvestorDeclaration({
      ...investorDeclaration,
      isFileUploadAtTypedDeclaration: APMIFlow
        ? (getDeclaration(
            _application,
            referenceDetail,
            declarationTypes.isFileUploadAtTypedDeclaration
          ) as boolean) || initialValues.isFileUploadAtTypedDeclaration
        : !!getApplicant(_application?.applicants)?.isFileUploadAtTypedDeclaration ||
          initialValues.isFileUploadAtTypedDeclaration, // @isFileUploadAtTypedDeclaration keep this true if want to upload type declaration document
      typeDeclarationFile: getApplicantDocuments
        ? [getApplicantDocuments]
        : investorDeclaration.typeDeclarationFile,
      typedDeclaration: APMIFlow
        ? (getDeclaration(
            _application,
            referenceDetail,
            declarationTypes.typedDeclaration
          ) as string) ||
          (checkConsentRejectStatus(
            getLoggedInInvestor(_application as ApplicationProps, referenceDetail)
          )?.status
            ? initialValues.typedDeclaration
            : getApplicant(_application?.applicants)?.typedDeclaration ||
              initialValues.typedDeclaration)
        : getApplicant(_application?.applicants)?.typedDeclaration ||
          initialValues.typedDeclaration,
      fatcaDeclaration:
        (getDeclaration(_application, referenceDetail, declarationTypes.FATCA) as boolean) || false,
      riskProfileDeclaration: _application.riskProfileDeclaration || false,
      ...(APMIFlow && {
        typedDeclarationCheck:
          (getDeclaration(
            _application,
            referenceDetail,
            declarationTypes.typedDeclarationCheck
          ) as string) || initialValues.typedDeclarationCheck,
      }),
    });
  };

  useEffect(() => {
    (async function () {
      try {
        const _application = (await dispatch(
          getApplicationDetailsWithRefId(referenceId)
        )) as unknown as ApplicationProps;
        setApplication(_application);
        const getApplicantDocuments = _application.applicants
          .find((applicant) => applicant.applicant_type === '1')
          ?.documents?.find(
            (document) => document.documentType === 'typedDeclaration'
          ) as DocumentType;
        setGetFirstApplicantDocuments(getApplicantDocuments),
          updateInvestorLoginDeclarations(_application, getApplicantDocuments);
        const responseRisk = (await dispatch(
          riskProfileMasterData()
        )) as unknown as RiskProfileMaster;
        setRiskProfileMasterResponse(responseRisk);
        const docResponse = (await dispatch(getDocuments())) as unknown as Document;
        setDocumentResponse(docResponse);
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setNationalityMaster(nationalitiesMdmsMasters);
        const strategiesResponse = (await dispatch(
          getStrategies({
            isActive: true,
            ...(_application?.distributor_id
              ? { distributorId: _application?.distributor_id }
              : {}),
          })
        )) as unknown as GetStrategiesResponseBody[];
        setStrategiesMaster(strategiesResponse);
      } catch (e) {
        typeof e === 'string' && setConsentInfo(e);
        console.error((e as Error).message);
      } finally {
        setLoading(false);
      }
    })();
    setLoading(true);
  }, []);

  useEffect(() => {
    const { token } = investor;
    if (!token) {
      history.push(`/investment-details/${referenceId}/details`);
    }
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (
          application &&
          getLoggedInInvestor(application as ApplicationProps, referenceDetail)?.investorConsent
        ) {
          if (APMIFlow) {
            const check = checkConsentRejectStatus(
              getLoggedInInvestor(application as ApplicationProps, referenceDetail)
            );
            if (check.status) {
              throw 'Typed Fee declaration is acknowledged as No. Application will not be proceeded further';
            }
          }
          if (
            ((!APMIFlow &&
              !getApplicant(application?.applicants)?.typedDeclaration &&
              !getApplicant(application?.applicants)?.isFileUploadAtTypedDeclaration) ||
              (getApplicant(application?.applicants)?.isFileUploadAtTypedDeclaration &&
                !checkTypeDeclarationDoc(application?.applicants)?.documentId) ||
              !application?.riskProfileDeclaration) &&
            referenceDetail.applicant_type !== '1'
          ) {
            throw `Consent pending with joint applicant(s)`;
          }
          application?.applicants.map((applicant, ind) => {
            if (
              APMIFlow &&
              !checkTypedDeclaration(applicant.typedDeclarationCheck) &&
              !applicant.typedDeclaration &&
              !applicant.isFileUploadAtTypedDeclaration
            ) {
              throw `Consent pending with joint applicant(s)`;
            }
            if (!applicant.fatcaDeclaration) {
              throw `Consent pending with joint applicant(s)`;
            }
          });
          application?.applicants.map((applicant, ind) => {
            if (!applicant.investorConsent) {
              throw `Consent pending with joint applicant(s)`;
            }
            if (APMIFlow) {
              const check = checkConsentRejectStatus(applicant);
              if (check.status) {
                throw check.investorText;
              }
            }
          });
          if (!application?.signDetails?.url) {
            await isFormValidForSubmission(
              application as ApplicationProps,
              true,
              true,
              nationalityMaster,
              custodianData as InvestorCustodianTypes,
              documentsResponse,
              auth.role,
              riskProfileMasterResponse?.riskProfile as RiskProfileMasterType[],
              strategiesMaster,
              referenceDetail,
              true
            );
          }
          if (
            application?.applicants?.length > 1 &&
            (referenceDetail.applicant_type === '2' || referenceDetail.applicant_type === '3') &&
            APPLICATION_LISTING_STATUS[application?.status || ''] ===
              APPLICATION_LISTING_STATUS.sent_to_applicant1
          ) {
            throw `Esign pending with joint applicant(s)`;
          }
          if (
            application?.applicants?.length > 1 &&
            referenceDetail.applicant_type === '3' &&
            [
              APPLICATION_LISTING_STATUS.sent_to_applicant1,
              APPLICATION_LISTING_STATUS.approved_by_applicant1,
            ].includes(APPLICATION_LISTING_STATUS[application?.status || ''])
          ) {
            throw `Esign pending with joint applicant(s)`;
          }
          throw '';
        }
      } catch (e) {
        typeof e === 'string' && setConsentInfo(e);
      }
    })();
  }, [custodianData, strategiesMaster]);

  useEffect(() => {
    (async function () {
      if (application && riskProfileMasterResponse && documentsResponse) {
        try {
          await FormValidationForDisplayCompleteApplicationButtonForInvestor(
            application,
            documentsResponse,
            auth.role,
            riskProfileMasterResponse.riskProfile,
            referenceDetail,
            true,
            true,
            true,
            true
          );
        } catch (error) {
          await dispatch(getInvestorEditSections(error as unknown as InvestorEditErrorObj[]));
        }
      }
    })();
  }, [documentsResponse]);

  const handleEsign = async (values: Values) => {
    try {
      const { signDetails, id, applicants = [] } = application || {};
      if (signDetails?.url) {
        if (
          applicants?.length > 1 &&
          (referenceDetail.applicant_type === '2' || referenceDetail.applicant_type === '3') &&
          APPLICATION_LISTING_STATUS[application?.status || ''] ===
            APPLICATION_LISTING_STATUS.sent_to_applicant1
        ) {
          throw `Esign pending with joint applicant(s)`;
        }
        if (
          applicants?.length > 1 &&
          referenceDetail.applicant_type === '3' &&
          APPLICATION_LISTING_STATUS[application?.status || ''] !==
            APPLICATION_LISTING_STATUS.approved_by_applicant2
        ) {
          throw `Esign pending with joint applicant(s)`;
        }
        window.open(signDetails?.url, '_blank');
        return;
      }
      if (id) {
        setIsSubmitting(true);
        const response = (await dispatch(
          esignApplication(referenceId)
        )) as unknown as esignApplicationResponse;
        setApplication({
          ...application,
          signDetails: response,
        } as unknown as ApplicationProps);
        window.open(response?.url, '_blank');
        return;
      }
    } catch (e) {
      if (typeof e === 'string') {
        dispatch(showError(e as string));
      }
      console.error((e as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleConsentSubmit = async (values: Values) => {
    try {
      if (
        APMIFlow &&
        application &&
        (!application.isRiskProfileToBeFilledByInvestor ||
          !application.isDisclosureOfExclusionsToBeFilledByInvestor ||
          !application.isDisclosureOfInterestToBeFilledByInvestor)
      ) {
        throw new DeclarationErrors(
          'check & update Riskprofile,Disclosure Of Interest,Disclosure Of Exclusions'
        );
      }
      if (
        values.isFileUploadAtTypedDeclaration &&
        !values.typeDeclarationFile[0]?.documentId &&
        referenceDetail.applicant_type === '1'
      ) {
        throw new DeclarationErrors('Please upload the Document');
      }
      if (APMIFlow && !values.typedDeclarationCheck) {
        throw new DeclarationErrors('Please Acknowledge the typed declaration');
      }
      if (
        ((APMIFlow
          ? !checkTypedDeclaration(values.typedDeclarationCheck) && !values.typedDeclaration
          : !values.typedDeclaration) &&
          !values.isFileUploadAtTypedDeclaration &&
          (APMIFlow || referenceDetail.applicant_type === '1')) ||
        (!values.riskProfileDeclaration && referenceDetail.applicant_type === '1') ||
        !values.fatcaDeclaration
      ) {
        throw new DeclarationErrors('Declaration(s) is required');
      }
      if (
        !values.isFileUploadAtTypedDeclaration &&
        (APMIFlow || referenceDetail.applicant_type === '1') &&
        (APMIFlow
          ? !checkTypedDeclaration(values.typedDeclarationCheck) &&
            values.typedDeclaration.toLowerCase() !== text.toLowerCase()
          : values.typedDeclaration.toLowerCase() !== text.toLowerCase())
      ) {
        throw new DeclarationErrors("Typed Declaration doesn't match with sample!");
      }
      const { signDetails, id, applicants = [] } = application || {};

      if (id) {
        setIsSubmitting(true);
        const applicationResponse = ((((APMIFlow
          ? !(getDeclaration(
              application as ApplicationProps,
              referenceDetail,
              declarationTypes.typedDeclaration
            ) as string) &&
            !(getDeclaration(
              application as ApplicationProps,
              referenceDetail,
              declarationTypes.isFileUploadAtTypedDeclaration
            ) as string)
          : !getApplicant(applicants)?.typedDeclaration &&
            !getApplicant(applicants)?.isFileUploadAtTypedDeclaration) ||
          (getApplicant(applicants)?.isFileUploadAtTypedDeclaration &&
            !checkTypeDeclarationDoc(applicants)?.documentId)) &&
          (APMIFlow || referenceDetail.applicant_type === '1')) ||
        !getDeclaration(application as ApplicationProps, referenceDetail, declarationTypes.FATCA) ||
        (!application?.riskProfileDeclaration && referenceDetail.applicant_type === '1') ||
        !getLoggedInInvestor(application as ApplicationProps, referenceDetail)?.investorConsent
          ? ((await dispatch(
              updateApplication({
                body: {
                  ...application,
                  riskProfileDeclaration: values.riskProfileDeclaration,
                  applicants: applicants.map((applicant) => {
                    const doc = (applicant
                      ? applicant?.documents
                      : []) as unknown as DocumentType[];
                    if (
                      Number(applicant.applicant_type) === Number(referenceDetail.applicant_type)
                    ) {
                      return {
                        ...applicant,
                        isFileUploadAtTypedDeclaration: values.isFileUploadAtTypedDeclaration,
                        typedDeclaration:
                          (APMIFlow
                            ? !checkTypedDeclaration(values.typedDeclarationCheck)
                            : referenceDetail.applicant_type === '1') &&
                          !values.isFileUploadAtTypedDeclaration
                            ? values.typedDeclaration
                            : '',
                        ...(APMIFlow && { typedDeclarationCheck: values.typedDeclarationCheck }),
                        fatcaDeclaration: values.fatcaDeclaration,
                        investorConsent: true,
                        documents:
                          referenceDetail.applicant_type === '1' &&
                          values.isFileUploadAtTypedDeclaration
                            ? doc.map((_doc) => _doc.documentType).includes('typedDeclaration')
                              ? (doc
                                  .map((document) => {
                                    if (document.documentType === 'typedDeclaration') {
                                      if (values.typeDeclarationFile[0]?.documentId) {
                                        return {
                                          ...document,
                                          documentId: values.typeDeclarationFile[0]?.documentId,
                                          documentName: 'Typed Declaration Document',
                                        };
                                      } else if (!values.typeDeclarationFile[0]?.documentId) {
                                        return;
                                      } else {
                                        return document;
                                      }
                                    }

                                    return document;
                                  })
                                  .filter((ele) => ele) as DocumentType[])
                              : doc.concat([
                                  {
                                    documentId: values.typeDeclarationFile[0]?.documentId,
                                    documentName: 'Typed Declaration Document',
                                    documentType: 'typedDeclaration',
                                    isActive: true,
                                  },
                                ])
                            : applicant.documents?.map((_doc) =>
                                _doc.documentType === 'typedDeclaration'
                                  ? { ..._doc, isActive: false }
                                  : _doc
                              ),
                      };
                    }
                    return applicant;
                  }),
                },
                applicationId: id,
              })
            )) as unknown as ApplicationProps)
          : application) as unknown as ApplicationProps;
        setApplication(applicationResponse);
        updateInvestorLoginDeclarations(applicationResponse);
        setConsentInfo('Consent pending with joint applicant(s)');
        if (APMIFlow) {
          const check = checkConsentRejectStatus(
            getLoggedInInvestor(applicationResponse as ApplicationProps, referenceDetail)
          );
          if (check.status) {
            throw 'Typed Fee declaration is acknowledged as No. Application will not be proceeded further';
          }
        }
        const checkTypeddeclaration =
          (getApplicant(applicationResponse.applicants)?.typedDeclaration &&
            !getApplicant(applicationResponse.applicants)?.isFileUploadAtTypedDeclaration) ||
          (getApplicant(applicationResponse.applicants)?.isFileUploadAtTypedDeclaration &&
            checkTypeDeclarationDoc(applicationResponse.applicants)?.documentId);
        if (
          !APMIFlow &&
          (!checkTypeddeclaration || !applicationResponse.riskProfileDeclaration) &&
          referenceDetail.applicant_type !== '1'
        ) {
          throw `Consent is submitted. E-sign link will be sent on registered email after consent is submitted by all joint applicant(s).`;
        }
        applicationResponse?.applicants.map((applicant, ind) => {
          const check = checkConsentRejectStatus(applicant);
          if (applicant.investorConsent && check.status) {
            throw check.investorText;
          }
          if (
            APMIFlow &&
            !checkTypedDeclaration(applicant.typedDeclarationCheck) &&
            !applicant.typedDeclaration &&
            !applicant.isFileUploadAtTypedDeclaration
          ) {
            throw `Consent is submitted. E-sign link will be sent on registered email after consent is submitted by all joint applicant(s).`;
          }
          if (!applicant.fatcaDeclaration) {
            throw `Consent is submitted. E-sign link will be sent on registered email after consent is submitted by all joint applicant(s).`;
          }
        });
        applicationResponse?.applicants.map((applicant, ind) => {
          if (!applicant.investorConsent) {
            throw `Consent is submitted. E-sign link will be sent on registered email after consent is submitted by all joint applicant(s).`;
          }
          if (APMIFlow) {
            const check = checkConsentRejectStatus(applicant);
            if (check.status) {
              throw check.investorText;
            }
          }
        });
        if (ESIGN_STATUS.PENDING_SIGNATURE !== applicationResponse?.signDetails?.status) {
          ESIGN_STATUS.NOT_GENERATED === applicationResponse?.signDetails?.status
            ? await isFormValidForSubmission(
                applicationResponse as ApplicationProps,
                true,
                true,
                nationalityMaster,
                custodianData as InvestorCustodianTypes,
                documentsResponse,
                auth.role,
                riskProfileMasterResponse?.riskProfile as RiskProfileMasterType[],
                strategiesMaster,
                referenceDetail,
                true
              )
            : await isFormValidForSubmission(
                applicationResponse as ApplicationProps,
                true,
                false,
                nationalityMaster,
                custodianData as InvestorCustodianTypes,
                documentsResponse,
                auth.role,
                riskProfileMasterResponse?.riskProfile as RiskProfileMasterType[],
                strategiesMaster,
                referenceDetail,
                true
              );
        }
        const checkAllInvestorConsent = applicationResponse?.applicants
          ?.filter((applicant) => referenceDetail.applicant_type !== applicant.applicant_type)
          ?.every((_applicant) => _applicant.investorConsent);
        let sendEmailtoFistApplicant = false;
        applicationResponse?.applicants?.map((applicant) => {
          if (
            referenceDetail.applicant_type === applicant.applicant_type &&
            (APMIFlow
              ? !referenceDetail.investorConsent ||
                !checkTypedDeclaration(applicant.typedDeclarationCheck)
              : !referenceDetail.investorConsent) &&
            applicant.investorConsent &&
            checkAllInvestorConsent
          ) {
            sendEmailtoFistApplicant = true;
          }
        });
        const applicationAfterEmailSend = sendEmailtoFistApplicant
          ? ((await dispatch(sendEsignEmail(id))) as unknown as ApplicationProps)
          : applicationResponse;
        setApplication(applicationAfterEmailSend);
        setConsentInfo(
          'Thank you for providing your consent. Your document generation is in progress and e-signing link will be shared with you once it is generated'
        );
        if (
          applicationAfterEmailSend?.applicants?.length > 1 &&
          (referenceDetail.applicant_type === '2' || referenceDetail.applicant_type === '3') &&
          APPLICATION_LISTING_STATUS[applicationAfterEmailSend?.status || ''] ===
            APPLICATION_LISTING_STATUS.sent_to_applicant1
        ) {
          throw `Thank you for providing your consent. Your document generation is in progress and e-signing link will be shared with you once it is generated`;
        }
        if (
          applicationAfterEmailSend?.applicants?.length > 1 &&
          referenceDetail.applicant_type === '3' &&
          APPLICATION_LISTING_STATUS[applicationAfterEmailSend?.status || ''] !==
            APPLICATION_LISTING_STATUS.approved_by_applicant2
        ) {
          throw `Thank you for providing your consent. Your document generation is in progress and e-signing link will be shared with you once it is generated`;
        }
        throw '';
      }
    } catch (e) {
      if (e instanceof DeclarationErrors) {
        dispatch(showError((e as DeclarationErrors).message));
      }
      if (typeof e === 'string') {
        setConsentInfo(
          e ===
            'Consent is submitted. E-sign link will be sent on registered email after consent is submitted by all joint applicant(s).'
            ? 'Consent pending with joint applicant(s)'
            : e ===
              'Thank you for providing your consent. Your document generation is in progress and e-signing link will be shared with you once it is generated'
            ? `Esign pending with joint applicant(s)`
            : e
        );
        dispatch(
          showError(
            (!e
              ? 'Thank you for providing your consent. Your document generation is in progress and e-signing link will be shared with you once it is generated'
              : e) as string
          )
        );
      }
      console.error((e as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const text = APMIFlow
    ? 'I / We have read and understood the fees / charge structure'
    : 'Please review the fees mapped to your PMS account. Write on a piece of paper “I / We have read and understood the fees / charge structure”';

  const text2 = APMIFlow ? '' : 'Scan and upload the document.';

  return (
    <Formik
      initialValues={investorDeclaration}
      validate={(values: Values) => {
        // try {
        //   validateYupSchema(
        //     values,
        //     referenceDetail.applicant_type === '1' ? typeDeclarationScheme(text) : {},
        //     true,
        //     values
        //   );
        // } catch (e) {
        //   return yupToFormErrors(e);
        // }
      }}
      onSubmit={
        (
          APMIFlow
            ? getLoggedInInvestor(application as ApplicationProps, referenceDetail)
                ?.investorConsent &&
              !checkTypedDeclaration(
                getLoggedInInvestor(application as ApplicationProps, referenceDetail)
                  ?.typedDeclarationCheck
              )
            : getLoggedInInvestor(application as ApplicationProps, referenceDetail)?.investorConsent
        )
          ? handleEsign
          : handleConsentSubmit
      }
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Box
          sx={{
            bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <IconButton
            sx={{
              position: 'fixed',
              right: 0,
              bottom: 75,
              borderRadius: '5px  0 0 5px',
              '&,:hover': {
                bgcolor: 'primary.main',
              },
            }}
            onClick={() => applicationDetailRef.current?.scrollIntoView({ behavior: 'smooth' })}>
            <ArrowUpwardIcon fontSize="large" sx={{ color: 'common.white' }} />
          </IconButton>
          <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'common.white' }}>
            <Box>
              <CardMedia
                component="img"
                src="/images/spark-logo.jpg"
                alt="Logo"
                sx={{ width: '180px', m: 3 }}
              />
            </Box>
          </AppBar>
          <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
          <Box sx={{ py: 5, px: { xs: 5, sm: 10 } }} ref={applicationDetailRef}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                mb: 2,
                justifyContent: 'space-between',
                mt: 10,
                flexDirection: { xs: 'column', sm: 'row' },
              }}>
              <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main', mb: 4 }}>
                Application Details
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                }}>
                {[USER_ROLES.INVESTOR].includes(auth.role) && (
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 600,
                      color: 'primary.main',
                      mb: 4,
                      mr: { xs: 1.35, sm: 3, md: 3 },
                    }}>
                    Applicant Name:{'  '}
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 500,
                        fontSize: '18px',
                        letterSpacing: '0.4px',
                        ml: 0.5,
                        textTransform: 'capitalize',
                      }}>
                      {referenceDetail.name}
                    </Typography>
                  </Typography>
                )}
                {((investorEditObj && investorEditObj?.length > 0) ||
                  (APMIFlow &&
                    referenceDetail.applicant_type === '1' &&
                    !getLoggedInInvestor(application as ApplicationProps, referenceDetail)
                      ?.investorConsent)) &&
                  !investorStatusCheck(application?.status || '') && (
                    <IconButton>
                      <CardMedia
                        component="img"
                        src="/images/edit-icon-outlined.svg"
                        alt="Edit Icon"
                        sx={{ width: 'unset' }}
                        onClick={() => {
                          try {
                            if (
                              APMIFlow &&
                              application &&
                              (!application.isRiskProfileToBeFilledByInvestor ||
                                !application.isDisclosureOfExclusionsToBeFilledByInvestor ||
                                !application.isDisclosureOfInterestToBeFilledByInvestor)
                            ) {
                              throw 'check & update Riskprofile,Disclosure Of Interest,Disclosure Of Exclusions';
                            }
                            history.push(
                              `/investment-details/${referenceId}/investor-edit-application/${
                                APMIFlow && referenceDetail.applicant_type === '1'
                                  ? investorEditRiskProfileObj.endPoint
                                  : investorEditObj && investorEditObj[0].endPoint
                              }`,
                              {
                                id: application?.id,
                                applicant1ReferenceId: referenceDetail.esignReferenceId,
                              }
                            );
                          } catch (e) {
                            typeof e === 'string' && dispatch(showError(e as string));
                          }
                        }}
                      />
                    </IconButton>
                  )}
              </Typography>
            </Box>
            {loading && <LinearProgress />}
            {application && (
              <>
                <ApplicationDetailsCommonLayout
                  loading={loading}
                  application={application as ApplicationProps}
                />
                {(auth.role === USER_ROLES.INVESTOR ||
                  Number(auth.id) ===
                    Number(
                      getApplicant(application?.applicants as Partial<Applicant>[])?.userId
                    )) &&
                  ((APMIFlow
                    ? !getDeclaration(
                        application,
                        referenceDetail,
                        declarationTypes.typedDeclaration
                      ) &&
                      !getDeclaration(
                        application,
                        referenceDetail,
                        declarationTypes.isFileUploadAtTypedDeclaration
                      )
                    : !(
                        getApplicant(application?.applicants as Partial<Applicant>[])
                          ?.typedDeclaration ||
                        (getApplicant(application?.applicants as Partial<Applicant>[])
                          ?.isFileUploadAtTypedDeclaration &&
                          getApplicant(
                            application?.applicants as Partial<Applicant>[]
                          )?.documents?.find(
                            (document) => document.documentType === 'typedDeclaration'
                          )?.id)
                      ) && referenceDetail.applicant_type === '1') ||
                    !getDeclaration(application, referenceDetail, declarationTypes.FATCA) ||
                    (!application?.riskProfileDeclaration &&
                      referenceDetail.applicant_type === '1')) && (
                    <Box
                      sx={{
                        bgcolor: 'white',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
                        borderRadius: '10px',
                        mt: 5,
                      }}>
                      <Box sx={{ p: 2 }}>
                        <Grid
                          container
                          sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
                          <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                            Investor(s) Declaration(s)
                          </Typography>
                          <>
                            <SubHeading
                              sx={{
                                letterSpacing: 0.5 + 'px',
                                padding: { xs: '10px 10px', sm: '10px 30px' },
                              }}>
                              {APMIFlow &&
                                ['First/Solo Applicant', 'Second Applicant', 'Third Applicant'][
                                  Number(referenceDetail.applicant_type) - 1
                                ]}
                              Typed Declaration
                            </SubHeading>
                            <Grid item xs={12} sm={12}>
                              {/* <MFCheckbox
                                  name={`isFileUploadAtTypedDeclaration`}
                                  label={'Wish to upload Type Declaration Document'}
                                /> */}
                              {!values.isFileUploadAtTypedDeclaration && (
                                <Typography
                                  sx={{
                                    color: 'rgba(41, 49, 57, 0.7)',
                                    fontSize: '14px',
                                    fontWeight: 550,
                                    mb: 1,
                                    mt: 3,
                                  }}>{`${'Please read and enter the same text below'}`}</Typography>
                              )}
                              <Typography
                                component="div"
                                sx={{
                                  backgroundColor: 'rgba(51, 127, 201, 0.04)',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  padding: '10px 20px',
                                  borderRadius: '5px',
                                  alignItems: 'flex-start',
                                  mt: 0.5,
                                  mb: 1,
                                  width: '100%',
                                  boxSizing: 'border-box',
                                  userSelect: 'none',
                                }}>
                                <Typography
                                  sx={{
                                    color: 'rgba(41, 49, 57, 0.7)',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    ml: 1,
                                  }}>
                                  {`${text}`}
                                </Typography>
                                {text2 && (
                                  <Typography
                                    sx={{
                                      color: 'rgba(41, 49, 57, 0.7)',
                                      fontSize: '14px',
                                      fontWeight: 500,
                                      ml: 1,
                                      mt: 1,
                                    }}>
                                    {`${text2}`}
                                  </Typography>
                                )}
                                {/* <Typography sx={{ mt: 10, mb: 1 }}>
                                    <Tooltip title={tooltip}>
                                      <IconButton
                                        onMouseOver={() => {
                                          setTooltip('Copy Link');
                                        }}
                                        onClick={() => {
                                          setTooltip('Copied');
                                          navigator.clipboard.writeText(text);
                                        }}>
                                        <ContentCopyIcon
                                          fontSize="small"
                                          sx={{
                                            ':hover': {
                                              bgcolor: '#F4FCFC',
                                            },
                                            cursor: 'pointer',
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography> */}
                              </Typography>
                              {values.isFileUploadAtTypedDeclaration &&
                                values.typeDeclarationFile.map((doc, index) => {
                                  return (
                                    <Grid
                                      key={index}
                                      container
                                      rowSpacing={2}
                                      columnSpacing={5}
                                      display="flex"
                                      alignItems="center">
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        display="flex"
                                        flexDirection="column"
                                        mt={2}>
                                        <DeclarationUpload
                                          doc_Index={index}
                                          setValues={setValues}
                                          values={values}
                                          getFirstApplicantDocuments={
                                            getFirstApplicantDocuments as DocumentType
                                          }
                                          setGetFirstApplicantDocuments={
                                            setGetFirstApplicantDocuments as React.Dispatch<
                                              React.SetStateAction<DocumentType>
                                            >
                                          }
                                          disabled={false}
                                          onFileUpload={handleUpload}
                                          onFileUploaded={handleUploaded}
                                        />
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              {!values.isFileUploadAtTypedDeclaration && (
                                <>
                                  <MFTextField
                                    name={`typedDeclaration`}
                                    multiline
                                    rows={4}
                                    label="Typed Declaration*"
                                    placeholder="Enter Declaration"
                                    onBlur={({
                                      target: { value },
                                    }: React.FocusEvent<HTMLInputElement>) => {
                                      setValues({
                                        ...values,
                                        typedDeclaration: value.trim().split(/ +/).join(' '),
                                      });
                                    }}
                                    disabled={checkTypedDeclaration(values.typedDeclarationCheck)}
                                  />
                                  {referenceDetail.applicant_type !== '1' && (
                                    <UseRadioGroup
                                      formLabel="Acknowledge the typed declaration"
                                      name={'typedDeclarationCheck'}
                                      items={Object.keys(YesNoMaster).map((check) => ({
                                        label: YesNoMaster[check],
                                        value: check,
                                      }))}
                                    />
                                  )}
                                </>
                              )}
                            </Grid>
                          </>
                          <SubHeading
                            sx={{
                              letterSpacing: 0.5 + 'px',
                              padding: { xs: '10px 10px', sm: '10px 30px' },
                            }}>
                            {
                              ['First/Solo Applicant', 'Second Applicant', 'Third Applicant'][
                                Number(referenceDetail.applicant_type) - 1
                              ]
                            }{' '}
                            FATCA Declaration
                          </SubHeading>
                          <Grid item xs={12} sm={12}>
                            <MFCheckbox
                              // disabled={
                              //   role !== USER_ROLES.INVESTOR ||
                              //   checkEditiable(referenceDetail.applicant_type, role, index + 1)
                              // }
                              name={`fatcaDeclaration`}
                              label={
                                <Typography>
                                  <Typography>
                                    I declare / I am authorized to declare that the above
                                    information and information in the submitted documents, to be
                                    true, correct, and updated, and the submitted documents are
                                    genuine and duly executed.
                                    {!readmoreDisclaimer && (
                                      <>
                                        ..
                                        <Link
                                          sx={{ ml: 2, textDecoration: 'none', fontWeight: 600 }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setReadmoreDisclaimer(true);
                                          }}>
                                          Read More
                                        </Link>
                                      </>
                                    )}
                                  </Typography>
                                  {readmoreDisclaimer && (
                                    <>
                                      <Typography sx={{ mt: 3 }}>
                                        I acknowledge / I am authorized to acknowledge that towards
                                        compliance with tax information-sharing laws, such as FATCA
                                        / CRS, the SEBI authorized intermediary may be required to
                                        seek additional personal, tax, and beneficial owner
                                        information and certain certifications and documentation
                                        from the Account Applicant. Such information may be sought
                                        either at the time of account opening or any time
                                        subsequently. In certain circumstances (including if the
                                        intermediary does not receive a valid self-certification
                                        from the applicant) the Intermediary may be obliged to share
                                        information on Applicant’s Account with relevant tax
                                        authorities. Should there be any change in any information
                                        provided, ensure that the Applicant will intimate the
                                        Institution promptly, i.e., within 30 days.
                                      </Typography>
                                      <Typography sx={{ mt: 3 }}>
                                        Towards compliance with such laws, the Intermediary may also
                                        be required to provide information to any institutions such
                                        as withholding agents for the purpose of ensuring
                                        appropriate withholding from the account or any proceeds in
                                        relation thereto. As may be required by domestic or overseas
                                        regulators/ tax authorities, the Intermediary may also be
                                        constrained to withhold payout of any sums from the
                                        Applicant’s Account or close or suspend the Applicant’s
                                        Account.
                                      </Typography>
                                      <Typography sx={{ mt: 3 }}>
                                        I also understand that the account will be reported if any
                                        one of the aforesaid FATCA / CRS criteria for any of the
                                        Account Applicants, i.e., Primary or Joint, is met.
                                        {readmoreDisclaimer && (
                                          <Link
                                            sx={{
                                              ml: 2,
                                              textDecoration: 'none',
                                              fontWeight: 600,
                                            }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setReadmoreDisclaimer(false);
                                            }}>
                                            Read Less
                                          </Link>
                                        )}
                                      </Typography>
                                    </>
                                  )}
                                </Typography>
                              }
                              sx={{ display: 'flex', alignItems: 'flex-start' }}
                            />
                          </Grid>
                          {referenceDetail.applicant_type === '1' && (
                            <>
                              <SubHeading
                                sx={{
                                  letterSpacing: 0.5 + 'px',
                                  padding: { xs: '10px 10px', sm: '10px 30px' },
                                }}>
                                Risk Profile Declaration
                              </SubHeading>
                              <Grid item xs={12} sm={12}>
                                <MFCheckbox
                                  // disabled={
                                  //   role !== USER_ROLES.INVESTOR ||
                                  //   getDisableStatusForjointHolder(role, referenceDetail.applicant_type)
                                  // }
                                  name={`riskProfileDeclaration`}
                                  label={riskProfileDeclaration}
                                  sx={{ display: 'flex', alignItems: 'flex-start' }}
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Box>
                    </Box>
                  )}
                {enableDisableEsignBasedOnDocId(application) && !isSubmitting && !consentInfo && (
                  <Box sx={{ mt: 4 }}>
                    <Notes
                      displayContent={
                        'Consent submitted by all applicant(s). Your document generation is in progress and e-signing link will be shared with you once it is generated.'
                      }
                    />
                  </Box>
                )}
                {!isSubmitting &&
                  consentInfo &&
                  (APMIFlow
                    ? !checkTypedDeclaration(
                        getLoggedInInvestor(application as ApplicationProps, referenceDetail)
                          ?.typedDeclarationCheck
                      ) ||
                      (checkTypedDeclaration(
                        getLoggedInInvestor(application as ApplicationProps, referenceDetail)
                          ?.typedDeclarationCheck
                      ) &&
                        checkTypedDeclaration(values.typedDeclarationCheck))
                    : !APMIFlow) && (
                    <Box sx={{ mt: 4 }}>
                      <Notes displayContent={consentInfo} />
                    </Box>
                  )}
                <Box sx={{ textAlign: 'center' }}>
                  {investorEditObj &&
                    investorEditObj?.length > 0 &&
                    !getLoggedInInvestor(application, referenceDetail)?.investorConsent && (
                      <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        //type="submit"
                        loading={issClicked}
                        sx={{
                          color: 'common.white',
                          minWidth: '200px',
                          mt: consentInfo ? 1 : 4,
                          fontWeight: 600,
                          lineHeight: 1.5,
                        }}
                        onClick={async () => {
                          try {
                            if (
                              APMIFlow &&
                              application &&
                              (!application.isRiskProfileToBeFilledByInvestor ||
                                !application.isDisclosureOfExclusionsToBeFilledByInvestor ||
                                !application.isDisclosureOfInterestToBeFilledByInvestor)
                            ) {
                              throw 'check & update Riskprofile,Disclosure Of Interest,Disclosure Of Exclusions';
                            }
                            if (
                              values.isFileUploadAtTypedDeclaration &&
                              !values.typeDeclarationFile[0]?.documentId &&
                              referenceDetail.applicant_type === '1'
                            ) {
                              throw 'Please upload the Document';
                            }
                            if (APMIFlow && !values.typedDeclarationCheck) {
                              throw 'Please Acknowledge the typed declaration';
                            }
                            if (
                              ((APMIFlow
                                ? !checkTypedDeclaration(values.typedDeclarationCheck) &&
                                  !values.typedDeclaration
                                : !values.typedDeclaration) &&
                                !values.isFileUploadAtTypedDeclaration &&
                                (APMIFlow || referenceDetail.applicant_type === '1')) ||
                              (!values.riskProfileDeclaration &&
                                referenceDetail.applicant_type === '1') ||
                              !values.fatcaDeclaration
                            ) {
                              throw 'Declaration(s) is required';
                            }
                            if (
                              !values.isFileUploadAtTypedDeclaration &&
                              (APMIFlow || referenceDetail.applicant_type === '1') &&
                              (APMIFlow
                                ? !checkTypedDeclaration(values.typedDeclarationCheck) &&
                                  values.typedDeclaration.toLowerCase() !== text.toLowerCase()
                                : values.typedDeclaration.toLowerCase() !== text.toLowerCase())
                            ) {
                              throw "Typed Declaration doesn't match with sample!";
                            }
                            const { id, applicants = [] } = application || {};
                            if (id) {
                              setIsClicked(true);
                              const applicationResponse = ((((APMIFlow
                                ? !checkTypedDeclaration(
                                    getDeclaration(
                                      application as ApplicationProps,
                                      referenceDetail,
                                      declarationTypes.typedDeclarationCheck
                                    ) as string
                                  ) &&
                                  !(getDeclaration(
                                    application as ApplicationProps,
                                    referenceDetail,
                                    declarationTypes.typedDeclaration
                                  ) as string) &&
                                  !(getDeclaration(
                                    application as ApplicationProps,
                                    referenceDetail,
                                    declarationTypes.isFileUploadAtTypedDeclaration
                                  ) as string)
                                : !getApplicant(applicants)?.typedDeclaration &&
                                  !getApplicant(applicants)?.isFileUploadAtTypedDeclaration) ||
                                (getApplicant(applicants)?.isFileUploadAtTypedDeclaration &&
                                  !checkTypeDeclarationDoc(applicants)?.documentId)) &&
                                (APMIFlow || referenceDetail.applicant_type === '1')) ||
                              getDeclaration(
                                application as ApplicationProps,
                                referenceDetail,
                                declarationTypes.FATCA
                              ) ||
                              (!application?.riskProfileDeclaration &&
                                referenceDetail.applicant_type === '1')
                                ? ((await dispatch(
                                    updateApplication({
                                      body: {
                                        ...application,
                                        riskProfileDeclaration: values.riskProfileDeclaration,
                                        applicants: applicants.map((applicant) => {
                                          const doc = (applicant
                                            ? applicant?.documents
                                            : []) as unknown as DocumentType[];
                                          if (
                                            Number(applicant.applicant_type) ===
                                            Number(referenceDetail.applicant_type)
                                          ) {
                                            return {
                                              ...applicant,
                                              isFileUploadAtTypedDeclaration:
                                                values.isFileUploadAtTypedDeclaration,
                                              typedDeclaration:
                                                (APMIFlow
                                                  ? !checkTypedDeclaration(
                                                      values.typedDeclarationCheck
                                                    )
                                                  : referenceDetail.applicant_type === '1') &&
                                                !values.isFileUploadAtTypedDeclaration
                                                  ? values.typedDeclaration
                                                  : '',
                                              ...(APMIFlow && {
                                                typedDeclarationCheck: values.typedDeclarationCheck,
                                              }),
                                              fatcaDeclaration: values.fatcaDeclaration,
                                              documents:
                                                referenceDetail.applicant_type === '1' &&
                                                values.isFileUploadAtTypedDeclaration
                                                  ? doc
                                                      .map((_doc) => _doc.documentType)
                                                      .includes('typedDeclaration')
                                                    ? (doc
                                                        .map((document) => {
                                                          if (
                                                            document.documentType ===
                                                            'typedDeclaration'
                                                          ) {
                                                            if (
                                                              values.typeDeclarationFile[0]
                                                                ?.documentId
                                                            ) {
                                                              return {
                                                                ...document,
                                                                documentId:
                                                                  values.typeDeclarationFile[0]
                                                                    ?.documentId,
                                                                documentName:
                                                                  'Typed Declaration Document',
                                                              };
                                                            } else if (
                                                              !values.typeDeclarationFile[0]
                                                                ?.documentId
                                                            ) {
                                                              return;
                                                            } else {
                                                              return document;
                                                            }
                                                          }

                                                          return document;
                                                        })
                                                        .filter((ele) => ele) as DocumentType[])
                                                    : doc.concat([
                                                        {
                                                          documentId:
                                                            values.typeDeclarationFile[0]
                                                              ?.documentId,
                                                          documentName:
                                                            'Typed Declaration Document',
                                                          documentType: 'typedDeclaration',
                                                          isActive: true,
                                                        },
                                                      ])
                                                  : applicant.documents?.map((_doc) =>
                                                      _doc.documentType === 'typedDeclaration'
                                                        ? { ..._doc, isActive: false }
                                                        : _doc
                                                    ),
                                            };
                                          }
                                          return applicant;
                                        }),
                                      },
                                      applicationId: id,
                                    })
                                  )) as unknown as ApplicationProps)
                                : application) as unknown as ApplicationProps;
                              setApplication(applicationResponse);
                              history.push(
                                `/investment-details/${referenceId}/investor-edit-application/${
                                  APMIFlow && referenceDetail.applicant_type === '1'
                                    ? investorEditRiskProfileObj.endPoint
                                    : investorEditObj[0].endPoint
                                }`,
                                {
                                  id: application?.id,
                                  applicant1ReferenceId: referenceDetail.esignReferenceId,
                                }
                              );
                            }
                          } catch (e) {
                            typeof e === 'string' && dispatch(showError(e as string));
                            console.error((e as Error).message);
                          } finally {
                            setIsClicked(false);
                          }
                        }}
                        disabled={
                          investorStatusCheck(application?.status || '') ||
                          isSubmitting ||
                          documentUpload ||
                          (referenceDetail.applicant_type === '1' &&
                            application.editedByPrimaryApplicant)
                        }>
                        Next
                      </LoadingButton>
                    )}
                  {!(investorEditObj && investorEditObj?.length > 0) &&
                    ![ESIGN_STATUS.SIGNED, ESIGN_STATUS.EXPIRED].includes(
                      application?.signDetails?.status
                    ) &&
                    !loading && (
                      <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        type="submit"
                        // loading={isSubmitting}
                        sx={{
                          color: 'common.white',
                          minWidth: '200px',
                          // mb: 2,
                          mt: consentInfo ? 1 : 4,
                          fontWeight: 600,
                          lineHeight: 1.5,
                        }}
                        // onClick={handleEsign}
                        disabled={
                          investorStatusCheck(application?.status || '') ||
                          isSubmitting ||
                          submitPreview ||
                          ((getLoggedInInvestor(application as ApplicationProps, referenceDetail)
                            ?.investorConsent &&
                            consentInfo &&
                            (APMIFlow &&
                            checkTypedDeclaration(
                              getLoggedInInvestor(application as ApplicationProps, referenceDetail)
                                ?.typedDeclarationCheck
                            )
                              ? checkTypedDeclaration(values.typedDeclarationCheck)
                              : true)) as boolean) ||
                          documentUpload ||
                          enableDisableEsignBasedOnDocId(application) ||
                          (referenceDetail.applicant_type === '1' &&
                            application.editedByPrimaryApplicant)
                        }>
                        {((((!values.typedDeclaration && !values.isFileUploadAtTypedDeclaration) ||
                          (values.isFileUploadAtTypedDeclaration &&
                            !values.typeDeclarationFile[0]?.documentId) ||
                          !values.riskProfileDeclaration) &&
                          referenceDetail.applicant_type === '1') ||
                          !values.fatcaDeclaration ||
                          ((!getApplicant(application?.applicants)?.typedDeclaration ||
                            !application?.riskProfileDeclaration) &&
                            referenceDetail.applicant_type !== '1') ||
                          !application?.applicants?.every(
                            (applicant) => applicant.fatcaDeclaration
                          ) ||
                          !application?.signDetails?.url) &&
                        !getLoggedInInvestor(application as ApplicationProps, referenceDetail)
                          ?.investorConsent
                          ? 'Submit Consent'
                          : getLoggedInInvestor(application as ApplicationProps, referenceDetail)
                              ?.investorConsent && consentInfo
                          ? APMIFlow &&
                            checkTypedDeclaration(
                              getLoggedInInvestor(application as ApplicationProps, referenceDetail)
                                ?.typedDeclarationCheck
                            ) &&
                            !checkTypedDeclaration(values.typedDeclarationCheck)
                            ? 'Submit Consent'
                            : 'Consent Submitted'
                          : 'e-Sign'}
                      </LoadingButton>
                    )}
                  {APMIFlow &&
                    !getLoggedInInvestor(application as ApplicationProps, referenceDetail)
                      ?.investorConsent &&
                    referenceDetail.applicant_type === '1' && (
                      <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        loading={submitPreview}
                        sx={{
                          color: 'common.white',
                          minWidth: '200px',
                          // mb: 2,
                          mt: consentInfo ? 1 : 4,
                          fontWeight: 600,
                          lineHeight: 1.5,
                          ml: { sm: 0, md: 2 },
                          pl: 5,
                        }}
                        onClick={async () => {
                          try {
                            const { id, applicants = [] } = application || {};
                            if (id) {
                              setSubmitPreview(true);

                              (await dispatch(
                                updateApplication({
                                  body: {
                                    ...application,
                                    pmsReviewOpted: true,
                                    status: 'sent_to_amc_approver',
                                  },
                                  applicationId: id,
                                })
                              )) as unknown as ApplicationProps;
                              setErrorOpen({
                                message:
                                  'Application is sent for PMS review. An email will be sent for consent once review is completed.',
                                open: true,
                              });
                            }
                          } catch (e) {
                            typeof e === 'string' && dispatch(showError(e as string));
                            console.error((e as Error).message);
                          } finally {
                            setSubmitPreview(false);
                          }
                        }}
                        disabled={
                          investorStatusCheck(application?.status || '') ||
                          isSubmitting ||
                          !application.editedByPrimaryApplicant
                        }>
                        {' '}
                        Consent Post PMS review
                      </LoadingButton>
                    )}
                </Box>
              </>
            )}
          </Box>
          <Footer />
          <Dialog
            open={isSubmitting}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle sx={{ textAlign: 'center' }} id="alert-dialog-title">
              {'Please Wait'}
            </DialogTitle>
            <DialogContent sx={{ textAlign: 'center', px: 8 }}>
              <DialogContentText sx={{ mb: 4 }} id="alert-dialog-description">
                Don&#39;t refresh or click on back button
              </DialogContentText>
              <CircularProgress />
            </DialogContent>
          </Dialog>
          <NavigationThrowErrorPopup
            handleClose={handleErrorPopupClose}
            errorOpen={errorOpen}
            onSave={() => {
              history.push(`/investment-details/${referenceId}/details`);
            }}
          />
        </Box>
      )}
    </Formik>
  );
}
