import {
  Applicant,
  ApplicantAddressType,
  ApplicationProps,
  Bank,
  DistributorForStrategy,
  getDetailsByRefrenceType,
  JointholderRelationMaster,
  RiskProfileMasterType,
  StrategyDetailsType,
  StrategyTypeInDistributorSetup,
} from '../redux-store/types/api-types';
import {
  AMC_AUTHORISED_SIGNATORY_STATUS,
  applicantStatusMasters,
  APPLICATION_LISTING_STATUS,
  APPLICATION_TYPE,
  BloodRelations,
  FeeTypeMaster,
  performanceFeeMaster,
  NDPSMBankMaster,
  SALT,
  SALT2,
  USER_ROLES,
  YesNoMaster,
  APPLICANT_STATUS,
  APMIFlow,
} from './constant';
import { ToWords } from 'to-words';
import { individualPanRegex, nonIndividualPanRegex, numberRegex } from './regex';
import _ from 'lodash';
import { Values, updatedRiskProfile } from '../components/investors/riskProfileDetails';
import { InvestorEditErrorObj } from '../redux-store/reducers/investorEditSections';
import {
  getAuthorizedSignatoriesConsentDetails,
  getConsentDetails,
} from '../components/dashboard/applicationslist';
import { GetStrategiesResponseBody } from '../redux-store/types/strategies';
import { documentDetails } from '../components/investors/documentDetails';
import { updateNominee } from '../components/investors/nomineeDetails';
import {
  investorEditDocumentDetailsObj,
  investorEditEndpointExcludingDoc,
  investorEditEndpointIncludingDoc,
} from '../components/investors/components';
import { checkTypedDeclaration } from '../components/Investments/investor-application';

export const maskMobileNumber = (mobileNumber: string): string => {
  if (!mobileNumber) {
    return '';
  }
  return (
    mobileNumber.slice(0, 2) +
    mobileNumber.slice(2, mobileNumber.length - 2).replace(/\d/g, '*') +
    mobileNumber.slice(mobileNumber.length - 2)
  );
};

export const getApplicantName = (index: number, isNomineeOrBank?: boolean): string => {
  if (index === 1) {
    return isNomineeOrBank ? 'First' : 'First/Sole';
  } else if (index === 2) {
    return 'Second';
  } else {
    return 'Third';
  }
};

export const minDateForContributor = (): Date => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date;
};

export function getOccupationType(occupation?: string | null): boolean {
  return [
    'PVT.SECTOR SERVICE',
    'PUBLIC SECTOR',
    'GOVT.SERVICE',
    'HOUSEWIFE',
    'DEFENCE',
    'PROFESSIONAL',
    'RETIRED',
    'BUSINESS',
    'AGRICULTURE',
    'STUDENT',
    'FOREX DEALER',
    'Salaried',
    'Self Employed',
    'Self Employed Professional',
    'Retired',
    'Housewife',
    'Student',
    'Farmer',
    '',
  ].includes(occupation || '');
}

export const getFirstHolderName = (applicants: Partial<Applicant>[]): string => {
  return (
    applicants.find((applicant) => ['firstapplicant', '1'].includes(applicant.applicant_type || ''))
      ?.name || 'N/A'
  );
};

export const getApplicantType = (applicantType: string | undefined): string => {
  if (applicantType) {
    if (['firstapplicant', '1'].includes(applicantType)) {
      return '1';
    } else if (['secondapplicant', '2'].includes(applicantType)) {
      return '2';
    } else {
      return '3';
    }
  }
  return '';
};

export const formatToIndianCurrency = (value: number | null): string => {
  const options = { style: 'currency', currency: 'INR' };
  if (value) {
    return new Intl.NumberFormat('en-IN', options).format(value);
  }
  return '';
};

export function toExactSubstr(str: string): string {
  const a = str.split('.');
  if (a.length == 2) {
    return a[0].concat('.', a[1].substr(0, 2));
  }
  return str;
}

export const formatCurrency = (value?: number | null): string => {
  const formats = ['K', 'L', 'Cr'];
  let amount: any = Number(value);
  formats.some((key, i) => {
    const size = Math.pow(10, i === 0 ? 3 : 3 - 1);
    if (size <= amount) {
      amount = toExactSubstr(`${amount / size}`);
      if (amount >= 100 && i < formats.length - 1) {
        i++;
      } else {
        // amount = (amount * decPlaces) / decPlaces;
        amount += ' ' + formats[i];
        return true;
      }
    } else {
      return true;
    }
  });
  return amount;
};

export const EIGHTEEN_YEARS_IN_MILLISECONDS = 18 * 31556926 * 1000; //  18 * no of seconds in a Year * 1000

export const isMinor = (dateOfBirth = ''): boolean => {
  return new Date().getTime() - new Date(dateOfBirth).getTime() < EIGHTEEN_YEARS_IN_MILLISECONDS;
};

// eslint-disable-next-line @typescript-eslint/no-var-requires
const countryCodes = require('country-codes-list');
export const getCountryCodes = (): { label: string; key: string; countryCode: string }[] => {
  const countryCodesObject: { [key: string]: string } = countryCodes.customList(
    'countryCode',
    '{countryNameEn}: +{countryCallingCode}'
  );

  const modifiedCodes = Object.keys(countryCodesObject).map((cc) => ({
    label: countryCodesObject[cc],
    key: countryCodesObject[cc].split(':')[1].trim(),
    countryCode: cc,
  }));
  return modifiedCodes;
};

export const getAddressFields = (addressType: string): ApplicantAddressType => {
  return {
    address1: '',
    address2: '',
    address3: '',
    city: '',
    district: '',
    state: '',
    country: addressType === 'overseas' ? '' : 'INDIA',
    pincode: '',
    permanentAddressSameAsCorresponding: false,
    address_type: addressType,
    isActive: true,
    fetchedFromKRA: null,
  };
};

export const enableDisableEsignBasedOnDocId = (application: ApplicationProps) => {
  return (
    application?.applicants?.every((item) =>
      APMIFlow
        ? item.investorConsent === true && !checkTypedDeclaration(item.typedDeclarationCheck)
        : item.investorConsent === true
    ) && !application.leegalityDocumentId
  );
};

export const changeGoalVal = (
  ans: string,
  riskProfileData: updatedRiskProfile[],
  question: string
) => {
  const ansArray = ans.split('*').map((val) => {
    const index = val.indexOf('_');
    const result = val.substring(0, index);
    return result;
  });
  const valueArray = riskProfileData.find((profile) => profile.question === question)?.values;
  const labelsArray = ansArray.map((key) => {
    const item = valueArray?.find((val) => val.key === key);
    return item ? item.label : key;
  });
  return labelsArray.toString();
};

export const getAddressData = (
  addressType: string,
  addresses: Partial<ApplicantAddressType>[] | undefined
): Partial<ApplicantAddressType> => {
  return (
    addresses?.find((address) => address.address_type === addressType) ||
    getAddressFields(addressType)
  );
};

export function getRelation(relationValue?: string | null): boolean {
  return ['Mother', 'Father', 'Daughter', 'Son', 'Spouse', 'Brother', 'Sister', ''].includes(
    relationValue || ''
  );
}

export function getAmcAuthorisedMaster(num: number) {
  return Array.from({ length: num }, (v, ind) => ind + 1);
}

export const saveForLater = (
  role: string,
  id?: string,
  applicant1ReferenceId?: string | null
): string => {
  return role === USER_ROLES.INVESTOR
    ? `/investment-details/${applicant1ReferenceId}/application-details`
    : role === USER_ROLES.POAAPPROVER || role === USER_ROLES.AMCAPPROVER
    ? `/application-details/${id}`
    : '/applications';
};

export const maxAge = (dateOfBirth = ''): boolean => {
  const thisYear = new Date().getFullYear();
  return thisYear - new Date(dateOfBirth).getFullYear() > 125;
};

export function currencyConversion(num?: string | number | null): string | null {
  if (!num) return '';
  const toWords = new ToWords();
  const words = toWords.convert(Number(num), { currency: true });
  return words;
}

export function checkIfApplicationIsNonIndividual({ applicationType }: ApplicationProps): boolean {
  return applicationType === APPLICATION_TYPE.NON_INDIVIDUAL;
}

// eslint-disable-next-line
export const preventSpecialCharacters = (e: any) => {
  if (
    !((e.ctrlKey || e.metaKey) && e.keyCode == 67) &&
    !((e.ctrlKey || e.metaKey) && e.keyCode == 86) &&
    !((e.ctrlKey || e.metaKey) && e.keyCode == 88) &&
    !((e.ctrlKey || e.metaKey) && e.keyCode == 90) &&
    !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter', 'Tab'].includes(e.key) &&
    !numberRegex.test(e.key)
  ) {
    e.preventDefault();
  }
};
export function allowOnlyNumbers(e: any): void {
  if (['-', '+', 'e', 'E'].includes(e.key)) {
    e.preventDefault();
  }
}
export const permanentAddressSameAsCorresponding = (
  updatedCorrespondingAddress: Partial<ApplicantAddressType>,
  addressType: string,
  addresses: Partial<ApplicantAddressType>[] | undefined
): Partial<ApplicantAddressType> => {
  // eslint-disable-next-line
  const { address1, address2, address3, city, district, pincode, state, country, ...rest } =
    updatedCorrespondingAddress;
  return {
    ...getAddressData(addressType, addresses),
    address1: address1,
    address2: address2,
    address3: address3,
    city: city,
    pincode: pincode,
    state: state,
    district: district,
    country: country,
    address_type: addressType,
  };
};
export const _updatedAddresses = (
  updatedAddresses: {
    nationality?: string | null | undefined;
    status?: string | null | undefined;
    permanent?: Partial<ApplicantAddressType> | undefined;
    correspondence?: Partial<ApplicantAddressType> | undefined;
    overseas?: Partial<ApplicantAddressType> | undefined;
    [key: string]: string | Partial<ApplicantAddressType> | null | undefined;
  },
  applicant: Partial<Applicant>
): Partial<ApplicantAddressType>[] => {
  const { nationality, status, ...addressProps } = updatedAddresses;
  return Object.keys(addressProps)
    .map((addressType) => {
      // if (applicant.nationality?.toLowerCase() === 'indian' && addressType === 'overseas') {
      //   return {};
      // }
      if (
        addressType === 'permanent' &&
        addressProps['overseas']?.permanentAddressSameAsCorresponding
      ) {
        return permanentAddressSameAsCorresponding(
          addressProps['overseas'],
          addressType,
          applicant.addresses
        );
      }
      if (
        addressType === 'permanent' &&
        addressProps['correspondence']?.permanentAddressSameAsCorresponding &&
        !addressProps['overseas']
      ) {
        return permanentAddressSameAsCorresponding(
          addressProps['correspondence'],
          addressType,
          applicant.addresses
        );
      }
      if (
        Object.keys(addressProps).length === 1 &&
        addressProps['overseas']?.permanentAddressSameAsCorresponding
      ) {
        return [
          {
            ...permanentAddressSameAsCorresponding(
              addressProps['overseas'],
              'permanent',
              applicant.addresses
            ),
          },
          {
            ...getAddressData(addressType, applicant.addresses),
            ...(addressProps[addressType] as unknown as Partial<ApplicantAddressType>),
            address_type: addressType,
          },
        ];
      }
      if (
        Object.keys(addressProps).length === 1 &&
        addressProps['correspondence']?.permanentAddressSameAsCorresponding
      ) {
        return [
          {
            ...permanentAddressSameAsCorresponding(
              addressProps['correspondence'],
              'permanent',
              applicant.addresses
            ),
          },
          {
            ...getAddressData(addressType, applicant.addresses),
            ...(addressProps[addressType] as unknown as Partial<ApplicantAddressType>),
            address_type: addressType,
          },
        ];
      }
      return {
        ...getAddressData(addressType, applicant.addresses),
        ...(addressProps[addressType] as unknown as Partial<ApplicantAddressType>),
        address_type: addressType,
      };
    })
    .flat()
    .filter((address) => Object.keys(address).length);
};
export const applyRoleBasedStatus = (role: any): boolean => {
  return [
    USER_ROLES.RM,
    USER_ROLES.SUBDISTRIBUTOR,
    USER_ROLES.DISTRIBUTOR,
    USER_ROLES.AMCAPPROVER,
  ].includes(role);
};

export const checkKraBasedOnStatus = (status: any): boolean => {
  return ![
    APPLICATION_LISTING_STATUS.invitationexpired,
    APPLICATION_LISTING_STATUS.signed,
    APPLICATION_LISTING_STATUS.completed,
    APPLICATION_LISTING_STATUS.rejected,
  ].includes(APPLICATION_LISTING_STATUS[status]);
};

export const applicationComparison = (
  existingApplication: Partial<ApplicationProps> | null,
  updatedApplication: Partial<ApplicationProps>
): boolean => {
  return _.isEqual(existingApplication, updatedApplication);
};

export const sendApplication_Nri = (application?: ApplicationProps | null): boolean | undefined => {
  return application?.applicants.map((applicant) => applicant.status).includes('NRI');
};

export const checkForCorrespondenceAddress = (
  nationality: string | null | undefined,
  status: string | null | undefined
): boolean => {
  return (
    nationality?.toLowerCase() === 'indian' &&
    (applicantStatusMasters[status as string] === applicantStatusMasters.Individual ||
      status === APPLICANT_STATUS.INDIVIDUAL)
  );
};

export const setUpFeeCalculations = (
  commitmentAmount: number,
  setupFeePercentage: number
): { feeAmount: number; feeGst: number; totalSetupFee: number } => {
  const setupFeeAmount = (Number(commitmentAmount) * Number(setupFeePercentage)) / 100;
  const GstForSetupFee = (setupFeeAmount * 18) / 100;
  return {
    feeAmount: Number(setupFeeAmount),
    feeGst: Number(GstForSetupFee),
    totalSetupFee: Number(setupFeeAmount + GstForSetupFee),
  };
};

export const checkAddressField = (data?: string | null) => {
  if (data) {
    if (data.includes(' ,')) {
      return data.split(' ,').join(',') + ' ';
    }
    return data.includes(',') ? data + ' ' : data + ', ';
  }
  return '';
};

export const getDisableStatusForjointHolder = (role: string, applicantType: string) => {
  if (role === USER_ROLES.INVESTOR && applicantType !== '1') {
    return true;
  }
  return false;
};

export const getBankAddress = (
  address1: string | undefined,
  address2: string | undefined,
  address3: string | undefined
): string => {
  return `${address2 ? checkAddressField(address1) || '' : address1 || '' || ''}${
    address3 ? checkAddressField(address2) || '' : address2 || ''
  }${address3 || ''}`;
};

export const numToAlpMonth = (numMonth: any, month: any) => {
  numMonth?.map((monthNum: any) => {
    if (monthNum === 1) {
      month.push('Jan');
    } else if (monthNum === 2) {
      month.push('Feb');
    } else if (monthNum === 3) {
      month.push('Mar');
    } else if (monthNum === 4) {
      month.push('Apr');
    } else if (monthNum === 5) {
      month.push('May');
    } else if (monthNum === 6) {
      month.push('Jun');
    } else if (monthNum === 7) {
      month.push('Jul');
    } else if (monthNum === 8) {
      month.push('Aug');
    } else if (monthNum === 9) {
      month.push('Sep');
    } else if (monthNum === 10) {
      month.push('Oct');
    } else if (monthNum === 11) {
      month.push('Nov');
    } else if (monthNum === 12) {
      month.push('Dec');
    }
  });
};

export const removeSingleQuote = (value: string | null | undefined): any => {
  if (value && value.includes("'")) return value.split("'").join('');
  else return value;
};

export function removeEqualOperator(value = ''): string {
  const lastTwoIndex = value.length - 2;
  let encodeString = value.slice(0, lastTwoIndex);
  const slicedStr = value.slice(lastTwoIndex, value.length);
  slicedStr.split('').forEach((char) => {
    if (char !== '=') {
      encodeString += char;
    }
  });
  return encodeString;
}

export const getAge = (Dob: string | null | undefined) => {
  let age;
  if (Dob) {
    const birthDate = new Date(Dob);
    const currentDate = new Date();
    const difference = currentDate.getTime() - birthDate.getTime();
    age = difference / (1000 * 60 * 60 * 24 * 365.25);
  }
  return age;
};

export const checkSendToCheckerNDPMS = (
  showBank: boolean,
  bankFormCreated: boolean,
  checkNDPMS: boolean,
  applyRole: boolean
) => {
  if (!showBank && !bankFormCreated && checkNDPMS && applyRole) {
    return false;
  }
  if (showBank && bankFormCreated && checkNDPMS && applyRole) {
    return false;
  }
  return true;
};

export const checkOtherForExperience = (questionType: string, answer: string) => {
  return (
    questionType === 'investmentOrTradingExperiance' &&
    ['yearsInStocks', 'yearsInDerivatives', 'otherInvestmest'].includes(answer)
  );
};

export function getColor(ind: number, values: Values) {
  const profile = values.riskprofiles[ind];
  const isInvestmentOrTradingExperience = RiskExperienceQues(profile.question);
  const isAnswerEmpty = profile?.answer === '';
  const isYearsInDerivativesEmpty =
    profile?.yearsInDerivatives === '' || profile?.yearsInDerivatives === null;
  const isYearsInStocksEmpty = profile?.yearsInStocks === '' || profile?.yearsInStocks === null;
  const isOtherInvestmentEmpty =
    profile?.otherInvestmest === '' || profile?.otherInvestmest === null;
  const color = isInvestmentOrTradingExperience
    ? isAnswerEmpty && isYearsInDerivativesEmpty && isYearsInStocksEmpty && isOtherInvestmentEmpty
      ? '#131836'
      : '#57B6BA'
    : isAnswerEmpty
    ? '#131836'
    : '#57B6BA';

  return color;
}

export function checkExperienceQuestionValidation(riskprofiles: updatedRiskProfile[]) {
  return riskprofiles
    .filter((profile) => RiskExperienceQues(profile.question))
    .map((_profile) => {
      if (_profile.answer.replace(/_\d+$/, '') === 'noPrior') {
        return true;
      } else if (
        Number(_profile.yearsInDerivatives) > 0 ||
        Number(_profile.otherInvestmest) > 0 ||
        Number(_profile.yearsInStocks) > 0
      ) {
        return true;
      } else {
        return false;
      }
    })
    .every((_profile) => _profile);
}

export function checkAllQuestionsAnswered(riskprofiles: updatedRiskProfile[]) {
  return riskprofiles
    ?.map((profile) => {
      if (RiskExperienceQues(profile.question)) {
        return true;
      } else {
        return profile.answer !== '' && profile.required === 'true';
      }
    })
    ?.every((_profile) => _profile);
}

export function generateExperienceString(data: updatedRiskProfile) {
  const experience = [];

  if (data.yearsInStocks) {
    experience.push(`${data.yearsInStocks} Years in Stocks`);
  }
  if (data.yearsInDerivatives) {
    experience.push(`${data.yearsInDerivatives} Years In Derivatives`);
  }

  if (data.otherInvestmest) {
    experience.push(`${data.otherInvestmest} Years In Other Investment Related Fields`);
  }
  return experience.join(', ');
}

export const getArrayWithoutScore = (arr: string[]) => {
  return arr.map((item) => item.replace(/_\d+$/, ''));
};

export const RiskExperienceQues = (question: string) => {
  return question === 'investmentOrTradingExperiance';
};

export const checkOtherForExperienceReadOnlyView = (questionType: string, answer: string) => {
  return (
    questionType === 'investmentOrTradingExperiance' &&
    [
      'Years In Stocks',
      'Years In Derivatives',
      'Years In Other Investment Related Fields',
    ].includes(answer)
  );
};

export const callFormCreactionApi = (
  checkNdpmsFlow: boolean,
  routeForNDPSM: boolean,
  showBankIcon: boolean,
  bankAccountFormCreated: boolean,
  ndpmsSectionEdited: boolean
) => {
  if (checkNdpmsFlow) {
    if (!showBankIcon && !bankAccountFormCreated && !routeForNDPSM) {
      return true;
    }
    if (showBankIcon && bankAccountFormCreated && ndpmsSectionEdited && !routeForNDPSM) {
      return true;
    }
  }
  return false;
};

export const showSaveButton = (
  ndpmsSectionEdited: boolean,
  showBankIcon: boolean,
  bankAccountFormCreated: boolean,
  role: any,
  checkNdpmsFlow: boolean
) => {
  return (
    [USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR, USER_ROLES.RM].includes(role || '') &&
    ndpmsSectionEdited &&
    checkNdpmsFlow &&
    showBankIcon === bankAccountFormCreated
  );
};

export const turnEditOnInDoc = (
  checkRoute: boolean,
  role: any,
  checkNDPMS: boolean,
  showbank: boolean,
  bankFormcreated: boolean
) => {
  return (
    !checkRoute &&
    [USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR, USER_ROLES.RM].includes(role) &&
    checkNDPMS &&
    !showbank &&
    !bankFormcreated
  );
};
export const checkBankAccoutFieldEnableOrDisalble = (
  checkNDPMS: boolean,
  NDPSMroute: boolean,
  bankAccountFormCreated = false,
  showBankIcon = false
) => {
  return (
    checkNDPMS &&
    ((!bankAccountFormCreated && !showBankIcon) ||
      (bankAccountFormCreated && showBankIcon && !NDPSMroute))
  );
};

export const checkValuePresenceInKeyvalueMaster = (value: any, master: any[]) => {
  return master.some((item) => item.value === value);
};

export const checkNDPMSForIndusindBank = (
  AccountType: string | null | undefined,
  Bank: string | null | undefined
) => {
  return checkNDPMS(AccountType) && Bank === 'IndusInd_Bank';
};

export const checkNDPMS = (AccountType: string | null | undefined) => {
  return AccountType === 'non_discretionary';
};

export const getRiskAgeValue = (age: number | undefined) => {
  if (age) {
    age = Math.round(age);
    if (age >= 0 && age < 25) {
      return 'under25years';
    } else if (age >= 25 && age < 35) {
      return 'between25to34years';
    } else if (age >= 35 && age <= 45) {
      return 'between35to45years';
    } else {
      return 'over45years';
    }
  }
  return '';
};

export const timeLineTime = (Data: string) => {
  const timestamp = Data;
  const date = new Date(timestamp);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

export const getScore = (key: string) => {
  if (key === 'under25years') {
    return '0.1';
  } else if (key === 'between25to34years') {
    return '0.2';
  } else if (key === 'over45years') {
    return '0.4';
  } else if (key === 'between35to45years') {
    return '0.3';
  }
  return '';
};

export const checkEditiable = (roleType: string, role: string, applicantIndex: number) => {
  if (role === USER_ROLES.INVESTOR && Number(roleType) !== applicantIndex) {
    return true;
  }
  return false;
};

export function encodeBase64(value: string): string {
  const encodeValue = btoa(value);
  const encodeSubString = removeEqualOperator(encodeValue);
  const encodePayload = btoa(`${SALT}${encodeSubString}${SALT2}`);
  return encodePayload;
}

export function getNomineeRelation(relationValue?: string | null): boolean {
  return ['MOTHER', 'FATHER', 'DAUGHTER', 'SON', 'SPOUSE', 'BROTHER', 'SISTER', ''].includes(
    relationValue || ''
  );
}

export function getNames(applicants: Partial<Applicant>[]) {
  const nameList = applicants.map((e) => e.name) || [];
  if (nameList.length === 0) {
    return;
  } else if (nameList.length === 1) {
    return nameList[nameList.length - 1];
  } else {
    return nameList.slice(0, -1).join(', ') + ` & ${nameList[nameList.length - 1]}`;
  }
}

export function updatedCommitmentAmount(
  minCommitmentAmount: number,
  applicants: Partial<Applicant>[],
  data: JointholderRelationMaster
): number | void {
  if (applicants.length === 2) {
    const applicantRelation = applicants
      .map((applicant) => applicant.relationShipWithFirstApplicant)
      .filter((ele) => ele)
      ?.toString();
    const relation = BloodRelations.includes(applicantRelation) ? applicantRelation : 'Others';
    const amount = data.singleJointHolder
      .map((jointHolder) => {
        if (jointHolder.jointHolder1RelationShip === relation) {
          return (minCommitmentAmount * jointHolder.minimumAmountPercentage) / 100;
        }
      })
      .filter((ele) => ele)
      .toString();
    return Number(amount);
  }
  if (applicants.length === 3) {
    const applicantRelation = applicants
      .map((applicant) => applicant.relationShipWithFirstApplicant)
      .filter((ele) => ele);
    const relation = applicantRelation.map((_relation) =>
      BloodRelations.includes(_relation as string) ? _relation : 'Others'
    );
    const amount = data.twoJointHolders
      .map((jointHolder) => {
        if (
          relation[applicants.length - 3] === jointHolder.jointHolder1RelationShip &&
          relation[applicants.length - 2] === jointHolder.jointHolder2RelationShip
        ) {
          return (minCommitmentAmount * jointHolder.minimumAmountPercentage) / 100;
        }
      })
      .filter((ele) => ele)
      .toString();
    return Number(amount);
  }
}

export function checkStrategyBoxSelected(strategyData: StrategyDetailsType[]): boolean {
  return strategyData?.some((data) => data.isActive === true);
}

export function getTotalStrategyAmount(data: StrategyDetailsType[]): number | null {
  return data
    ?.filter((strategy) => strategy.isActive)
    ?.map((_strategy) => Number(_strategy.investmentAmount))
    ?.reduce((a: number, b: number) => a + b, 0);
}

export function checkPoaMandateHolder(applicants: Partial<Applicant>[]): boolean {
  return applicants
    .map(
      (applicant) => YesNoMaster[applicant.poaMandateHolderOutsideIndia || ''] !== YesNoMaster.yes
    )
    .every((item) => item);
}

export const futureAge = (dateOfBirth = ''): boolean => {
  return new Date(dateOfBirth) > new Date();
};

export const disableBankDetail = (
  role: any,
  status = '',
  bankAccountFormCreated = false,
  showBankIcon = false
) => {
  return (
    [USER_ROLES.AMCAPPROVER].includes(role) ||
    APPLICATION_LISTING_STATUS[status || ''] !== APPLICATION_LISTING_STATUS.draft ||
    bankAccountFormCreated !== showBankIcon
  );
};

export const getApplicantIdForInvestorPhotoCapture = (
  application: Partial<ApplicationProps> | null,
  applicant1ReferenceId: string
): string => {
  return (
    application?.applicants?.find((applicant) => applicant.referenceId === applicant1ReferenceId)
      ?.id || ''
  );
};

export const showPopBankDetail = (
  checkApplication: boolean,
  accountType = '',
  bankAccountFormCreated: boolean,
  showBankIcon: boolean,
  bankDetails: string | null,
  role: any
) => {
  return (
    !checkApplication &&
    checkNDPMS(accountType) &&
    bankAccountFormCreated &&
    showBankIcon &&
    bankDetails === NDPSMBankMaster[bankDetails || ''] &&
    [USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR, USER_ROLES.RM].includes(role || '')
  );
};

export const saveForLaterInvestorPhotoCapture = (
  referenceId: string,
  application: Partial<ApplicationProps> | null
): any => {
  return {
    routePath: `/investor-liveliness/${referenceId}/application-details`,
    routeState: {
      applicationId: application?.id,
      applicantId: getApplicantIdForInvestorPhotoCapture(application, referenceId),
    },
  };
};

export const getInvestorNamesBasedOnPhotoCatureRefId = (applicants: Partial<Applicant>[]) => {
  return applicants
    .filter((applicant) => !applicant.referenceId)
    .map((applicant) => applicant.name);
};

export function applicationDownloadStatusCheck(status: string): boolean {
  return APPLICATION_LISTING_STATUS[status] !== APPLICATION_LISTING_STATUS.completed;
}

export function auditDownloadStatusCheck(status: string): boolean {
  return [APPLICATION_LISTING_STATUS.completed, APPLICATION_LISTING_STATUS.signed].includes(
    APPLICATION_LISTING_STATUS[status]
  );
}

export function amcDownloadStatusCheck(row: ApplicationProps): boolean {
  return auditDownloadStatusCheck(row?.status) || !!row?.signedLeegalityFile?.url;
}

export const fieldValidateForInvestor = (
  roleType: string,
  applicantIndex: number,
  validateJointHolders: boolean
) => {
  if (
    validateJointHolders &&
    (Number(roleType) !== applicantIndex || Number(roleType) === applicantIndex)
  ) {
    return true;
  }
  if (!validateJointHolders && Number(roleType) === applicantIndex) {
    return true;
  }
  return false;
};

export const getInvestorRouteLinks = (
  investorEditSections: InvestorEditErrorObj[],
  currentPath: string,
  referenceDetail?: getDetailsByRefrenceType
): {
  backSectionLink: string;
  saveAndProceedSectionLink: string;
  currentRouteIndex: number;
  backSectionName: string;
} | null => {
  const editSectionRoutes =
    APMIFlow && referenceDetail?.applicant_type === '1'
      ? investorEditSections?.find(
          (menu) => menu.endPoint === investorEditDocumentDetailsObj.endPoint
        )
        ? investorEditEndpointIncludingDoc
        : investorEditEndpointExcludingDoc
      : investorEditSections.map((link) => link.endPoint);
  const getIndex = editSectionRoutes.indexOf(currentPath);
  if (getIndex === -1) {
    return null;
  } else {
    return {
      backSectionLink:
        getIndex === 0 || (APMIFlow && referenceDetail?.applicant_type === '1')
          ? ''
          : editSectionRoutes?.length === 1
          ? ''
          : editSectionRoutes[getIndex - 1],
      saveAndProceedSectionLink:
        editSectionRoutes?.length === 1 ? '' : editSectionRoutes[getIndex + 1],
      currentRouteIndex: getIndex,
      backSectionName:
        getIndex === 0 || (APMIFlow && referenceDetail?.applicant_type === '1')
          ? ''
          : editSectionRoutes?.length === 1
          ? ''
          : investorEditSections[getIndex - 1].tabText,
    };
  }
};
export const documentNameValidationCheckForMultipleDocumentsMandatory = (documentName: string) => {
  return [
    // 'Aadhaar Card',
    'Voter ID Card',
    'Passport',
    'Aadhar Card',
    "Voter's ID",
    // 'Aadhaar Card(Masked by User)',
    // 'Aadhaar Card(Masked by System)',
    'Passport Copy (1st and last page)',
  ].includes(documentName);
};

export const isBankDetailsChanged = (
  initialValues: string | null | undefined,
  currentValues: string | null | undefined
) => {
  return (
    NDPSMBankMaster[initialValues || ''] === NDPSMBankMaster.None &&
    NDPSMBankMaster[currentValues || ''] === NDPSMBankMaster.IndusInd_Bank
  );
};

export const CheckRegenrationButtonDisable = (application: ApplicationProps) => {
  if (
    application &&
    ((!checkIfApplicationIsNonIndividual(application) &&
      getConsentDetails(application?.applicants).consentStatus ===
        APPLICATION_LISTING_STATUS.completed) ||
      (checkIfApplicationIsNonIndividual(application) &&
        application?.groups &&
        application?.groups.length > 0 &&
        getAuthorizedSignatoriesConsentDetails(application?.groups).consentStatus ===
          APPLICATION_LISTING_STATUS.completed)) &&
    !application?.leegalityDocumentId
  ) {
    return false;
  }
  return true;
};

export const isValidPan = (pan: string): boolean =>
  [individualPanRegex, nonIndividualPanRegex].some((pattern) => pattern.test(pan));

export const documentTypeValidationCheckForDocumentNumberDocumentExpiryDate = (
  documentType: string
): boolean => {
  return ['identityProof', 'addressProof', 'correspondenceAddress'].includes(documentType);
};

export const documentTypeValidationCheckForDocumentNumberDocumentExpiryDateNominee = (
  documentType: string
): boolean => {
  return ['guardianIdProof', 'nomineeIdProof'].includes(documentType);
};

export const documentNameValidationCheckForDocumentExpiryDate = (docName: string): boolean => {
  return ['Passport', 'Driving License'].includes(docName);
};

export const isSecurityTransfer = (modeOfPayment: string): boolean => {
  return modeOfPayment?.includes('securitiesTransfer');
};

export const checkDocPresent = (documentsList: documentDetails[]) => {
  return documentsList.some((docs) => docs.documentId);
};

export const DisplayAddress = (
  address1: string | null | undefined = '',
  address2: string | null | undefined = '',
  address3: string | null | undefined = '',
  city: string | null | undefined = '',
  state: string | null | undefined = '',
  country: string | null | undefined = '',
  pincode: string | null | undefined = ''
): string => {
  return `${checkAddressField(address1)}${checkAddressField(address2)}${checkAddressField(address3)}
      ${checkAddressField(city)}${checkAddressField(state)}${checkAddressField(
    country
  )}${pincode}`.trim();
};

export function findPerformanceKeyByValue(StretegyId: string) {
  return (
    Object.values(performanceFeeMaster).find((value) => value.StretegyId === StretegyId)
      ?.performenceFee || '-'
  );
}

export const sortList = (Ids: string[] | number[], _Ids: number[] | string[]) => {
  // Create a copy of the original list
  const sortedList = [..._Ids];
  // Loop through the Ids array
  for (let i = 0; i < Ids.length; i++) {
    // Get the current element
    const element = Ids[i];
    // Find the index of the element in the original list
    const index = sortedList.indexOf(element);
    // If the element is found and is not in the correct position
    if (index !== -1 && index !== i) {
      // Remove the element from the original list
      sortedList.splice(index, 1);
      // Insert the element at the correct position in the original list
      sortedList.splice(i, 0, element);
    }
  }
  // Return the sorted list
  return sortedList as string[] | number[];
};

export const sortStrategyPerformenceFee = (
  _DistributorPerformenceFee: (DistributorForStrategy | StrategyTypeInDistributorSetup)[],
  distributorIds: (number | string)[]
) => {
  const unmatchedPerformenceFee = _DistributorPerformenceFee.filter(
    (item) => !distributorIds.includes(item.id.toString())
  );

  const matchedPerformenceFee = _DistributorPerformenceFee.filter((item) =>
    distributorIds.includes(item.id.toString())
  );

  matchedPerformenceFee.sort(
    (a, b) => distributorIds.indexOf(a.id.toString()) - distributorIds.indexOf(b.id.toString())
  );

  return [...matchedPerformenceFee, ...unmatchedPerformenceFee] as (
    | DistributorForStrategy
    | StrategyTypeInDistributorSetup
  )[];
};

export const ManagementFeePercentage = (ManagementFee?: string | number | null) => {
  return ManagementFee || ManagementFee == 0 ? ManagementFee + '%' : '';
};

export const feeTypeMasterFunc = (strategy: GetStrategiesResponseBody) =>
  Object.keys(FeeTypeMaster)
    ?.filter(
      (item) =>
        (FeeTypeMaster[item] !== FeeTypeMaster.hybrid && !strategy?.performanceFeeHybrid) ||
        strategy?.performanceFeeHybrid
    )
    ?.map((fee) => {
      return { label: FeeTypeMaster[fee], value: fee };
    });

export const getSystematicPlan = (data: Partial<RiskProfileMasterType>) => {
  return data?.values?.find((_data) => _data.key === 'notApplicable');
};
export const isApplicantNRI = (status: string): boolean => {
  return applicantStatusMasters[status || ''] === applicantStatusMasters.NRI;
};

export const isCustodianICICI = (custodian: string): boolean => custodian === 'ICICI';

export const isCustodianICICIOrHDFC = (custodian: string | null): boolean =>
  ['ICICI', 'HDFC'].includes(custodian || '');

export const isCustodianHDFC = (custodian: string | null): boolean =>
  ['HDFC'].includes(custodian || '');

export function isPendingByAMCAuthorisedSignatory(status: string): boolean {
  return AMC_AUTHORISED_SIGNATORY_STATUS.includes(status);
}

export const isBankAccountProofUploadedFromBankSection = (
  ind: number,
  documentType: string,
  banks: Bank[]
): boolean => {
  return !!(
    documentType === 'bankAccountProof' &&
    ind === 0 &&
    banks?.find((bank) => bank.defaultBankAccount)?.documentId
  );
};

export const isCustodianNuvuma = (custodian: string): boolean => ['Nuvama'].includes(custodian);

export const checkValidationBasedOnDate = (
  date: string | number | Date,
  validationStartDate: string
): boolean => {
  if (!date) return false;
  const dateForCompare = date ? new Date(date) : new Date();
  const dateForApplyValidations = new Date(validationStartDate);
  return dateForCompare.getTime() >= dateForApplyValidations.getTime();
};

export const nomineeResidualSecuritySno = (
  nominees: updateNominee[],
  nomineeResidualSecurities: string
): string => {
  const updatedNominees = nominees?.filter((item) => item.isActive) || [];
  return (
    nomineeResidualSecurities ||
    (updatedNominees?.length === 1
      ? updatedNominees[0]?.sno
      : updatedNominees.find((item) => item.nomineeResidualSecurity)?.sno
    )?.toString() ||
    '' ||
    ''
  );
};

export const convertToRoman = (num: number): string => {
  const romanNumerals = [
    { value: 1000, numeral: 'm' },
    { value: 900, numeral: 'cm' },
    { value: 500, numeral: 'd' },
    { value: 400, numeral: 'cd' },
    { value: 100, numeral: 'c' },
    { value: 90, numeral: 'xc' },
    { value: 50, numeral: 'l' },
    { value: 40, numeral: 'xl' },
    { value: 10, numeral: 'x' },
    { value: 9, numeral: 'ix' },
    { value: 5, numeral: 'v' },
    { value: 4, numeral: 'iv' },
    { value: 1, numeral: 'i' },
  ];

  let roman = '';

  for (const { value, numeral } of romanNumerals) {
    while (num >= value) {
      roman += numeral;
      num -= value;
    }
  }

  return roman;
};
const replaceUndefined = (obj: any) => {
  for (const key in obj) {
    if (obj[key] === 'undefined') {
      obj[key] = undefined;
    } else if (typeof obj[key] === 'object') {
      replaceUndefined(obj[key]); //works for object,nested objects
    } else if (Array.isArray(obj[key])) {
      obj[key].forEach((item: any) => {
        if (typeof item === 'object') {
          replaceUndefined(item); //works for array, nested Arrays
        }
      });
    }
  }
};
export default function trimOnPayload(payload: any): any {
  const stringifyObj = JSON.stringify(payload, (key, value) => {
    if (typeof value === 'string') {
      return value.trim();
    }
    //JSON.stringify removing undefined property, for keeping that we need to convert undefined value to string
    if (typeof value === 'undefined') {
      return 'undefined';
    }
    return value;
  });
  const parsedObj = JSON.parse(stringifyObj);
  replaceUndefined(parsedObj); //reverting string form of undefined to actual undefined value
  return parsedObj;
}
