import { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Grid, Hidden, LinearProgress } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useDispatch } from 'react-redux';
import { SubHeading } from '../investors/components';
import { Location } from 'history';

import { addOfficer, getOfficerById, updateOfficer } from '../../redux-store/actions/strategies';
import { addStrategyOffiersValidation } from '../../utils/schema';
import { StrategyAndOfficersTypeManagement } from '../../utils/constant';
import { strategyOfficersDetails } from '../../redux-store/types/api-types';

const initialValues: strategyOfficersDetails = {
  name: '',
  email: '',
  phone: '',
  type: '',
};

export default function AddOfficer({
  location,
}: {
  location: Location<{ officersType: string; officerId: string }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [strategyOfficerInitial, setStrategyOfficerInitial] = useState(initialValues);
  const { officersType, officerId } = location.state || {};

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);

        if (officerId) {
          const GetStrategyDetail = (await dispatch(
            getOfficerById(officerId)
          )) as unknown as strategyOfficersDetails;
          const { name, email, phone, type } = GetStrategyDetail || {};
          setStrategyOfficerInitial({
            ...strategyOfficerInitial,
            name,
            email,
            phone,
            type,
          });
        } else {
          setStrategyOfficerInitial({
            ...strategyOfficerInitial,
            type: officersType,
          });
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: strategyOfficersDetails) => {
    try {
      if (!officerId) {
        await dispatch(addOfficer(values));
      } else {
        await dispatch(updateOfficer(Number(officerId), values));
      }
      history.push(officerId ? 'officer-details' : 'strategy-list', {
        officersType,
        officerId: officerId,
      });
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={strategyOfficerInitial}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={addStrategyOffiersValidation(officersType)}>
        {({ handleSubmit, values, setValues }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(officerId ? 'officer-details' : 'strategy-list', {
                      officersType: officersType,
                      officerId: officerId,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  Officers Setup
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(officerId ? 'officer-details' : 'strategy-list', {
                      officersType: officersType,
                      officerId: officerId,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {officerId
                  ? `Edit ${StrategyAndOfficersTypeManagement[officersType || '']}`
                  : `Add ${StrategyAndOfficersTypeManagement[officersType || '']}`}
              </Typography>
            </Box>

            {loading ? (
              <LinearProgress sx={{ mt: 2 }} />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                  <SubHeading
                    sx={{
                      color: 'common.blue',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                      ml: { xs: 3.5 },
                    }}>
                    {`${StrategyAndOfficersTypeManagement[officersType || '']} Details`}
                  </SubHeading>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="name" label="Name *" placeholder="Enter Name" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="email" label="Email Id *" placeholder="Enter Email Id" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="phone"
                      label={`Mobile/Landline Number ${
                        StrategyAndOfficersTypeManagement[officersType || ''] ===
                        StrategyAndOfficersTypeManagement.compliance
                          ? '*'
                          : ''
                      }`}
                      placeholder="Enter Mobile/Landline Number"
                    />
                  </Grid>
                </Grid>

                <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                  <MFSubmitButton label="Save" />
                </Box>
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
