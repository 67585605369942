import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Grid,
  Input,
  Typography,
} from '@mui/material';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { FormControl } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { showError } from '../../redux-store/actions/auth';
import MFCheckbox from '../../lib/formik/Checkbox';
import UseRadioGroup, { UseRadioGroupTransparent } from '../../lib/formik/Radio';
import { MFTextField } from '../../lib/formik';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { riskProfileScheme } from '../../utils/schema';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  InvestorEditRouteLinkObj,
  profileType,
  RiskProfile,
  RiskProfileMaster,
  RiskProfileMasterType,
} from '../../redux-store/types/api-types';
import { RootStateType } from '../../redux-store/reducers';
import {
  RiskExperienceQues,
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  checkAllQuestionsAnswered,
  checkExperienceQuestionValidation,
  checkIfApplicationIsNonIndividual,
  checkOtherForExperience,
  fieldValidateForInvestor,
  getAge,
  getArrayWithoutScore,
  getColor,
  getDisableStatusForjointHolder,
  getInvestorRouteLinks,
  getRiskAgeValue,
  getScore,
  getSystematicPlan,
  saveForLater,
  saveForLaterInvestorPhotoCapture,
} from '../../utils/utilityFunctions';
import { updateApplication } from '../../redux-store/actions/application';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  APMIFlow,
  SHOW_SECTION_FILLED_BY_INVESTOR_CHECKBOX,
  USER_ROLES,
} from '../../utils/constant';
import { useSnackbar } from 'notistack';
import { riskProfileMasterData } from '../../redux-store/actions';
import { riskProfileDeclaration, sectionFilledByInvestor } from '../../utils/declaration';
import { fontSize } from '@mui/system';
import { ConfirmationDialog } from '../commonComponents';
import { InvestorEditErrorObj } from '../../redux-store/reducers/investorEditSections';

export type updatedRiskProfile = RiskProfile &
  RiskProfileMasterType & {
    otherValue?: string;
    InvestmentGoal?: string[];
    yearsInStocks?: string;
    otherInvestmest?: string;
    yearsInDerivatives?: string;
    scoreCal?: number;
  };

export type Values = {
  isRiskProfileToBeFilledByInvestor: boolean;
  totalRiskProfileScore: number;
  riskprofiles: updatedRiskProfile[];
  saveType: string;
  riskProfileDeclaration: boolean;
};

export const RiskProfileObj: updatedRiskProfile = {
  question: '',
  answer: '',
  scoreText: '',
  otherValue: '',
  yearsInStocks: '',
  yearsInDerivatives: '',
  otherInvestmest: '',
  key: '',
  displayText: '',
  InvestmentGoal: [],
  scoreCal: 0,
};

const initialValues: Values = {
  riskProfileDeclaration: true,
  isRiskProfileToBeFilledByInvestor: APMIFlow ? true : false,
  totalRiskProfileScore: 0,
  riskprofiles: [],
  saveType: 'save and proceed',
};

function RiskDeclaration({
  data,
  values,
  setValues,
  setTotalScore,
  totalScore,
  disabled,
}: {
  data: updatedRiskProfile[];
  values: Values;
  setValues: FormikHelpers<Values>['setValues'];
  setTotalScore: any;
  totalScore: any;
  disabled: boolean;
}): JSX.Element {
  const [expanded, setExpanded] = React.useState<string | false>('');
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);

  useEffect(() => {
    setExpanded(disabled ? '' : expanded);
  }, [disabled]);

  const _handleSelection = async (
    ind: number,
    selectedOptionObj: any,
    InvestmentGoal: string[],
    scoreCal: string | number
  ) => {
    const index = InvestmentGoal?.indexOf(
      `${selectedOptionObj.key}_${selectedOptionObj.score}` as string
    );
    const investmentGoalSelect =
      index === -1
        ? [...InvestmentGoal, `${selectedOptionObj.key}_${selectedOptionObj.score}`]
        : InvestmentGoal?.filter(
            (goal: any) => goal != (`${selectedOptionObj.key}_${selectedOptionObj.score}` as string)
          );

    const total = investmentGoalSelect?.includes(
      `${selectedOptionObj.key}_${selectedOptionObj.score}`
    )
      ? Number(scoreCal) + Number(selectedOptionObj.score)
      : Number(scoreCal) - Number(selectedOptionObj.score);

    setValues({
      ...values,
      riskprofiles: values.riskprofiles.map((risk, index) => {
        if (index === ind) {
          return {
            ...risk,
            answer: investmentGoalSelect?.toString().replace(/,/g, '*'),
            scoreText: total.toFixed(1)?.toString() || '',
            InvestmentGoal: investmentGoalSelect,
            scoreCal: total,
            yearsInDerivatives: getArrayWithoutScore(investmentGoalSelect).includes('noPrior')
              ? ''
              : risk.yearsInDerivatives,
            otherInvestmest: getArrayWithoutScore(investmentGoalSelect).includes('noPrior')
              ? ''
              : risk.otherInvestmest,
            yearsInStocks: getArrayWithoutScore(investmentGoalSelect).includes('noPrior')
              ? ''
              : risk.yearsInStocks,
          };
        }
        return risk;
      }),
    });
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Box sx={{ width: '100%' }}>
      {data?.map((dataValues: any, ind: any) => {
        return (
          <>
            <Accordion
              sx={{ boxShadow: 'none', mb: 3, '&::before': { display: 'none' } }}
              key={ind}
              expanded={expanded === `panel${ind}`}
              onChange={handleChange(`panel${ind}`)}
              disabled={disabled}>
              <AccordionSummary
                sx={{
                  bgcolor: 'rgba(221, 234, 243, 0.2)',
                  borderRadius: '5px',
                  '&.Mui-expanded': { minHeight: 'unset' },
                  '& .MuiAccordionSummary-content': { '&.Mui-expanded': { my: 2 } },
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${ind}d-content`}
                id={`panel${ind}d-header`}>
                <Typography
                  sx={{
                    color: getColor(ind, values),
                    fontSize: 14,
                    fontWeight: 600,
                  }}>
                  {`${ind + 1}. ${dataValues.displayText} ${
                    dataValues.required === 'true' && !disabled
                      ? APMIFlow
                        ? values.isRiskProfileToBeFilledByInvestor &&
                          fieldValidateForInvestor(referenceDetail.applicant_type, 1, false)
                          ? '*'
                          : ''
                        : '*'
                      : ''
                  }`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ pb: 0 }}>
                {['radio', 'multiSelectCheckBox'].includes(dataValues.questionType) && (
                  <>
                    {dataValues.isMultiselect === 'false' && (
                      <UseRadioGroupTransparent
                        name={`riskprofiles.${ind}.answer`}
                        items={dataValues.values.map((profile: any) => ({
                          label: profile.label,
                          value: profile.key,
                        }))}
                        disabled={getDisableStatusForjointHolder(
                          role,
                          referenceDetail.applicant_type
                        )}
                        onChange={(e) => {
                          let score;
                          setValues({
                            ...values,
                            riskprofiles: values.riskprofiles.map((risk, index) => {
                              if (index === ind) {
                                score = risk.values?.filter((risk) => {
                                  return risk.key === e.target.value;
                                })?.[0].score;
                                setTotalScore(totalScore + Number(score));
                                return {
                                  ...risk,
                                  answer: e.target.value,
                                  scoreText: score?.toString() || '',
                                };
                              }
                              return risk;
                            }),
                          });
                        }}
                        sx={{
                          '& .MuiFormGroup-root': { flexDirection: 'column' },
                          '& .MuiFormControlLabel-label': { fontWeight: 400 },
                        }}
                      />
                    )}
                    {dataValues.isMultiselect === 'true' &&
                      dataValues.values.map((risk: any) => {
                        return (
                          <>
                            <FormControlLabel
                              key={dataValues.key}
                              value={dataValues.key}
                              sx={{
                                width: '100%',
                                position: 'relative',
                                pb: 0,
                                ml: 0.2,
                                '.MuiFormLabel-root ': {
                                  color: 'primary.main',
                                  fontWeight: 600,
                                  mb: 0.5,
                                },
                                '.MuiFormControlLabel-root': {
                                  width: '100%',
                                  alignItems: 'flex-start',
                                  '.MuiTypography-root': {
                                    color: 'text.primary',
                                    fontWeight: 500,
                                    fontSize: 14,
                                    overflow: { xs: 'unset', md: 'hidden' },
                                    whiteSpace: { xs: 'unset', md: 'nowrap' },
                                    textOverflow: { xs: 'unset', md: 'ellipsis' },
                                    wordBreak: { xs: 'break-word', md: 'unset' },
                                  },
                                  '.MuiRadio-root,.MuiCheckbox-root ,.Mui-checked': {
                                    color: 'text.primary',
                                  },
                                  '.MuiRadio-root,.MuiCheckbox-root ': { p: 0, mr: 0.5 },
                                },
                                '.MuiAccordionDetails-root': {
                                  paddingTop: 0,
                                },
                                '.MuiAccordionSummary-content.Mui-expanded': {
                                  marginBottom: '10px',
                                },
                              }}
                              onChange={() => {
                                _handleSelection(
                                  ind,
                                  risk,
                                  dataValues.InvestmentGoal,
                                  dataValues.scoreCal
                                );
                              }}
                              disabled={getDisableStatusForjointHolder(
                                role,
                                referenceDetail.applicant_type
                              )}
                              control={
                                <Checkbox
                                  size="small"
                                  checked={dataValues.InvestmentGoal?.map((goal: any) =>
                                    goal.toString()
                                  ).includes(`${risk.key}_${risk.score}`)}
                                />
                              }
                              label={risk.label}
                              title={risk.label}
                            />
                            {RiskExperienceQues(values.riskprofiles[ind]?.question) && (
                              <>
                                <Grid
                                  xs={12}
                                  sm={12}
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    mt: 2,
                                  }}>
                                  <Input
                                    value={values.riskprofiles[ind].yearsInStocks}
                                    size="small"
                                    sx={{
                                      width: { md: '45px', xs: '45px' },
                                      ml: 2,
                                      mb: 4,
                                      '& ::placeholder': {
                                        textTransform: 'none',
                                        fontSize: '0.9rem',
                                      },
                                      'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
                                        {
                                          WebkitAppearance: 'none',
                                          margin: 0,
                                        },
                                      'input[type=number]': { MozAppearance: 'textfield' },
                                    }}
                                    type="number"
                                    onKeyDown={(e) => {
                                      allowOnlyNumbers(e);
                                    }}
                                    disabled={getArrayWithoutScore(
                                      dataValues.InvestmentGoal?.map((goal: any) => goal.toString())
                                    ).includes('noPrior')}
                                    onChange={(event: any) => {
                                      setValues({
                                        ...values,
                                        riskprofiles: values.riskprofiles.map((risk, index) => {
                                          if (index === ind) {
                                            return {
                                              ...risk,
                                              yearsInStocks: event.target.value,
                                            };
                                          }
                                          return risk;
                                        }),
                                      });
                                    }}
                                    onFocus={(e) =>
                                      e.target.addEventListener('wheel', (e) => e.preventDefault())
                                    }
                                  />
                                  <Typography
                                    sx={{
                                      ml: 0.5,
                                      mb: 4,
                                      color: 'text.primary',
                                    }}>
                                    Years in Stocks
                                  </Typography>
                                </Grid>
                                <Grid
                                  xs={12}
                                  sm={6}
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',

                                    mt: 2,
                                  }}>
                                  <Input
                                    value={values.riskprofiles[ind].yearsInDerivatives}
                                    size="small"
                                    sx={{
                                      width: { md: '45px', xs: '45px' },
                                      ml: 2,
                                      mb: 4,
                                      '& ::placeholder': {
                                        textTransform: 'none',
                                        fontSize: '0.9rem',
                                      },
                                      'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
                                        {
                                          WebkitAppearance: 'none',
                                          margin: 0,
                                        },
                                      'input[type=number]': { MozAppearance: 'textfield' },
                                    }}
                                    type="number"
                                    onKeyDown={(e) => {
                                      allowOnlyNumbers(e);
                                    }}
                                    disabled={getArrayWithoutScore(
                                      dataValues.InvestmentGoal?.map((goal: any) => goal.toString())
                                    ).includes('noPrior')}
                                    onChange={(event: any) => {
                                      // setMinValue(event.target.value);
                                      setValues({
                                        ...values,
                                        riskprofiles: values.riskprofiles.map((risk, index) => {
                                          if (index === ind) {
                                            return {
                                              ...risk,
                                              yearsInDerivatives: event.target.value,
                                            };
                                          }
                                          return risk;
                                        }),
                                      });
                                    }}
                                    onFocus={(e) =>
                                      e.target.addEventListener('wheel', (e) => e.preventDefault())
                                    }
                                  />
                                  <Typography
                                    sx={{
                                      ml: 0.5,
                                      mb: 4,
                                      color: 'text.primary',
                                    }}>
                                    Years In Derivatives
                                  </Typography>
                                </Grid>
                                <Grid
                                  xs={12}
                                  sm={12}
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    mt: 2,
                                  }}>
                                  <Input
                                    value={values.riskprofiles[ind].otherInvestmest}
                                    size="small"
                                    sx={{
                                      width: { md: '45px', xs: '45px' },
                                      ml: 2,
                                      mb: 4,
                                      '& ::placeholder': {
                                        textTransform: 'none',
                                        fontSize: '0.9rem',
                                      },
                                      'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
                                        {
                                          WebkitAppearance: 'none',
                                          margin: 0,
                                        },
                                      'input[type=number]': { MozAppearance: 'textfield' },
                                    }}
                                    disabled={getArrayWithoutScore(
                                      dataValues.InvestmentGoal?.map((goal: any) => goal.toString())
                                    ).includes('noPrior')}
                                    type="number"
                                    onKeyDown={(e) => {
                                      allowOnlyNumbers(e);
                                    }}
                                    onChange={(event: any) => {
                                      // setMinValue(event.target.value);
                                      setValues({
                                        ...values,
                                        riskprofiles: values.riskprofiles.map((risk, index) => {
                                          if (index === ind) {
                                            return {
                                              ...risk,
                                              otherInvestmest: event.target.value,
                                            };
                                          }
                                          return risk;
                                        }),
                                      });
                                    }}
                                    onFocus={(e) =>
                                      e.target.addEventListener('wheel', (e) => e.preventDefault())
                                    }
                                  />
                                  <Typography
                                    sx={{
                                      ml: 1,
                                      mb: 4,
                                      color: 'text.primary',
                                    }}>
                                    Years In Other Investment Related Fields
                                  </Typography>
                                </Grid>
                              </>
                            )}
                          </>
                        );
                      })}
                  </>
                )}
                {/* {checkOtherForExperience(
                  values.riskprofiles[ind]?.question,
                  values.riskprofiles[ind]?.answer
                ) && (
                  <Grid xs={12} sm={6}>
                    <MFTextField
                      name={`riskprofiles.${ind}.otherValue`}
                      placeholder={`Enter here`}
                      disabled={getDisableStatusForjointHolder(
                        role,
                        referenceDetail.applicant_type
                      )}
                      type="number"
                      sx={{
                        mb: 0,
                        '&.MuiInputBase-root': {
                          position: 'relative',
                          border: 0,
                          borderBottom: '1px solid #DDEAF3',
                          fontSize: 16,
                        },
                      }}
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      endAdornment={dataValues.key === 'indicative%ofTotalInvestment' ? '%' : ''}
                      trimOnBlur={false}
                    />
                  </Grid>
                )} */}

                {(values.riskprofiles[ind]?.answer === 'others' ||
                  (values.riskprofiles[ind].isMultiselect === 'true' &&
                    values.riskprofiles[ind]?.answer.includes('others'))) && (
                  <Grid xs={12} sm={6}>
                    <MFTextField
                      name={`riskprofiles.${ind}.otherValue`}
                      placeholder={`Please specify`}
                      disabled={getDisableStatusForjointHolder(
                        role,
                        referenceDetail.applicant_type
                      )}
                      sx={{
                        mb: 0,
                        '&.MuiInputBase-root': {
                          position: 'relative',
                          border: 0,
                          borderBottom: '1px solid #DDEAF3',
                          fontSize: 16,
                        },
                      }}
                      onChange={(e) => {
                        const removeAstricOrUnderscore = e.target.value
                          ?.replace('*', '')
                          ?.replace('_', '');
                        setValues({
                          ...values,
                          riskprofiles: values.riskprofiles.map((risk, index) => {
                            if (index === ind) {
                              return {
                                ...risk,
                                otherValue: removeAstricOrUnderscore,
                              };
                            }
                            return risk;
                          }),
                        });
                      }}
                    />
                  </Grid>
                )}
              </AccordionDetails>
            </Accordion>
          </>
        );
      })}
    </Box>
  );
}

export const profiletext = (total: number, profileData: profileType[]): any => {
  let text;
  profileData?.map((profile) => {
    const min = Math.min(profile.minScore, profile.maxScore),
      max = Math.max(profile.minScore, profile.maxScore);
    if (total >= min && total <= max) {
      text = (
        <>
          <Typography>
            You are{' '}
            <Typography component="strong" sx={{ fontSize: 16, fontWeight: 600, color: 'black' }}>
              {'  '}
              {profile.profile}.{' '}
            </Typography>
            {`${profile.Descriptions}. Your risk taking ability is ${profile.riskTakingAbility}`}
          </Typography>
        </>
      );
    }
  });
  return text;
};

export const handleIntialSelect = (riskprofiles: RiskProfile[], riskProfileQuestion: string) => {
  let selectedValue: string[] = [];
  const longTermArr = riskprofiles?.find((a) => a.question === riskProfileQuestion);
  selectedValue = longTermArr?.answer.split('*') as unknown as string[];
  return selectedValue;
};

export default function riskProfile(): JSX.Element {
  const [riskProfileData, setRiskProfileData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const { enqueueSnackbar } = useSnackbar();
  const [riskProfileDataMaster, setRiskProfileDataMaster] = useState<RiskProfileMasterType[]>([]);
  const [profileData, setProfileData] = useState<profileType[]>([]);
  const [totalScore, setTotalScore] = useState(0);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();
  const [openPopup, setOpenPopup] = useState<{ riskProfileCheck: boolean } | null>(null);
  const { investorEditObj } = useSelector((store: RootStateType) => store.investorEditObj);
  const [investorRouteObj, setInvestorRouteObj] = useState<InvestorEditRouteLinkObj | null>(null);
  const [openPreviewConfirmationPopup, setOpenPreviewConfirmationPopup] = useState(false);

  const handleSubmit = async (values: Values) => {
    try {
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
        riskProfileDeclaration: applicationRiskProfileDeclaration,
      } = application || {};
      const {
        riskprofiles,
        isRiskProfileToBeFilledByInvestor,
        saveType,
        riskProfileDeclaration: valuesRiskProfileDeclaration,
      } = values;
      if (
        (!isRiskProfileToBeFilledByInvestor ||
          (isRiskProfileToBeFilledByInvestor &&
            fieldValidateForInvestor(referenceDetail.applicant_type, 1, false))) &&
        (!checkAllQuestionsAnswered(riskprofiles) ||
          !checkExperienceQuestionValidation(riskprofiles))
      ) {
        throw 'Please fill all the required(*) fields';
      }
      riskprofiles?.map((risk) => {
        if (
          (!isRiskProfileToBeFilledByInvestor ||
            (isRiskProfileToBeFilledByInvestor &&
              fieldValidateForInvestor(referenceDetail.applicant_type, 1, false))) &&
          (risk.answer === 'others' ||
            checkOtherForExperience(risk.question, risk.answer) ||
            (risk.isMultiselect === 'true' && risk.answer.includes('others'))) &&
          !risk.otherValue &&
          risk.required === 'true'
        ) {
          const questionForDisplay = riskProfileDataMaster
            ?.map((riskProfile) => {
              if (riskProfile.key === risk.question) {
                return riskProfile.displayText;
              }
              return;
            })
            ?.filter((ele) => ele)
            ?.toString();
          throw risk.question === 'investmentOrTradingExperiance'
            ? `Investment/Trading experience shouldn't be zero/empty years`
            : `Please specify others in ${questionForDisplay}`;
        }
      });
      if (
        !valuesRiskProfileDeclaration &&
        application &&
        checkIfApplicationIsNonIndividual(application)
      ) {
        throw 'Declaration is required';
      }
      const updatedRiskProfile = riskprofiles?.map((_riskProfile) => {
        const {
          otherValue,
          required,
          values,
          displayText,
          key,
          isMultiselect,
          totalPercentage,
          InvestmentGoal,
          scoreCal,
          questionType,
          ...rest
        } = _riskProfile;
        return {
          ...rest,
          answer:
            otherValue && rest.answer === 'others'
              ? otherValue
              : isMultiselect === 'true' && otherValue && rest.answer?.includes('others')
              ? rest.answer?.replace('others', otherValue)
              : rest.answer,
          otherValue: checkOtherForExperience(rest.question, rest.answer) ? otherValue : '',
        };
      });
      const updatedPayload = {
        ...application,
        riskProfileDeclaration:
          application && checkIfApplicationIsNonIndividual(application)
            ? valuesRiskProfileDeclaration
            : applicationRiskProfileDeclaration,
        totalRiskProfileScore:
          !APMIFlow &&
          isRiskProfileToBeFilledByInvestor &&
          !fieldValidateForInvestor(referenceDetail.applicant_type, 1, false)
            ? 0
            : Number(manageTotal(values).toFixed(1)),
        isRiskProfileToBeFilledByInvestor,
        riskprofiles:
          !APMIFlow &&
          isRiskProfileToBeFilledByInvestor &&
          !fieldValidateForInvestor(referenceDetail.applicant_type, 1, false)
            ? updatedRiskProfile && updatedRiskProfile?.length > 0
              ? (updatedRiskProfile
                  ?.map((_profile) => {
                    if (_profile.id) {
                      return {
                        ..._profile,
                        isActive: false,
                      };
                    }
                    return;
                  })
                  .filter((ele) => ele) as updatedRiskProfile[])
              : updatedRiskProfile?.filter((profile) => {
                  if (RiskExperienceQues(profile.question)) {
                    return profile;
                  } else {
                    return profile.answer;
                  }
                })
            : updatedRiskProfile?.filter((profile) => {
                if (RiskExperienceQues(profile.question)) {
                  return profile;
                } else {
                  return profile.answer;
                }
              }),
      };
      const checkApplication = applicationComparison(
        {
          ...application,
          riskprofiles: application?.riskprofiles?.sort(
            (riskprofile1, riskprofile2) => Number(riskprofile1.id) - Number(riskprofile2.id)
          ),
        },
        {
          ...updatedPayload,
          riskprofiles: updatedPayload?.riskprofiles?.sort(
            (riskprofile1, riskprofile2) => Number(riskprofile1.id) - Number(riskprofile2.id)
          ),
          currentStep: !!currentStep && currentStep > 7 ? currentStep : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';

      //save for later route for investor at liveliness
      const investorEditSaveelaterRoute =
        referenceIdForInvestorPhotoCapture &&
        saveForLaterInvestorPhotoCapture(referenceIdForInvestorPhotoCapture, application);
      const referenceIdForSaveProceedRoute = referenceIdForInvestorPhotoCapture
        ? referenceIdForInvestorPhotoCapture
        : referenceDetail.esignReferenceId;

      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...updatedPayload,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: investorRouteObj ? currentStep : 8,
              ...(APMIFlow && {
                editedByPrimaryApplicant: investorRouteObj
                  ? true
                  : application?.editedByPrimaryApplicant,
              }),
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        setLoading(false);
        !isSaveLater
          ? investorRouteObj
            ? investorRouteObj.saveAndProceedSectionLink
              ? history.push(investorRouteObj.saveAndProceedSectionLink, {
                  id,
                  applicant1ReferenceId: referenceIdForSaveProceedRoute,
                })
              : setOpenPreviewConfirmationPopup(true)
            : history.push('strategy-details', {
                id,
                applicant1ReferenceId: referenceIdForSaveProceedRoute,
              })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : investorRouteObj
          ? investorRouteObj.backSectionLink
            ? history.push(investorRouteObj.backSectionLink, {
                id,
                applicant1ReferenceId: referenceIdForSaveProceedRoute,
              })
            : history.push(
                `/investment-details/${referenceDetail.esignReferenceId}/application-details`
              )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? investorRouteObj
            ? investorRouteObj.saveAndProceedSectionLink
              ? history.push(investorRouteObj.saveAndProceedSectionLink, {
                  id,
                  applicant1ReferenceId: referenceIdForSaveProceedRoute,
                })
              : setOpenPreviewConfirmationPopup(true)
            : history.push('strategy-details', {
                id,
                applicant1ReferenceId: referenceIdForSaveProceedRoute,
              })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : investorRouteObj
          ? investorRouteObj.backSectionLink
            ? history.push(investorRouteObj.backSectionLink, {
                id,
                applicant1ReferenceId: referenceIdForSaveProceedRoute,
              })
            : history.push(
                `/investment-details/${referenceDetail.esignReferenceId}/application-details`
              )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        const response = (await dispatch(riskProfileMasterData())) as unknown as RiskProfileMaster;
        if (!isComponentAlive) {
          return;
        }
        setRiskProfileDataMaster(response.riskProfile);
        setProfileData(response.profile);
        const {
          riskprofiles = [],
          isRiskProfileToBeFilledByInvestor = true,
          riskProfileDeclaration = true,
        } = application || {};
        setRiskProfileData({
          ...riskProfileData,
          riskProfileDeclaration: riskProfileDeclaration === null ? true : riskProfileDeclaration,
          isRiskProfileToBeFilledByInvestor:
            isRiskProfileToBeFilledByInvestor || initialValues.isRiskProfileToBeFilledByInvestor,
          riskprofiles: response.riskProfile
            ?.map((mdmsProfile) => {
              const existingRiskProfiles = riskprofiles?.filter(
                (risk_profiles) => risk_profiles.question === mdmsProfile.key
              );
              return existingRiskProfiles.length
                ? existingRiskProfiles?.map((existingProfiles) => {
                    if (
                      existingProfiles.question === mdmsProfile.key &&
                      mdmsProfile.isMultiselect === 'true'
                    ) {
                      const getInvestment = handleIntialSelect(riskprofiles, mdmsProfile.key);
                      const checkAnswerArray = mdmsProfile.values?.map((_value) => _value.key);
                      let getOtherValue = '';
                      const updateOptions = getInvestment.map((ans) => {
                        if (!checkAnswerArray?.includes(ans.split('_')[0])) {
                          getOtherValue = ans.split('_')[0];
                          return `others_${ans.split('_')[1]}`;
                        }
                        return ans;
                      });
                      return {
                        ...existingProfiles,
                        ...mdmsProfile,
                        values: mdmsProfile.values?.map((value) => {
                          const scoreUpdate = updateOptions
                            .find((investment) => investment?.split('_')[0] === value.key)
                            ?.split('_')[1];

                          return {
                            ...value,
                            score: scoreUpdate ? Number(scoreUpdate) : value.score,
                          };
                        }),
                        InvestmentGoal:
                          RiskExperienceQues(existingProfiles.question) && !existingProfiles.answer
                            ? []
                            : updateOptions || [],
                        scoreCal: Number(existingProfiles.scoreText) || 0,
                        otherValue: getOtherValue,
                        answer: getOtherValue
                          ? RiskExperienceQues(existingProfiles.question)
                            ? ''
                            : updateOptions?.toString()?.replace(/,/g, '*')
                          : existingProfiles.answer,
                      };
                    } else {
                      const checkAnswer =
                        existingProfiles.question === mdmsProfile.key &&
                        mdmsProfile.isMultiselect === 'false' &&
                        mdmsProfile.values
                          ?.map((value) => value.key)
                          .includes(existingProfiles.answer);

                      return {
                        ...existingProfiles,
                        ...mdmsProfile,
                        values: mdmsProfile.values?.map((value) => {
                          if (existingProfiles.answer === value.key) {
                            return {
                              ...value,
                              score: Number(existingProfiles.scoreText),
                            };
                          }
                          return value;
                        }),
                        answer: checkAnswer ? existingProfiles.answer : 'others',
                        otherValue: checkAnswer
                          ? checkOtherForExperience(
                              existingProfiles.question,
                              existingProfiles.answer
                            )
                            ? existingProfiles.otherValue
                            : ''
                          : existingProfiles.answer,
                      };
                    }
                  })
                : [
                    {
                      ...RiskProfileObj,
                      question: mdmsProfile.key,
                      answer:
                        (APMIFlow ||
                          isRiskProfileToBeFilledByInvestor === null ||
                          (isRiskProfileToBeFilledByInvestor &&
                            fieldValidateForInvestor(referenceDetail.applicant_type, 1, false))) &&
                        ['systematicPlan', 'age'].includes(mdmsProfile.key)
                          ? mdmsProfile.key === 'age'
                            ? getRiskAgeValue(
                                getAge(
                                  application?.applicants?.find(
                                    (_applicant) => _applicant.applicant_type === '1'
                                  )?.dateOfBirth
                                )
                              )
                            : mdmsProfile.key === 'systematicPlan'
                            ? getSystematicPlan(mdmsProfile)?.key || ''
                            : ''
                          : '',
                      scoreText:
                        (APMIFlow ||
                          isRiskProfileToBeFilledByInvestor === null ||
                          (isRiskProfileToBeFilledByInvestor &&
                            fieldValidateForInvestor(referenceDetail.applicant_type, 1, false))) &&
                        ['systematicPlan', 'age'].includes(mdmsProfile.key)
                          ? mdmsProfile.key === 'age'
                            ? getScore(
                                getRiskAgeValue(
                                  getAge(
                                    application?.applicants?.find(
                                      (_applicant) => _applicant.applicant_type === '1'
                                    )?.dateOfBirth
                                  )
                                )
                              )
                            : mdmsProfile.key === 'systematicPlan'
                            ? (getSystematicPlan(mdmsProfile)?.score as unknown as string) || ''
                            : ''
                          : '',
                      ...mdmsProfile,
                    },
                  ];
            })
            .flat(),
        });
        const routeLinkObj =
          (investorEditObj && investorEditObj?.length > 0) ||
          (APMIFlow && referenceDetail?.applicant_type === '1')
            ? getInvestorRouteLinks(
                investorEditObj as InvestorEditErrorObj[],
                history?.location?.pathname?.split('/')?.pop() || '',
                referenceDetail
              )
            : null;
        setInvestorRouteObj(routeLinkObj);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, [application]);

  const manageTotal = (values: Values) => {
    const total = values.riskprofiles
      .map((risk) => {
        return Number(risk.scoreText);
      })
      .reduce(function (acc, val) {
        return acc + val;
      }, 0);

    return total;
  };

  return (
    <Formik
      initialValues={riskProfileData}
      // validationSchema={riskProfileScheme}
      onSubmit={handleSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          //columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          {SHOW_SECTION_FILLED_BY_INVESTOR_CHECKBOX &&
            !fieldValidateForInvestor(referenceDetail.applicant_type, 1, false) &&
            application &&
            !checkIfApplicationIsNonIndividual(application) && (
              <Grid xs={12} sx={{ mb: 2 }}>
                <MFCheckbox
                  name="isRiskProfileToBeFilledByInvestor"
                  label={sectionFilledByInvestor}
                  sx={{ display: 'flex' }}
                  onChange={({ target: { checked } }) => {
                    const updatedRiskProfile = riskProfileDataMaster.map((mdmsProfile) => {
                      const riskObj = values.riskprofiles?.find(
                        (_profile) => _profile.key === mdmsProfile.key
                      );
                      return {
                        ...RiskProfileObj,
                        question: mdmsProfile.key,
                        ...riskObj,
                        answer:
                          mdmsProfile.key === 'age'
                            ? getRiskAgeValue(
                                getAge(
                                  application?.applicants?.find(
                                    (_applicant) => _applicant.applicant_type === '1'
                                  )?.dateOfBirth
                                )
                              )
                            : mdmsProfile.key === 'systematicPlan'
                            ? getSystematicPlan(mdmsProfile)?.key || ''
                            : '',
                        scoreText:
                          mdmsProfile.key === 'age'
                            ? getScore(
                                getRiskAgeValue(
                                  getAge(
                                    application?.applicants?.find(
                                      (_applicant) => _applicant.applicant_type === '1'
                                    )?.dateOfBirth
                                  )
                                )
                              )
                            : mdmsProfile.key === 'systematicPlan'
                            ? (getSystematicPlan(mdmsProfile)?.score as unknown as string) || ''
                            : '',
                        yearsInStocks: '',
                        yearsInDerivatives: '',
                        otherInvestmest: '',
                        ...mdmsProfile,
                      };
                    });
                    !checked &&
                      setValues({
                        ...values,
                        isRiskProfileToBeFilledByInvestor: checked,
                        riskprofiles: [...updatedRiskProfile],
                      });
                    checked && setOpenPopup({ riskProfileCheck: checked });
                  }}
                  checked={values.isRiskProfileToBeFilledByInvestor}
                  disabled={APMIFlow ? true : false}
                />
              </Grid>
            )}
          <RiskDeclaration
            data={values.riskprofiles}
            values={values}
            setValues={setValues}
            setTotalScore={setTotalScore}
            totalScore={totalScore}
            disabled={
              APMIFlow
                ? false
                : values.isRiskProfileToBeFilledByInvestor &&
                  !fieldValidateForInvestor(referenceDetail.applicant_type, 1, false)
            }
          />
          {/* <Typography
            sx={{
              color: 'black',
              fontWeight: 600,
              fontSize: 14,
              mt: 2,
            }}>{`Your total Score is ${manageTotal(values).toFixed(1)}
          `}</Typography>
          <Typography sx={{ mt: 1 }}>
            {profiletext(Number(manageTotal(values).toFixed(1)), profileData)}
          </Typography> */}
          {application && checkIfApplicationIsNonIndividual(application) && (
            <Grid item xs={12}>
              <MFCheckbox
                name={`riskProfileDeclaration`}
                label={riskProfileDeclaration}
                sx={{ display: 'flex', alignItems: 'flex-start' }}
              />
            </Grid>
          )}
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
            saveForLaterButtonText={
              investorRouteObj
                ? investorRouteObj.backSectionLink
                  ? `Save and Back To ${investorRouteObj.backSectionName}`
                  : 'Save For Later'
                : 'Save For Later'
            }
            saveButtonText={
              investorRouteObj
                ? investorRouteObj.saveAndProceedSectionLink
                  ? 'Save and Proceed'
                  : 'Save Application'
                : 'Save and Proceed'
            }
            showEndIcon={
              investorRouteObj ? (investorRouteObj.saveAndProceedSectionLink ? true : false) : true
            }
            showSaveForLater={
              investorRouteObj ? (investorRouteObj.backSectionLink ? true : false) : true
            }
          />
          <ConfirmationDialog
            message={`Are you sure you do not wish to fill this step ?`}
            open={openPopup !== null}
            setOpen={() => {
              setOpenPopup(null);
            }}
            onSave={() => {
              setValues({
                ...values,
                isRiskProfileToBeFilledByInvestor: openPopup?.riskProfileCheck as boolean,
                riskprofiles: values.riskprofiles?.map((_profile) => {
                  return {
                    ..._profile,
                    answer: '',
                    scoreText: '',
                    InvestmentGoal: [],
                    scoreCal: 0,
                    yearsInStocks: '',
                    yearsInDerivatives: '',
                    otherInvestmest: '',
                  };
                }),
              });
              setOpenPopup(null);
            }}
            onCancel={() => {
              setOpenPopup(null);
            }}
          />
          <ConfirmationDialog
            message={`Application Saved successfully and no further edits provided, Are you sure you want to go back to preview?`}
            open={openPreviewConfirmationPopup}
            setOpen={() => {
              setOpenPreviewConfirmationPopup(false);
            }}
            onSave={() => {
              setOpenPreviewConfirmationPopup(false);
              history.push(
                `/investment-details/${referenceDetail.esignReferenceId}/application-details`
              );
            }}
            onCancel={() => {
              setOpenPreviewConfirmationPopup(false);
            }}
          />
        </Grid>
      )}
    </Formik>
  );
}
