import { AppBar, Box, CardMedia, Divider, Grid, IconButton, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import {
  Redirect,
  Route,
  Router,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router';
import EnterOTP from './enterOTP';
import EnterPAN, { EnterEmailId, EnterMobile } from './enterPAN';
import { CommonLayout } from '../commonComponents';
import { Location } from 'history';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { DocumentsVerification } from './documentVerification';
import { APPLICATION_TYPE, USER_ROLES } from '../../utils/constant';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useEffect } from 'react';
import { getApplicantIdForInvestorPhotoCapture } from '../../utils/utilityFunctions';

function Routes(): JSX.Element {
  const history = useHistory();
  const { path } = useRouteMatch();
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={path}
          render={() => <Redirect to={`${path}/enter-pan-number`} from={location.pathname} />}
        />
        <Route path={`${path}/enter-pan-number`} component={EnterPAN} />
        <Route path={`${path}/enter-mobile-number`} component={EnterMobile} />
        <Route path={`${path}/enter-otp`} component={EnterOTP} />
        <Route path={`${path}/enter-email-id`} component={EnterEmailId} />
        <Route path={`${path}/verify-documents`} component={DocumentsVerification} />
      </Switch>
    </Router>
  );
}

export function OnboardInvestorLayoutAtPhotoCapture({
  location,
}: {
  location: Location<{
    application: ApplicationProps;
    firstHolderName: string;
  }>;
}): JSX.Element {
  const { application, firstHolderName = '' } = location.state || {};
  const { id } = application || {};
  const history = useHistory();
  const { path } = useRouteMatch();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);

  const {
    investor: { token = '' },
  } = useSelector((store: RootStateType) => store);

  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();

  useEffect(() => {
    if (!token) {
      history.push(`/investor-liveliness/${referenceIdForInvestorPhotoCapture}/login`);
      return;
    }
  }, []);

  return (
    <Box
      sx={{
        bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
      }}>
      <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'common.white' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: { xs: 0, sm: '48px' },
            paddingLeft: { xs: 0, sm: '48px' },
          }}>
          <CardMedia
            component="img"
            src="/images/spark-logo.jpg"
            alt="Logo"
            sx={{ width: '180px', m: 3, mb: 2 }}
          />
        </Box>
      </AppBar>
      <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
      <Box sx={{ py: 3, px: { xs: 2, sm: 10 }, mt: 10 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <IconButton
            onClick={() =>
              history.push(
                `/investor-liveliness/${referenceIdForInvestorPhotoCapture}/application-details`,
                {
                  applicationId: id,
                  applicantId: getApplicantIdForInvestorPhotoCapture(
                    application,
                    referenceIdForInvestorPhotoCapture
                  ),
                }
              )
            }
            sx={{ height: 'fit-content', p: 0 }}
            // component={RouterLink}
            // to={
            //   `application-details`, {
            //     applicationId: loginResponse.applicationId,
            //     applicantId: loginResponse.applicantId,
            //   }
            // }
          >
            <NavigateBeforeIcon fontSize="large" sx={{ color: 'common.black' }} />
          </IconButton>
          <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main' }}>
            Add Joint Holder
          </Typography>
        </Box>
        <Box
          sx={{
            // py:1,
            bgcolor: 'white',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
            borderRadius: '10px',
            // height: { xs: 'calc(100vh - 100px)', md: 'calc(100vh - 165px)' },
            // overflowY: 'auto',
          }}>
          <Box
            sx={{
              p: 2,
              // height: { xs: 'calc(100vh - 191px)', md: 'calc(100vh - 200px)' },
              // overflowY: 'auto',
            }}>
            {/* {history.location.pathname === `${path}/verify-documents` ? (
              <Routes />
            ) : (
              <>
                {id && ( */}
            <Typography
              sx={{
                ml: 3,
                fontSize: 20,
                color: '#000000',
                fontWeight: 500,
              }}>
              Please provide joint holder details{' '}
              {firstHolderName !== 'N/A' && `- ${firstHolderName}`}
            </Typography>
            {/* )} */}
            <Grid container sx={{ mt: id ? 1 : 5 }}>
              <Grid item xs={6} sx={{ display: { xs: 'none', md: 'block' } }}>
                <CardMedia component="img" src="/images/onboard-investor.svg" alt="Illustration" />
              </Grid>
              <Grid item container xs={12} md={6} sx={{ alignSelf: 'center' }}>
                <Grid item xs={2} sx={{ display: { xs: 'none', sm: 'block' } }} />
                <Grid item xs={12} sm={8}>
                  <Routes />
                </Grid>
                <Grid item xs={2} sx={{ display: { xs: 'none', sm: 'block' } }} />
              </Grid>
            </Grid>
            {/* </>
            )} */}
          </Box>
        </Box>
      </Box>
      {/* <Footer /> */}
    </Box>
  );
}

//Router
// Onboard Investor Layout
export default function OnboardInvestorLayout({
  location,
}: {
  location: Location<{ application: ApplicationProps; firstHolderName: string }>;
}): JSX.Element {
  const { application, firstHolderName = '' } = location.state || {};
  const { id } = application || {};
  const history = useHistory();
  const { path } = useRouteMatch();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);

  return (
    <CommonLayout>
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <IconButton
            //  onClick={() => history.push('/')}
            sx={{ height: 'fit-content', p: 0 }}
            component={RouterLink}
            to={
              [USER_ROLES.AMCAPPROVER, USER_ROLES.POAAPPROVER].includes(role)
                ? '/applications-list'
                : '/applications'
            }>
            <NavigateBeforeIcon fontSize="large" sx={{ color: 'common.black' }} />
          </IconButton>
          <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main' }}>
            {id
              ? 'Add Joint Holder'
              : application?.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              ? 'Onboard Non Individual Investor'
              : 'Onboard Investor'}
          </Typography>
        </Box>
        <Box
          sx={{
            // py:1,
            bgcolor: 'white',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
            borderRadius: '10px',
            // height: { xs: 'calc(100vh - 100px)', md: 'calc(100vh - 165px)' },
            // overflowY: 'auto',
          }}>
          <Box
            sx={{
              p: 2,
              // height: { xs: 'calc(100vh - 191px)', md: 'calc(100vh - 200px)' },
              // overflowY: 'auto',
            }}>
            {history.location.pathname === `${path}/verify-documents` ? (
              <Routes />
            ) : (
              <>
                {id && (
                  <Typography
                    sx={{
                      ml: 3,
                      fontSize: 20,
                      color: '#000000',
                      fontWeight: 500,
                    }}>
                    Please provide joint holder details{' '}
                    {firstHolderName !== 'N/A' && `- ${firstHolderName}`}
                  </Typography>
                )}
                <Grid container sx={{ mt: id ? 1 : 5 }}>
                  <Grid item xs={6} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <CardMedia
                      component="img"
                      src="/images/onboard-investor.svg"
                      alt="Illustration"
                    />
                  </Grid>
                  <Grid item container xs={12} md={6} sx={{ alignSelf: 'center' }}>
                    <Grid item xs={2} sx={{ display: { xs: 'none', sm: 'block' } }} />
                    <Grid item xs={12} sm={8}>
                      <Routes />
                    </Grid>
                    <Grid item xs={2} sx={{ display: { xs: 'none', sm: 'block' } }} />
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Box>
      </>
    </CommonLayout>
  );
}
