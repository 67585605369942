import {
  CardMedia,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  Theme,
} from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { SxProps } from '@mui/system';
import { useField } from 'formik';
import { useState } from 'react';

const defaultTextFieldStyles: SxProps<Theme> = {
  'label + &': {
    mt: 4,
  },
  '& .MuiInputBase-root': {
    border: '1px solid #DDEAF3',
  },
  '& .MuiOutlinedInput-root': {
    position: 'relative',
    backgroundColor: 'common.white',
    border: '1px solid #DDEAF3',
    fontSize: 16,
    '&:hover': {
      borderColor: 'primary.main',
      '.MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '.MuiInputBase-input': {
      p: '10px 12px',
    },
    '&:focus-visible': {
      outline: 'none',
    },
  },
};

export const TextDatePicker = ({
  label = '',
  placeholder = '',
  name,
  inputLabelStyles,
  textFieldStyles = defaultTextFieldStyles,
  maxDate = new Date(),
  minDate,
  disabled = false,
  onClick,
  disableHighlightToday = false,
}: {
  label?: string;
  placeholder: string;
  value?: string | null;
  onClick?: () => void;
  name: string;
  inputLabelStyles?: SxProps<Theme>;
  textFieldStyles?: SxProps<Theme>;
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
  disableHighlightToday?: boolean;
}): JSX.Element => {
  const [field, meta, { setValue }] = useField(name);
  const [error, setError] = useState(false);
  const onChange = (date: any) => {
    date == 'Invalid Date' && setError(true);
    date != 'Invalid Date' && setError(false);
    setValue(date);
    onClick && onClick();
  };
  const errorText = error ? 'Invalid Date' : meta.error && meta.touched ? meta.error : '';

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormControl variant="outlined" fullWidth sx={{ my: { xs: 1, md: 2 } }}>
        <InputLabel shrink htmlFor="date-picker" sx={{ ...inputLabelStyles }}>
          {label}
        </InputLabel>
        <DesktopDatePicker
          maxDate={maxDate}
          minDate={minDate}
          components={{
            OpenPickerIcon: () => <CardMedia component="img" src="/images/calender.svg" />,
          }}
          componentsProps={{}}
          value={field.value}
          inputFormat="dd/MM/yyyy"
          onChange={onChange}
          disabled={disabled}
          views={['year', 'month', 'day']}
          InputAdornmentProps={{ position: 'start' }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              id="date-picker"
              placeholder={placeholder}
              sx={{
                ...textFieldStyles,
                display: 'flex',
                flexDirection: 'row-reverse',
                '& .Mui-disabled': {
                  cursor: !disabled ? 'text' : 'not-allowed !important',
                  pointerEvents: 'all',
                },
              }}
              disabled={disabled}
            />
          )}
          disableCloseOnSelect={false}
          showToolbar={false}
          disableHighlightToday={disableHighlightToday}
        />
        {errorText && (
          <FormHelperText error sx={{ marginLeft: 'unset' }}>
            {errorText}
          </FormHelperText>
        )}
      </FormControl>
    </LocalizationProvider>
  );
};
