/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { combineReducers } from 'redux';
import applicationReducer, { ApplicationStateProps } from './application';
import dashboardDoughnutReducer from './doughnutGraph';
import monthOnMonthReducer from './monthsGraph';
import topDistributorReducer from './topDistributors';
import commitmentAndContributionReducer from './commitmentAndContributionGraph';
import auth from './auth';
import errorReducer, { ErrorState } from './error';
import investorReducer from './investor';
import profileReducer from './profile';
import paramsReducer, { ParamsProps } from './queryParams';
import toast, { ToastType } from './toast';
import {
  dashboardOnboardingType,
  distributorWisecommitmentType,
  getAuthorizedSignatoriesDetailsByRefrenceType,
  getDetailsByRefrenceType,
  monthwiseCommitmentAmountType,
  monthwiseOnboardingSummaryType,
  monthwiseTotalInvestmentAmountAndApplicantsType,
} from '../types/api-types';
import refrenceIdReducer from './refrenceId';
import investorEditSectionsReducer, { InvestorEditSectionProps } from './investorEditSections';
import authorizedSignatoriesRefrenceIdReducer from './authorizedsignatoryrefId';
import userVerification from './userVerification';
import investmentAmountAndApplicantsReducer from './investmentAmountAndApplicantsGraph';
import { custodianReducer } from './custodian';

export interface RootStateType {
  auth: any;
  router: any;
  profileReducer: any;
  dataDougnut: dashboardOnboardingType;
  monthOnMonthData: monthwiseOnboardingSummaryType;
  topDistributors: distributorWisecommitmentType;
  commitmentAndContributionData: monthwiseCommitmentAmountType;
  investmentAmountAndApplicantsData: monthwiseTotalInvestmentAmountAndApplicantsType;
  application: ApplicationStateProps;
  refrenceIdReducer: getDetailsByRefrenceType;
  toast: ToastType;
  investor: any;
  error: ErrorState;
  paramsObj: ParamsProps;
  investorEditObj: InvestorEditSectionProps;
  authorizedSignatoriesRefrenceIdReducer: getAuthorizedSignatoriesDetailsByRefrenceType;
  userVerification: any;
  custodianReducer: any;
}

export const rootReducer = (history: any) =>
  combineReducers({
    router: history,
    auth,
    profile: profileReducer,
    application: applicationReducer,
    dataDougnut: dashboardDoughnutReducer,
    monthOnMonthData: monthOnMonthReducer,
    commitmentAndContributionData: commitmentAndContributionReducer,
    investmentAmountAndApplicantsData: investmentAmountAndApplicantsReducer,
    topDistributors: topDistributorReducer,
    refrenceIdReducer: refrenceIdReducer,
    toast,
    investor: investorReducer,
    error: errorReducer,
    paramsObj: paramsReducer,
    investorEditObj: investorEditSectionsReducer,
    authorizedSignatoriesRefrenceIdReducer: authorizedSignatoriesRefrenceIdReducer,
    userVerification,
    custodianReducer,
  });

export type RootState = ReturnType<typeof rootReducer>;
