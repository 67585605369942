import { Redirect, Route, Switch, useRouteMatch, useHistory, useParams } from 'react-router';
import LoginOtp from '../auth/loginOtp';
import Box from '@mui/material/Box';
import { Formik } from 'formik';
import { MFTextField, MFSubmitButton } from '../../lib/formik';
import { useDispatch, useSelector } from 'react-redux';
import { CountryCodesDropDown, Footer, LoginLayout } from '../commonComponents';
import {
  getApplicantIdForInvestorPhotoCapture,
  preventSpecialCharacters,
} from '../../utils/utilityFunctions';
import { useEffect, useState } from 'react';
import {
  getPhotoCaptureOtpForInvestor,
  GetPhotoCaptureOtpForInvestorResponse,
  InvestorLoginForPhotoCapture,
  InvestorLoginPhotoCaptureResponseBody,
} from '../../redux-store/actions/photoLocationCapture';
import WebcamCapture, { CapturedDetailsSucsessfully } from './faceCapture';
import LocationCapture from './locationCapture';
import InvestorApplicationBeforeFaceCapture from './investorApplicationEdit';
import { Location } from 'history';
import { RootStateType } from '../../redux-store/reducers';
import { AppBar, CardMedia, Divider, Grid, IconButton, Typography } from '@mui/material';
import { USER_ROLES } from '../../utils/constant';
import { InvestorPhotoCaptureEditRoutes } from '../investors/routes';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { Link as RouterLink } from 'react-router-dom';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { OnboardInvestorLayoutAtPhotoCapture } from '../onboarding/Router';
import { getApplicationDetails } from '../../redux-store/actions/application';

interface Values {
  mobile: string;
  countryCode: string;
  countryNameAndCode: string;
}

function PhotoCaptureMobileLogin(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const { params } = useRouteMatch();
  const { referenceId } = params as unknown as { referenceId: string };

  const onSubmit = async (values: Values) => {
    try {
      const response = (await dispatch(
        getPhotoCaptureOtpForInvestor(referenceId)
      )) as unknown as GetPhotoCaptureOtpForInvestorResponse;
      history.push('otp', {
        ...(response || {}),
        mobile: (response || {}).phone,
        email: (response || {}).email,
        loginType: 'investor_photoCapture',
        referenceId,
      });
    } catch (e) {
      console.error((e as Error).message);
    }
  };
  const [initialValues, setInitialValues] = useState<Values>({
    mobile: '',
    countryCode: '+91',
    countryNameAndCode: 'India: +91',
  });

  useEffect(() => {
    (async function () {
      try {
        if (referenceId) {
          const response = (await dispatch(
            InvestorLoginForPhotoCapture(referenceId)
          )) as unknown as InvestorLoginPhotoCaptureResponseBody;
          setInitialValues({
            mobile: response?.phone,
            countryCode: response?.countryCode,
            countryNameAndCode: response?.countryNameAndCode,
          });
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [referenceId]);

  return (
    <LoginLayout LoginText="Login">
      <Formik onSubmit={onSubmit} initialValues={initialValues} enableReinitialize={true}>
        {({ handleSubmit, values }) => (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              '.MuiLoadingButton-root': {
                mt: 5,
              },
              '.MuiInputLabel-root': {
                fontFamily: 'Poppins, sans-serif',
                fontSize: 16,
                fontWeight: 500,
              },
            }}>
            <MFTextField
              label="Mobile Number"
              name="mobile"
              placeholder="Enter Mobile number"
              onKeyDown={(e) => {
                preventSpecialCharacters(e);
              }}
              startAdornment={
                <CountryCodesDropDown
                  name={'countryNameAndCode'}
                  value={values.countryNameAndCode}
                  disabled={true}
                />
              }
              disabled={true}
            />
            {/* <MFTextField
              required
              label="Enter Mobile Number"
              name="mobile"
              type="text"
              placeholder="Enter Mobile Number"
              autoComplete="off"
              autoFocus
            /> */}
            <MFSubmitButton label="Get OTP" />
          </Box>
        )}
      </Formik>
    </LoginLayout>
  );
}

export const PhotoCaptureEditApplication = ({
  location,
}: {
  location: Location<{ id: string; applicant1ReferenceId?: string }>;
}): JSX.Element => {
  const { id, applicant1ReferenceId } = location.state || {};
  const {
    investor: { token = '' },
  } = useSelector((store: RootStateType) => store);

  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchApplication() {
      try {
        if (!token) {
          history.push(`/investor-liveliness/${referenceIdForInvestorPhotoCapture}/login`);
          return;
        }

        if (id) {
          (await dispatch(getApplicationDetails(id))) as unknown as ApplicationProps;
        } else {
          history.push(`/investor-liveliness/${referenceIdForInvestorPhotoCapture}/login`);
          console.error('No application id found');
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    }
    // if (application === null) {
    fetchApplication();
    // }
  }, []);

  // useEffect(() => {
  //   if (!token) {
  //     history.push(`/investor-liveliness/${referenceIdForInvestorPhotoCapture}/login`);
  //     return;
  //   }
  // }, []);

  return (
    <Box
      sx={{
        bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
      }}>
      <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'common.white' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: { xs: 0, sm: '48px' },
            paddingLeft: { xs: 0, sm: '48px' },
          }}>
          <CardMedia
            component="img"
            src="/images/spark-logo.jpg"
            alt="Logo"
            sx={{ width: '180px', m: 3, mb: 2 }}
          />
        </Box>
      </AppBar>
      <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
      <Box sx={{ py: 3, px: { xs: 2, sm: 10 }, mt: 10 }}>
        <InvestorPhotoCaptureEditRoutes
          id={id}
          applicant1ReferenceId={referenceIdForInvestorPhotoCapture}
        />
      </Box>
      <Footer />
    </Box>
  );
};

const InvestorPhotoRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={path}
        render={(props: any) => (
          <Redirect to={`${path}/:referenceId/login`} from={props.location.state} />
        )}
      />
      <Route path={`${path}/:referenceId/login`} component={PhotoCaptureMobileLogin} />
      <Route path={`${path}/:referenceId/otp`} component={LoginOtp} />
      <Route
        path={`${path}/:referenceId/application-details`}
        component={InvestorApplicationBeforeFaceCapture}
      />
      <Route
        path={`${path}/edit-application/:referenceId`}
        component={PhotoCaptureEditApplication}
      />
      <Route
        path={`${path}/onboarding-investor/:referenceId`}
        component={OnboardInvestorLayoutAtPhotoCapture}
      />
      {/* <Route path={`${path}/:referenceId/photo-capture`} component={WebcamCapture} />
      <Route path={`${path}/:referenceId/location-capture`} component={LocationCapture} />
      <Route path={`${path}/successfully`} component={CapturedDetailsSucsessfully} /> */}
      <Route
        path="*"
        render={() => {
          return 'Page you are requesting is not found.....';
        }}
      />
    </Switch>
  );
};

export default InvestorPhotoRoutes;
