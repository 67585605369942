import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { ApplicationProps, StrategyDetailsType } from '../../redux-store/types/api-types';
import {
  ManagementFeePercentage,
  convertToRoman,
  currencyConversion,
} from '../../utils/utilityFunctions';
import { DataTable } from '../DataTable';
import SourceOfInvestment from './sourceOfInvestment';
import { SubHeading } from '../investors/components';
import { KeyValue } from './contributor';
import { Gridstyles } from '.';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { columns } from '../investors/strategyDetails';
import {
  defaultOperationalCharges,
  FeeTypeMaster,
  STRATEGY_TYPE_OPTIONS,
} from '../../utils/constant';

export default function StrategyDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { applicationstrategydetails = [] } = application || {};
  const { role = '' } = useSelector((store: RootStateType) => store.auth);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main', mb: 2 }}>
              Strategy Details
            </Typography>
            <React.Fragment>
              <Box sx={{ width: '100%' }}>
                {applicationstrategydetails?.length > 0 ? (
                  <React.Fragment>
                    {applicationstrategydetails?.map((strategy: any, index: number) => (
                      <>
                        <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>
                          {strategy.strategyName}
                        </SubHeading>
                        <Gridstyles>
                          <Grid
                            item
                            container
                            pt={2}
                            pb={5}
                            rowSpacing={6}
                            columnSpacing={13}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start">
                            {application.distributor_id && (
                              <KeyValue
                                title={'Fee Type'}
                                description={FeeTypeMaster[strategy.feeType]}
                              />
                            )}
                            <KeyValue title="Entry Load" description={strategy.entryLoad} />
                            <KeyValue title="Exit Load" description={strategy.exitLoad} />{' '}
                            <KeyValue
                              title="Strategy type"
                              description={STRATEGY_TYPE_OPTIONS[strategy.strategyType || '']}
                            />
                            <KeyValue title="Benchmark" description={strategy.benchmark} />
                            <KeyValue
                              title={'Investment Amount'}
                              description={strategy.investmentAmount}
                              amount={
                                strategy.investmentAmount
                                  ? currencyConversion(strategy.investmentAmount)
                                  : strategy.investmentAmount
                              }
                            />
                            <KeyValue
                              title={'Management Fee'}
                              description={ManagementFeePercentage(
                                strategy.applicationStrategyManagementFee
                              )}
                            />
                            {/* <Grid item xs={12} sm={12}>
                              <DataTable
                                tableHeader={[...columns]}
                                tableData={strategy.applicationInvestmentAmountDetails || []}
                                boxStyles={{
                                  minWidth: 500,
                                  width: '100%',
                                  minHeight: '0px',
                                  maxHeight: 'unset',
                                  // marginTop: 5,
                                }}
                              />
                            </Grid> */}

                            {((strategy?.feeType !== 'fixed' && application.distributor_id) ||
                              !application.distributor_id) && (
                              <Grid item xs={12} sm={12}>
                                {' '}
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    mt: -5,
                                    mb: 1,
                                  }}>
                                  <Typography
                                    component="span"
                                    sx={{ fontWeight: 700, fontSize: 14, color: '#2057A6' }}>
                                    Performance Fee:{' '}
                                  </Typography>
                                  <Typography>{strategy?.performanceFee || '-'}</Typography>
                                </Typography>
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  flexDirection: 'column',
                                }}>
                                <Typography
                                  component="span"
                                  sx={{ fontWeight: 700, fontSize: 14, color: '#2057A6' }}>
                                  Other Operational Charges :
                                </Typography>
                                {(strategy?.otherOperationalCharges?.length > 0
                                  ? ((strategy.otherOperationalCharges || []) as string[])
                                  : defaultOperationalCharges
                                ).map((item, index) => (
                                  <Typography sx={{ color: '#2057A6' }} key={index}>
                                    {`(${convertToRoman(index + 1)}) ${item}`}
                                  </Typography>
                                ))}
                              </Box>
                            </Grid>
                          </Grid>
                        </Gridstyles>
                      </>
                    ))}
                    <Gridstyles>
                      <Grid
                        item
                        container
                        pt={2}
                        pb={5}
                        rowSpacing={6}
                        columnSpacing={13}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start">
                        {/*<Grid item xs={12} sm={12}>
                           <Typography
                            sx={{ fontSize: 14, fontWeight: 500, color: '#2057A6', mb: 1 }}>
                            <Typography
                              component="span"
                              sx={{ fontWeight: 700, fontSize: 14, color: '#2057A6' }}>
                              Note :{' '}
                            </Typography>
                            {`>20% pa - 20% of excess returns + GST`}
                          </Typography>
                        </Grid> */}
                        <Grid item xs={12} sm={12}>
                          {' '}
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight: 500,
                              color: '#2057A6',
                              mt: -5,
                              mb: 1,
                            }}>
                            <Typography
                              component="span"
                              sx={{ fontWeight: 700, fontSize: 14, color: '#2057A6' }}>
                              Other Operational Charges :{' '}
                            </Typography>
                            <Typography>(i) Audit charges: INR 800 per annum + GST</Typography>
                            <Typography>
                              {' '}
                              (ii) Incidental expenses / One-time charges towards
                              documentation/set-up: INR 600 + GST{' '}
                            </Typography>
                            <Typography>
                              {' '}
                              (iii) Fund accounting & Custodial Charges: 0.08% per annum on average
                              AUM + GST
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Gridstyles>
                  </React.Fragment>
                ) : (
                  <Grid
                    xs={12}
                    sm={12}
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: 'common.black',
                      textAlign: 'center',
                      my: 3,
                    }}>
                    No Strategy Details
                  </Grid>
                )}
              </Box>
            </React.Fragment>
          </Grid>

          {/* <Gridstyles> */}
          {/* <Box sx={{ ml: -2, mt: -2 }}> */}
          <SourceOfInvestment application={application} />
          {/* </Box> */}
          {/* </Gridstyles> */}
        </Box>
      </Box>
    </>
  );
}
