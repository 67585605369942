import { Redirect, Route, Router, Switch, useHistory, useRouteMatch } from 'react-router';
import { CommonLayout } from '../commonComponents';
import { IdDependencyRoutes } from '../userManagement/router';
import AddStrategy from './addStrategy';
import StrategyDetails from './strategyDetails';
import StrategyList from './strategyList';
import AddOfficer from '../StrategyOfficersSetup/addStrategyOfficer';
import OfficersDetails from '../StrategyOfficersSetup/strategyOfficersDetails';

function Routes({ historyLocationState }: { historyLocationState: any }): JSX.Element {
  const history = useHistory();
  const { path } = useRouteMatch();

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={path} render={() => <Redirect to={`${path}/strategy-list`} />} />
        <Route path={`${path}/add-strategy`} component={AddStrategy} />
        <Route path={`${path}/add-officer`} component={AddOfficer} />
        <Route path={`${path}/strategy-list`} component={StrategyList} />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/strategy-details`}
          component={StrategyDetails}
          replaceUrl={'/strategy-setup'}
        />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/edit-strategy`}
          component={AddStrategy}
          replaceUrl={'/strategy-setup'}
        />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/officer-details`}
          component={OfficersDetails}
          replaceUrl={'/strategy-setup'}
        />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/edit-officer`}
          component={AddOfficer}
          replaceUrl={'/strategy-setup'}
        />
      </Switch>
    </Router>
  );
}

export const StrategySetup = (): JSX.Element => {
  const history = useHistory();
  return (
    <CommonLayout>
      <>
        <Routes historyLocationState={history.location.state} />
      </>
    </CommonLayout>
  );
};
