import { Box, Grid, Typography } from '@mui/material';
import { KeyValue } from './contributor';
import { SubHeading } from '../investors/components';
import React from 'react';
import { Gridstyles } from './index';
import { Applicant, ApplicationProps } from '../../redux-store/types/api-types';
import { formatShortDate } from '../../utils/date';
import { SourceOfFundMasters, residenceMasters, commSentToMaster } from '../../utils/constant';
import { checkNDPMSForIndusindBank } from '../../utils/utilityFunctions';
import { currencyConversion, isCustodianICICIOrHDFC } from '../../utils/utilityFunctions';

export default function KycDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { applicants = [] } = application || {};
  const checkNdpmsFlow = checkNDPMSForIndusindBank(
    application?.accountType,
    application?.bankDetails
  );
  const selectedCustodian = application?.custodian || '';
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Additional KYC Details
            </Typography>
            {applicants.map((applicant, index: number) => {
              const title = [
                'First Applicant Details',
                'Second Applicant Details',
                'Third Applicant Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={15}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue
                        title={'Gross Annual Income'}
                        description={applicant.grossAnnualIncome}
                      />
                      {isCustodianICICIOrHDFC(selectedCustodian) && (
                        <KeyValue
                          title={'Income'}
                          description={applicant.income}
                          amount={applicant.income && currencyConversion(applicant.income)}
                        />
                      )}

                      {/* <KeyValue
                        title={'Gross Annual Income as on Date'}
                        description={formatShortDate(applicant.grossAnnualIncomeDate)}
                      />  */}
                      <KeyValue
                        title={'NetWorth (in INR)'}
                        description={applicant.netWorth}
                        // amount={applicant.netWorth && currencyConversion(applicant.netWorth)}
                      />
                      <KeyValue
                        title={'Networth as on Date'}
                        description={formatShortDate(applicant.netWorthDate)}
                      />

                      {checkNdpmsFlow && (
                        <>
                          <KeyValue
                            title={'Source Of Fund'}
                            description={
                              SourceOfFundMasters[applicant.sourceOfFund || ''] ===
                              SourceOfFundMasters.Others
                                ? applicant.sourceOfFundOthers
                                : SourceOfFundMasters[applicant.sourceOfFund || '']
                            }
                          />
                          <KeyValue
                            title={'Residence'}
                            description={residenceMasters[applicant.residence || '']}
                          />
                          <KeyValue
                            title={'Monthly Income'}
                            description={applicant.monthlyIncome}
                          />
                          <KeyValue
                            title={'Projected Cash Transaction (Rs. per month)'}
                            description={applicant.projectedCashTransaction}
                          />
                        </>
                      )}
                      <KeyValue
                        title={'Politically Exposed Person (PEP) Status'}
                        description={applicant.politicallyExposedPersonStatus}
                      />
                      <KeyValue title={'CKYC Number'} description={applicant.ckycNo} />
                      {isCustodianICICIOrHDFC(selectedCustodian) && (
                        <KeyValue
                          title={'Communications to be sent to'}
                          description={commSentToMaster[application.commSendTo] || ''}
                        />
                      )}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
