/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CALL_API } from '../middleware';
import {
  AdminStrategyDetailsType,
  StrategyDetailsType,
  strategyOfficersDetails,
} from '../types/api-types';
import {
  GetStrategiesResponseBody,
  GET_STRATEGIES,
  GET_STRATEGIES_SUCCESS,
  GET_STRATEGY,
  GET_STRATEGY_SUCCESS,
  UPDATE_STRATEGY,
  UPDATE_STRATEGY_SUCCESS,
  GetStrategiesListResponseBody,
  ADD_STRATEGY_SUCCESS,
  ADD_STRATEGY,
  GetOfficerStrategiesListResponseBody,
  GET_OFFICER_STRATEGIES_SUCCESS,
  GET_OFFICER_STRATEGIES,
  GET_OFFICER_STRATEGY_SUCCESS,
  GET_OFFICER_STRATEGIE,
  ADD_OFFICER_STRATEGY_SUCCESS,
  ADD_OFFICER_STRATEGIE,
  UPDATE_OFFICER_STRATEGY_SUCCESS,
  UPDATE_OFFICER_STRATEGY,
} from '../types/strategies';

export type strategiesParams = {
  isActive?: boolean;
  distributorId?: string | number | null;
};

export const getStrategies =
  (params?: strategiesParams) =>
  async (dispatch: any): Promise<GetStrategiesResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/strategies`,
        method: 'GET',
        types: [GET_STRATEGIES_SUCCESS, GET_STRATEGIES],
        params,
      },
    });
  };

export const getStrategyById =
  (Id: string) =>
  async (dispatch: any): Promise<AdminStrategyDetailsType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/strategies/${Id}`,
        method: 'GET',
        types: [GET_STRATEGY_SUCCESS, GET_STRATEGY],
      },
    });
  };

export const addStrategy =
  (body: AdminStrategyDetailsType) =>
  async (dispatch: any): Promise<GetStrategiesListResponseBody[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/strategies`,
        method: 'POST',
        types: [ADD_STRATEGY_SUCCESS, ADD_STRATEGY],
        body,
      },
    });
  };

export const updateStrategy =
  (Id: number, body: any) =>
  async (dispatch: any): Promise<GetStrategiesListResponseBody[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/strategies/${Id}/update`,
        method: 'POST',
        types: [UPDATE_STRATEGY_SUCCESS, UPDATE_STRATEGY],
        body,
      },
    });
  };

export const getOfficers =
  () =>
  async (dispatch: any): Promise<GetOfficerStrategiesListResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/officers`,
        method: 'GET',
        types: [GET_OFFICER_STRATEGIES_SUCCESS, GET_OFFICER_STRATEGIES],
      },
    });
  };

export const getOfficerById =
  (Id: string) =>
  async (dispatch: any): Promise<strategyOfficersDetails> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/officers/${Id}`,
        method: 'GET',
        types: [GET_OFFICER_STRATEGY_SUCCESS, GET_OFFICER_STRATEGIE],
      },
    });
  };

export const addOfficer =
  (body: strategyOfficersDetails) =>
  async (dispatch: any): Promise<GetOfficerStrategiesListResponseBody[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/officers`,
        method: 'POST',
        types: [ADD_OFFICER_STRATEGY_SUCCESS, ADD_OFFICER_STRATEGIE],
        body,
      },
    });
  };

export const updateOfficer =
  (Id: number, body: strategyOfficersDetails) =>
  async (dispatch: any): Promise<GetOfficerStrategiesListResponseBody[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/officers/${Id}`,
        method: 'POST',
        types: [UPDATE_OFFICER_STRATEGY_SUCCESS, UPDATE_OFFICER_STRATEGY],
        body,
      },
    });
  };
