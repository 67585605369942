import {
  IconButton,
  Typography,
  CardMedia,
  LinearProgress,
  Grid,
  Tooltip,
  Button,
  Dialog,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  FatcaMdmsData,
  getApplicationDetails,
  getDocuments,
  nonIndividualFormValidForSubmission,
  updateApplication,
} from '../../redux-store/actions/application';
import {
  ApplicationProps,
  FatcaMdms,
  individuals_Poa_nonIndividuals_Documents,
  nationaliyType,
  RiskProfileMaster,
} from '../../redux-store/types/api-types';
import { ApplicationTimeline, RejectedDetails } from '../ApplicationDetails';
import { AmlDetails, CommonLayout, ConfirmationDialog } from '../commonComponents';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {
  USER_ROLES,
  ApplicationStatusBasedOnRole,
  REJECTED_ROLES,
  IndividualApplicationStatusBasedOnRole,
  APPLICATION_LISTING_STATUS,
} from '../../utils/constant';
import { formatShortDateAndTime } from '../../utils/date';
import {
  checkIfApplicationIsNonIndividual,
  isPendingByAMCAuthorisedSignatory,
} from '../../utils/utilityFunctions';
import ContributorDetails from './ContributorDetails';
import { RootStateType } from '../../redux-store/reducers';
import ContactDetails from './ContactDetails';
import BankDetails from '../ApplicationDetails/BankDetails';
import AuthorizedSignatories from './AuthorisedSignatories';
import Documents from './Document';
import Ubo from './ubo';
import Fatca from './fatca';
import { useRouteMatch } from 'react-router';
import DistributorDetails from '../ApplicationDetails/rmDistributor';
import RiskProfiles from '../ApplicationDetails/RiskProfiles';
import StrategyDetails from '../ApplicationDetails/strategyDetails';
import DisclosureOfInterest from '../ApplicationDetails/disclosureOfInterest';
import DisclosureOfExclusions from '../ApplicationDetails/disclosureOfExclusions';
import RelatedPartyConsent from '../ApplicationDetails/relatedPartyConsent';
import { getNationalityList, riskProfileMasterData } from '../../redux-store/actions';
import { getStrategies } from '../../redux-store/actions/strategies';
import { GetStrategiesResponseBody } from '../../redux-store/types/strategies';
import { showError } from '../../redux-store/actions/auth';
import DisplayImgForAuthorizedSignatories from './authorisedSignatoriesDisplayImg';
import AuthorizedSignatoriesDocuments from './AuthorizedSignatoriesDocuments';
import Comments from '../ApplicationDetails/Comments';
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';
import { MFTextField } from '../../lib/formik';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import HistoryIcon from '@mui/icons-material/History';
import { getAuthorizedSignatoriesConsentDetails } from '../dashboard/applicationslist';
import { documentDetails } from '../investors/documentDetails';

export const NonIndividualApplicationLayout = ({
  loading,
  application,
}: {
  loading: boolean;
  application: ApplicationProps;
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const { groups = [] } = application || {};
  const authorizedSignatoriesReferenceDetail = useSelector(
    (store: RootStateType) => store.authorizedSignatoriesRefrenceIdReducer
  );
  return (
    <>
      {loading && <LinearProgress />}
      {application && (
        <>
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
            }}
            mb={3}>
            <Box sx={{ p: 2 }}>
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  mb={2}>
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: 'text.secondary',
                    }}>
                    Application ID: {application.applicationNumber}
                  </Typography>
                  {application.folio_no && (
                    <Typography
                      sx={{
                        fontSize: 18,
                        fontWeight: 600,
                        color: 'text.secondary',
                      }}>
                      Folio Number: {application.folio_no}
                    </Typography>
                  )}
                  <Typography
                    className={
                      application.hasPOA
                        ? role === USER_ROLES.POAAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : APPLICATION_LISTING_STATUS[application.status]
                        : checkIfApplicationIsNonIndividual(application)
                        ? role === USER_ROLES.AMCAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : APPLICATION_LISTING_STATUS[application.status]
                        : !checkIfApplicationIsNonIndividual(application) &&
                          role === USER_ROLES.AMCAPPROVER
                        ? IndividualApplicationStatusBasedOnRole[role][application.status]
                        : APPLICATION_LISTING_STATUS[application.status]
                    }
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: 'text.secondary',
                      '&.Draft .MuiTypography-root': {
                        color: 'rgba(51, 127, 201, 0.7)',
                      },
                      '&.Completed .MuiTypography-root': {
                        fontWeight: 600,
                        color: 'rgba(35, 133, 63, 0.7)',
                      },
                      '&.Rejected .MuiTypography-root': {
                        color: '#BD2932',
                        fontWeight: 500,
                      },
                    }}>
                    Status:{' '}
                    <Typography
                      component="span"
                      sx={{
                        color: '#B78813',
                        fontSize: '16px',
                        letterSpacing: '0.4px',
                        ml: 0.5,
                      }}>
                      {USER_ROLES.AMCAUTHORISEDSIGNATORY === role &&
                      isPendingByAMCAuthorisedSignatory(application.status)
                        ? 'Pending'
                        : application.hasPOA
                        ? role === USER_ROLES.POAAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : APPLICATION_LISTING_STATUS[application.status]
                        : checkIfApplicationIsNonIndividual(application)
                        ? role === USER_ROLES.AMCAPPROVER
                          ? ApplicationStatusBasedOnRole[role][application.status]
                          : APPLICATION_LISTING_STATUS[application.status]
                        : !checkIfApplicationIsNonIndividual(application) &&
                          role === USER_ROLES.AMCAPPROVER
                        ? IndividualApplicationStatusBasedOnRole[role][application.status]
                        : APPLICATION_LISTING_STATUS[application.status]}
                    </Typography>
                  </Typography>
                  {[
                    USER_ROLES.RM,
                    USER_ROLES.DISTRIBUTOR,
                    USER_ROLES.SUBDISTRIBUTOR,
                    USER_ROLES.AMCAPPROVER,
                    USER_ROLES.AMC_ADMIN,
                  ].includes(role) &&
                    application.signedLeegalityFile?.url && (
                      <Tooltip title={'Download'}>
                        <IconButton
                          sx={{
                            p: 0,
                            pr: 0.5,
                            ':hover': {
                              bgcolor: '#F4FCFC',
                            },
                            cursor: 'pointer',
                            pointerEvents: 'all',
                          }}
                          onClick={() =>
                            application.signedLeegalityFile?.url &&
                            window.open(application.signedLeegalityFile?.url)
                          }>
                          <CardMedia
                            component="img"
                            src={'/images/download-icon.png'}
                            alt="Download"
                            sx={{ width: '25px' }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                </Grid>
                {application.status === 'rejected' && (
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={1}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <RejectedDetails
                      title={'Rejected by'}
                      description={REJECTED_ROLES[application.rejectedByRole]}
                    />
                    <RejectedDetails
                      title={'Rejected on'}
                      description={formatShortDateAndTime(application.rejectedAt)}
                    />
                    <RejectedDetails
                      title={'Reason for rejection'}
                      description={application.rejectedReason}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
          {!authorizedSignatoriesReferenceDetail.referenceId && (
            <AmlDetails
              applicants={application.applicants}
              applicationType={application.applicationType}
            />
          )}

          {groups
            .map((group) => {
              return (
                group.groupsignatories.filter((signatories) => !signatories.referenceId).length !==
                group.groupsignatories?.length
              );
            })
            .includes(true) && (
            <DisplayImgForAuthorizedSignatories
              authorisedSignatores={groups
                .map((group) => {
                  return {
                    ...group,
                    groupsignatories: group.groupsignatories?.filter(
                      (_signatory) => _signatory.photocaptureStatus
                    ),
                  };
                })
                .flat()}
              editable={!application?.signDetails?.url}
            />
          )}
          {!authorizedSignatoriesReferenceDetail.referenceId && application.comments.length > 0 && (
            <Comments application={application} />
          )}
          <DistributorDetails application={application} />
          <ContributorDetails applicants={application.applicants} />
          <ContactDetails applicants={application.applicants} />
          {/* <InvestAndPaymentDetails application={application} /> */}
          <BankDetails application={application} />
          <Documents application={application} />

          <AuthorizedSignatories groups={application.groups} />
          <Ubo application={application} />
          <RiskProfiles application={application} />
          <StrategyDetails application={application} />
          <DisclosureOfInterest application={application} />
          <DisclosureOfExclusions application={application} />
          <RelatedPartyConsent application={application} />
          <Fatca applicants={application.applicants} />
          {authorizedSignatoriesReferenceDetail.referenceId
            ? (
                application?.groups
                  ?.find(
                    (_groupSignatory) =>
                      Number(_groupSignatory.id) ===
                      Number(authorizedSignatoriesReferenceDetail.groupId)
                  )
                  ?.groupsignatories?.find(
                    (_signatory) =>
                      Number(_signatory.id) === Number(authorizedSignatoriesReferenceDetail.id)
                  )?.groupsignatorydocuments as documentDetails[]
              )?.length > 0 && <AuthorizedSignatoriesDocuments groups={application.groups} />
            : application.groups
                .map((_group) => {
                  return _group.groupsignatories.map((_signatory) =>
                    _signatory.groupsignatorydocuments &&
                    _signatory.groupsignatorydocuments.length > 0
                      ? true
                      : false
                  );
                })
                .flat()
                .some((ele) => ele === true) && (
                <AuthorizedSignatoriesDocuments groups={application.groups} />
              )}
        </>
      )}
    </>
  );
};

//Read only view for RM, Distributor, Subdistributor
export const NonIndividualApplicationDetail = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState<ApplicationProps>();
  const applicationDetailRef = useRef<HTMLDivElement>();
  const { path } = useRouteMatch();
  const [openDialog, setDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [dialogOpen, setDialogOpen] = useState(false);

  const { editable, hasPOA, applicationSentBack = false } = application || {};
  const initialCommentValues: Partial<ApplicationProps> = { comment: '' };
  const Commentvalidation = yup.object().shape({
    comment: yup.string().required('Please provide Comment'),
  });
  const handleSendBack = () => setOpenSendBackDialog(true);
  const [openSendBackDialog, setOpenSendBackDialog] = useState(false);
  const [sendBackLoading, setSendBackLoading] = useState(false);
  const handleSendBackClose = () => {
    setOpenSendBackDialog(false), setSendBackLoading(false);
  };
  const onSendBackSubmit = async (values: Partial<ApplicationProps>) => {
    try {
      handleSendBackClose();
      setSendBackLoading(true);
      const { id, status } = application || {};
      if (id) {
        await dispatch(
          updateApplication({
            body: {
              ...application,
              status: 'sent_to_amc_approver',
              comment: values.comment,
              applicationSentBack: false,
            },
            applicationId: id,
          })
        );
        history.push('/applications');
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setSendBackLoading(false);
    }
  };

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const _application = (await dispatch(
          getApplicationDetails(id)
        )) as unknown as ApplicationProps;
        if (!isComponentActive) return;
        setApplication(_application);
      } catch (e) {
        console.log(e);
      } finally {
        if (isComponentActive) setLoading(false);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  const handleSubmit = async () => {
    try {
      const { id } = application || {};
      setIsSubmitting(true);
      if (id) {
        await dispatch(
          updateApplication({
            body: {
              ...application,

              status: 'sent_to_amc_approver',
            },
            applicationId: id,
          })
        );
        history.push('/applications');
      }
    } catch (e) {
      console.error((e as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <CommonLayout>
      <>
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
          ref={applicationDetailRef}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              component={RouterLink}
              to="/applications">
              <NavigateBeforeIcon fontSize="large" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main' }}>
              Application Details
            </Typography>
          </Box>
          {dialogOpen && application && (
            <ApplicationTimeline
              id={id}
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
              consentDetails={getAuthorizedSignatoriesConsentDetails(application.groups)}
              application={application}
            />
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {editable && (
              <IconButton>
                <CardMedia
                  component="img"
                  src="/images/edit-icon-outlined.svg"
                  alt="Edit Icon"
                  sx={{ width: 'unset' }}
                  onClick={() => history.push(`/non-individual-application`, { id })}
                />
              </IconButton>
            )}
            <IconButton>
              <HistoryIcon
                fontSize="inherit"
                sx={{ color: 'primary.main', width: 'inherit', fontSize: '48px' }}
                onClick={() => {
                  setDialogOpen(true);
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <IconButton
          sx={{
            position: 'fixed',
            right: 0,
            bottom: 75,
            borderRadius: '5px  0 0 5px',
            '&,:hover': {
              bgcolor: 'primary.main',
            },
          }}
          onClick={() => applicationDetailRef.current?.scrollIntoView({ behavior: 'smooth' })}>
          <ArrowUpwardIcon fontSize="large" sx={{ color: 'common.white' }} />
        </IconButton>
        <NonIndividualApplicationLayout
          loading={loading}
          application={application as ApplicationProps}
        />
        {editable &&
          (path === '/non-individual-application-preview/:id' ||
            APPLICATION_LISTING_STATUS[application?.status || ''] ===
              APPLICATION_LISTING_STATUS.draft) && (
            <Box sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                sx={{
                  color: 'common.white',
                  minWidth: '200px',
                  mt: 4,
                  fontWeight: 600,
                  lineHeight: 1.5,
                  fontSize: '16px',
                }}
                onClick={async () => {
                  try {
                    setIsSubmitting(true);
                    const nationalitiesMdmsMasters = (await dispatch(
                      getNationalityList()
                    )) as unknown as nationaliyType;
                    const strategiesResponse = (await dispatch(
                      getStrategies({
                        isActive: true,
                        ...(application?.distributor_id
                          ? { distributorId: application?.distributor_id }
                          : {}),
                      })
                    )) as unknown as GetStrategiesResponseBody[];
                    const responseRiskProfile = (await dispatch(
                      riskProfileMasterData()
                    )) as unknown as RiskProfileMaster;
                    const { applicants = [] } = application || {};
                    const getInvestorType = applicants[0]?.investorType;
                    const response = (await dispatch(getDocuments())) as unknown as any;
                    const responseFatca = (await dispatch(FatcaMdmsData())) as unknown as FatcaMdms;
                    await nonIndividualFormValidForSubmission(
                      application as ApplicationProps,
                      response[getInvestorType || ''] as individuals_Poa_nonIndividuals_Documents[],
                      nationalitiesMdmsMasters.countries,
                      true,
                      role,
                      responseRiskProfile.riskProfile,
                      strategiesResponse,
                      true,
                      responseFatca.fatca_questions
                    );
                    setIsSubmitting(false);
                    setDialog(true);
                  } catch (e) {
                    setIsSubmitting(false);
                    typeof e === 'string' && dispatch(showError(e));
                    console.error((e as Error).message);
                  }
                }}
                disabled={isSubmitting || sendBackLoading}>
                {`${'Send to Checker'} ${isSubmitting || sendBackLoading ? '...' : ''}`}
              </Button>
            </Box>
          )}
        <Dialog
          onClose={handleSendBackClose}
          open={openSendBackDialog}
          sx={{
            '.MuiPaper-root ': {
              p: 4,
              width: { xs: '70%', sm: '70%' },
            },
          }}>
          <IconButton onClick={handleSendBackClose} sx={{ alignSelf: 'flex-end' }}>
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box
            sx={{
              width: { xs: '100%', sm: '80%' },
              mx: 'auto',
              '.MuiTypography-root': {
                fontSize: 18,
                fontWeight: 500,
                textAlign: 'center',
              },
            }}>
            <Typography sx={{ color: 'primary.main', mt: 2 }}>
              Give Comments for application Send Back *
            </Typography>
            <Formik
              initialValues={initialCommentValues}
              onSubmit={onSendBackSubmit}
              validationSchema={Commentvalidation}>
              {({ handleSubmit }) => (
                <Box component="form" noValidate onSubmit={handleSubmit}>
                  <MFTextField
                    name="comment"
                    multiline
                    rows={4}
                    placeholder="Type your answer here..."
                  />

                  <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
                    <Button
                      onClick={() => {
                        handleSendBackClose();
                      }}
                      variant="outlined"
                      sx={{
                        fontSize: 15,
                        color: 'primary.main',
                        minWidth: 180,
                        letterSpacing: 2,
                        fontWeight: 500,
                      }}
                      disabled={sendBackLoading}>
                      Cancel
                    </Button>
                    <LoadingButton
                      loading={sendBackLoading}
                      variant="contained"
                      sx={{
                        fontSize: 15,
                        minWidth: 180,
                        letterSpacing: 2,
                        fontWeight: 400,
                      }}
                      type="submit">
                      Continue
                    </LoadingButton>
                  </Grid>
                </Box>
              )}
            </Formik>
          </Box>
        </Dialog>
        <ConfirmationDialog
          message={'Are you sure you want to send it to Checker?'}
          open={openDialog}
          setOpen={() => setDialog(false)}
          onSave={async () => {
            setDialog(false);
            applicationSentBack ? handleSendBack() : handleSubmit();
          }}
          onCancel={() => setDialog(false)}
        />
      </>
    </CommonLayout>
  );
};
