import { Box, Button, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import { Formik, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  Distributor,
  getDistributors,
  getDistributorsResponse,
  SubDistributor,
} from '../../redux-store/actions';
import React, { useEffect, useState } from 'react';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory, useParams } from 'react-router';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  APPLICATION_LISTING_STATUS,
  BankAccountTypeMaster,
  CustodianMaster,
  NDPSMBankMaster,
  USER_ROLES,
  applicantStatusMasters,
  custodianAndFirstNameLastNameDate,
} from '../../utils/constant';
import { InfoDialog, Notes, ProceedSaveLater, SubHeading } from './components';
import UseRadioGroup from '../../lib/formik/Radio';
import { distributorsValidationSchema } from '../../utils/schema';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  applicationComparison,
  applyRoleBasedStatus,
  checkNDPMS,
  checkValidationBasedOnDate,
  disableBankDetail,
  getApplicantIdForInvestorPhotoCapture,
  isBankDetailsChanged,
  saveForLater,
  saveForLaterInvestorPhotoCapture,
  showPopBankDetail,
} from '../../utils/utilityFunctions';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { ConfirmationDialog } from '../commonComponents';
import MFSelectField from '../../lib/formik/SelectField';
import { useSnackbar } from 'notistack';
import { OnEditDialogBox } from './additionalKYCDetails';

type DistributorDetailsProps = {
  distributorName: string | null;
  distributorId: number | null;
  loginType: string;
  firstName: string;
  empCode: string;
  phone: string | undefined;
  email: string;
  subdistributorId: number | null;
  subdistributorName: string | null;
  subdistributorEmail: string | null;
  filledBy: string | null;
  saveType: string;
  custodian: string;
  bankDetails: string | null;
};

function GridItem({ children }: { children: React.ReactNode }) {
  return (
    <Grid item xs={12} sm={6}>
      {children}
    </Grid>
  );
}

const RMDetail = () => {
  return (
    <>
      <GridItem>
        <MFTextField
          name="firstName"
          label="RMs Name"
          placeholder="Enter RM name"
          disabled={true}
        />
      </GridItem>
      <GridItem>
        <MFTextField
          name="email"
          label="RMs Email Address"
          placeholder="Enter RM Email ID"
          disabled={true}
        />
      </GridItem>
      <GridItem>
        <MFTextField
          name="phone"
          label="RMs Mobile Number"
          placeholder="Enter RMs Mobile Number"
          disabled={true}
        />
      </GridItem>
      {/* <GridItem>
        <MFTextField
          name="empCode"
          label="Employee Code"
          placeholder="Enter Employee Code"
          disabled={true}
        />
      </GridItem> */}
    </>
  );
};

const DistributorDetail = ({
  distributors,
  values,
  selectedDistributor,
  setSelectedDistributor,
}: {
  distributors: Distributor[];
  values: DistributorDetailsProps;
  selectedDistributor: SubDistributor[];
  setSelectedDistributor: (distributor: SubDistributor[]) => void;
}): JSX.Element => {
  const { role } = useSelector((store: RootStateType) => store.auth);
  const { setValues } = useFormikContext();
  const [open, setOpen] = useState(false);
  const [openDialog, setDialog] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDialogOpen = () => {
    setDialog(true);
  };
  const handleDialogClose = () => {
    setDialog(false);
  };

  return (
    <>
      {distributors.length > 0 ? (
        <>
          {[USER_ROLES.RM].includes(role) && values.distributorId && (
            <Grid item xs={12} sx={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                sx={{
                  color: 'white',
                  fontWeight: 500,
                  fontSize: 14,
                  py: 0.5,
                  px: 3,
                }}
                onClick={handleDialogOpen}>
                Clear
              </Button>
            </Grid>
          )}
          <GridItem>
            <SearchableSelect
              name="distributorId"
              label="Distributor Code *"
              items={distributors
                .sort((distributor1, distributor2) =>
                  (distributor1['name'] || '')
                    .toString()
                    .localeCompare((distributor2['name'] || '').toString())
                )
                .map((item) => ({
                  key: (item.name || 'NA') + ' - ' + item.arnCode,
                  value: item.id,
                }))}
              onChange={({ target: { value } }) => {
                const distributorCode = distributors.find((item) => item.id === value);
                const { id = null, name } = distributorCode || {};
                setValues({
                  ...values,
                  distributorName: name,
                  distributorId: id,
                  subdistributorId: null,
                  subdistributorName: '',
                  subdistributorEmail: '',
                });
                setSelectedDistributor(
                  distributors.find((distributor) => distributor.id === value)?.subdistributors ||
                    []
                );
              }}
              disabled={[
                USER_ROLES.DISTRIBUTOR,
                USER_ROLES.INVESTOR,
                USER_ROLES.POAAPPROVER,
                USER_ROLES.SUBDISTRIBUTOR,
              ].includes(role)}
              applyRenderValue={true}
              searchFieldPlaceholder={'Search Distributor'}
            />
          </GridItem>
          <GridItem>
            <MFTextField
              name="distributorName"
              label="Distributor/IFA Name *"
              placeholder="Enter Distributor name"
              disabled={true}
            />
          </GridItem>
          {!!selectedDistributor?.length && (
            <>
              <GridItem>
                <SearchableSelect
                  name="subdistributorId"
                  label="Distributor's RM Code *"
                  items={selectedDistributor
                    .sort((subDistributor1, subDistributor2) =>
                      (subDistributor1.user['firstName'] || '')
                        .toString()
                        .localeCompare((subDistributor2.user['firstName'] || '').toString())
                    )
                    .map((item) => ({
                      key: item.user.firstName + ' - ' + (item.distributors_RM_Code || 'NA'),
                      value: item.id,
                    }))}
                  onChange={({ target: { value } }) => {
                    const distributorCode = selectedDistributor.find((item) => item.id === value);
                    const { id = null, user } = distributorCode || {};
                    const { email, firstName } = user || {};
                    setValues({
                      ...values,
                      subdistributorId: id,
                      subdistributorName: firstName,
                      subdistributorEmail: email,
                    });
                  }}
                  disabled={[
                    USER_ROLES.DISTRIBUTOR,
                    USER_ROLES.INVESTOR,
                    USER_ROLES.POAAPPROVER,
                    USER_ROLES.SUBDISTRIBUTOR,
                  ].includes(role)}
                  searchFieldPlaceholder={"Search Distributor's RM"}
                />
              </GridItem>
              <GridItem>
                <MFTextField
                  name="subdistributorName"
                  label="Distributor's RM Name *"
                  placeholder="Enter Distributor name"
                  disabled={true}
                />
              </GridItem>
              <GridItem>
                <MFTextField
                  name="subdistributorEmail"
                  label="Distributor's RM Email *"
                  placeholder="Enter Distributor email"
                  disabled={true}
                />
              </GridItem>
              <GridItem>
                <SearchableSelect
                  name="subdistributorId"
                  label="Distributor RM/Advisor Name-Location *"
                  items={selectedDistributor
                    .sort((subDistributor1, subDistributor2) =>
                      (subDistributor1.user['firstName'] || '')
                        .toString()
                        .localeCompare((subDistributor2.user['firstName'] || '').toString())
                    )
                    .map((item) => ({
                      key: item.user.firstName as string,
                      value: item.id,
                    }))}
                  onChange={({ target: { value } }) => {
                    const distributorCode = selectedDistributor.find((item) => item.id === value);
                    const { id = null, user } = distributorCode || {};
                    const { email } = user || {};
                    setValues({
                      ...values,
                      subdistributorId: id,
                      subdistributorEmail: email,
                    });
                  }}
                  disabled={[
                    USER_ROLES.DISTRIBUTOR,
                    USER_ROLES.INVESTOR,
                    USER_ROLES.POAAPPROVER,
                    USER_ROLES.SUBDISTRIBUTOR,
                  ].includes(role)}
                  searchFieldPlaceholder={"Search Distributor's RM"}
                />
              </GridItem>
            </>
          )}
          <GridItem>
            <MFTextField name="filledBy" label="Filled by" placeholder="Enter filled by" />
            <Typography
              component="span"
              sx={{ position: 'relative', left: 64, bottom: { xs: 85, md: 91 } }}>
              <InfoOutlinedIcon color="info" fontSize="small" onClick={handleOpen} />
            </Typography>
          </GridItem>
          <InfoDialog
            onClose={handleClose}
            open={open}
            content={'Please add the Name of the Subdistributor RM.'}
          />
        </>
      ) : (
        <Grid
          xs={12}
          sm={12}
          sx={{
            fontSize: 18,
            fontWeight: 500,
            color: 'rgb(97, 209, 214)',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            mt: { lg: 9, xs: 9 },
            mb: 2.9,
            ml: { xs: 3 },
          }}>
          No distributor mapped under this RM
        </Grid>
      )}
      <ConfirmationDialog
        message={`Are you sure you want to clear Distributor details ?`}
        open={openDialog}
        setOpen={handleDialogClose}
        onSave={() => {
          handleDialogClose();
          setValues({
            ...values,
            distributorName: '',
            distributorId: null,
            subdistributorId: null,
            subdistributorName: '',
            subdistributorEmail: '',
          });
          setSelectedDistributor([]);
        }}
        onCancel={handleDialogClose}
      />
    </>
  );
};

export default function DistributorDetails(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [distributors, setDistributors] = useState<Distributor[]>([]);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role } = useSelector((store: RootStateType) => store.auth);
  const [selectedDistributor, setSelectedDistributor] = useState<SubDistributor[]>([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const [showDialogForEdit, setShowShowForEdit] = useState(false);
  const [finalvalue, setFinalValue] = useState<any>();

  const {
    distributor,
    rm,
    subdistributorId = null,
    subDistributor,
    filledBy = '',
    custodian = '',
    bankDetails = '',
    accountType,
    createdAt = '',
    bankAccountTypeNdpms = '',
  } = application || {};

  const { name, id } = distributor || {
    arnCode: '',
    name: '',
    id: null,
  };

  const {
    empCode,
    user: { email: RMEmail = '', firstName: RMFirstName = '', phone },
  } = rm || { empCode: '', user: { email: '' } };

  const {
    user: { firstName: subdistributorName, email: subdistributorEmail },
  } = subDistributor || { user: { firstName: '', email: '' } };

  const initialValues: DistributorDetailsProps = {
    distributorId: id,
    distributorName: name,
    loginType: 'rm',
    empCode,
    email: RMEmail,
    phone: phone,
    firstName: RMFirstName,
    subdistributorId: subdistributorId === null ? subdistributorId : Number(subdistributorId),
    subdistributorName,
    subdistributorEmail,
    filledBy,
    saveType: 'save and proceed',
    custodian,
    bankDetails,
  };

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        if (Object.keys(application || {}).length && role === USER_ROLES.RM) {
          const res = (await dispatch(
            getDistributors({ isActive: true })
          )) as unknown as getDistributorsResponse;
          const { distributors = [] } = res || {};
          const updatedDistributor =
            application?.distributor_id && !application.distributor.isActive
              ? [
                  ...distributors,
                  {
                    ...application.distributor,
                    subdistributors: [application.subDistributor],
                  } as Distributor,
                ]
              : distributors;
          if (isComponentActive) {
            const updateSubdistributor =
              application?.distributor_id &&
              application?.distributor.isActive &&
              application?.subdistributorId &&
              !application.subDistributor?.isActive
                ? [
                    ...(updatedDistributor.find((distributor) => distributor.id === id)
                      ?.subdistributors || []),
                    { ...application.subDistributor },
                  ]
                : updatedDistributor.find((distributor) => distributor.id === id)
                    ?.subdistributors || [];
            setSelectedDistributor(updateSubdistributor as unknown as SubDistributor[]);
            setDistributors(updatedDistributor as unknown as Distributor[]);

            // console.log(loginType, 'selected distri');
          }
        } else {
          const { distributor, subDistributor } = application || {};
          setDistributors(distributor ? [distributor as Distributor] : []);
          setSelectedDistributor(
            subDistributor
              ? [{ ...subDistributor, id: Number(subDistributor.id) } as SubDistributor]
              : []
          );
        }
      } catch (e: unknown) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [application]);

  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();

  const onSubmit = async (values: DistributorDetailsProps) => {
    try {
      const { distributorId, subdistributorId, filledBy, custodian, bankDetails, saveType } =
        values;
      const {
        id,
        applicant1ReferenceId,
        currentStep = null,
        banks,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const isSaveLater = saveType !== 'save and proceed';
      const checkApplication = applicationComparison(application, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        distributor_id: distributorId,
        subdistributorId: subdistributorId ? subdistributorId.toString() : subdistributorId,
        filledBy: filledBy,
        custodian: custodian,
        bankDetails: checkNDPMS(accountType) ? bankDetails : '',
        currentStep: currentStep === null ? 1 : currentStep,
      });

      //save for later route for investor at liveliness
      const investorEditSaveelaterRoute =
        referenceIdForInvestorPhotoCapture &&
        saveForLaterInvestorPhotoCapture(referenceIdForInvestorPhotoCapture, application);
      const referenceIdForSaveProceedRoute = referenceIdForInvestorPhotoCapture
        ? referenceIdForInvestorPhotoCapture
        : referenceDetail.esignReferenceId;
      const isDistributorPresent =
        ((!distributorId && application?.distributor_id) ||
          (distributorId && Number(distributorId) !== Number(application?.distributor_id))) &&
        application?.applicationstrategydetails?.length;
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ...application!,
              distributor_id: distributorId,
              subdistributorId,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              banks: isBankDetailsChanged(application?.bankDetails, values.bankDetails)
                ? application?.banks.length
                  ? application?.banks.map((applicant) => {
                      return { ...applicant, isActive: false };
                    })
                  : application?.banks
                : application?.banks,
              currentStep: 1,
              //currentStep === null ? 1 : currentStep
              filledBy,
              custodian,
              bankDetails: checkNDPMS(accountType) ? bankDetails : '',
              ndpmsSectionEdited: showDialogForEdit ? true : application?.ndpmsSectionEdited,
              applicationstrategydetails: application?.applicationstrategydetails?.length
                ? isDistributorPresent
                  ? application?.applicationstrategydetails?.map((item) => ({
                      ...item,
                      isActive: false,
                    }))
                  : application?.applicationstrategydetails
                : [],
            },
            applicationId: id,
          })
        );
        !isSaveLater
          ? history.push('contributor-details', {
              id,
              applicant1ReferenceId: referenceIdForSaveProceedRoute,
            })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('contributor-details', {
              id,
              applicant1ReferenceId: referenceIdForSaveProceedRoute,
            })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  const manageSubmit = (values: DistributorDetailsProps) => {
    const { bankAccountFormCreated = false, showBankIcon = false } = application || {};
    const { bankDetails = '' } = values;
    const checkApplication = applicationComparison(application, {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ...application,
      bankDetails: checkNDPMS(accountType) ? bankDetails : '',
    });
    if (
      showPopBankDetail(
        checkApplication,
        accountType,
        bankAccountFormCreated,
        showBankIcon,
        bankDetails,
        role
      )
    ) {
      setFinalValue(values);
      setShowShowForEdit(true);
    } else {
      onSubmit(values);
    }
  };

  const isFieldDisabled = [USER_ROLES.INVESTOR].includes(role);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={distributorsValidationSchema(
        selectedDistributor,
        distributors,
        checkNDPMS(accountType),
        createdAt
      )}
      onSubmit={manageSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Notes
            displayContent={
              'This section specifies whether the application is from an RM/Distributor’s RM.'
            }
          />
          <Grid container rowSpacing={2} columnSpacing={5} sx={{ pt: '25px !important' }}>
            {[USER_ROLES.RM, USER_ROLES.INVESTOR].includes(role) && (
              <Grid item xs={12}>
                <UseRadioGroup
                  formLabel=""
                  name={`loginType`}
                  items={[
                    { label: 'RM', value: USER_ROLES.RM },
                    { label: 'Distributor', value: USER_ROLES.DISTRIBUTOR },
                  ]}
                />
              </Grid>
            )}
            {![USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR, USER_ROLES.POAAPPROVER].includes(
              role
            ) && values.loginType === USER_ROLES.RM ? (
              <RMDetail />
            ) : (
              <DistributorDetail
                distributors={distributors}
                values={values}
                selectedDistributor={selectedDistributor}
                setSelectedDistributor={(subdistributors) =>
                  setSelectedDistributor(subdistributors)
                }
              />
            )}
            <Grid item xs={12}>
              <SubHeading sx={{ color: 'common.primary', letterSpacing: 0.5 + 'px' }}>
                {'Custodian Details'}
              </SubHeading>
            </Grid>
            <GridItem>
              <MFSelectField
                name={`custodian`}
                label={`Custodian Name   
                    ${
                      checkValidationBasedOnDate(createdAt, custodianAndFirstNameLastNameDate)
                        ? '*'
                        : ''
                    }`}
                items={CustodianMaster.map((item) => ({
                  key: item,
                  value: item,
                }))}
                applyLabelStyles={true}
                disabled={
                  (values.loginType === USER_ROLES.DISTRIBUTOR && distributors.length === 0) ||
                  isFieldDisabled ||
                  [USER_ROLES.AMCAPPROVER].includes(role)
                }
              />
            </GridItem>
            {checkNDPMS(accountType) && (
              <>
                <Grid item xs={12}>
                  <SubHeading sx={{ color: 'common.primary', letterSpacing: 0.5 + 'px' }}>
                    {'Bank Details'}
                  </SubHeading>
                </Grid>
                <GridItem>
                  <MFSelectField
                    name={`bankDetails`}
                    label={'Bank Details *'}
                    items={Object.keys(NDPSMBankMaster).map((item) => ({
                      key: NDPSMBankMaster[item],
                      value: item,
                    }))}
                    applyLabelStyles={true}
                    disabled={
                      (values.loginType === USER_ROLES.DISTRIBUTOR && distributors.length === 0) ||
                      isFieldDisabled ||
                      disableBankDetail(
                        role,
                        application?.status,
                        application?.bankAccountFormCreated,
                        application?.showBankIcon
                      )
                    }
                  />
                </GridItem>
              </>
            )}
          </Grid>
          <OnEditDialogBox
            open={showDialogForEdit}
            setOpen={setShowShowForEdit}
            handleSubmit={onSubmit}
            values={finalvalue}
            message={'Please confirm if new bank account is not to be opened for this application'}
          />
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Box>
      )}
    </Formik>
  );
}
