import { ExitToApp } from '@mui/icons-material';
import { AppBar, CardMedia, Divider, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { Location } from 'history';
import { Redirect, Route, Switch, useRouteMatch, useHistory } from 'react-router';
import { logout } from '../../redux-store/actions/auth';
import LoginOtp from '../auth/loginOtp';
import { Footer } from '../commonComponents';
import { Routes } from '../investors/routes';
import { useDispatch, useSelector } from 'react-redux';
import { USER_ROLES } from '../../utils/constant';
import { RootStateType } from '../../redux-store/reducers';
import LoginRequireRoute from '../../routes/LoginRequireRoute';
import MobileLogin from '../auth/MobileLogin';
import InvestorApplicationBeforeFaceCapture from '../geoLocationAndFaceCapture.tsx/investorApplicationEdit';
import WebcamCapture, {
  CapturedDetailsSucsessfully,
} from '../geoLocationAndFaceCapture.tsx/faceCapture';
import LocationCapture from '../geoLocationAndFaceCapture.tsx/locationCapture';
import AuthorizedSignatoriesInvestorApplication from './signatories-application';
import InvestmentDetails from '../Investments/investor-details';
import ProtectedRoute from '../../routes/ProtectedRoute';

const AuthorizedSignatoriesRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();
  const result = useRouteMatch<{ slug: string }>({
    path: `${path}/:slug/`,
  });
  const referenceId = result?.params.slug;

  return (
    <Switch>
      <Route
        exact
        path={path}
        render={(props: any) => (
          <Redirect to={`${path}/:referenceId/strategy-details`} from={props.location.state} />
        )}
      />
      <Route path={`${path}/:referenceId/strategy-details`} component={InvestmentDetails} />
      <Route path={`${path}/:referenceId/otp`} component={LoginOtp} />
      <ProtectedRoute
        path={`${path}/:referenceId/nonIndividual-application-details`}
        component={AuthorizedSignatoriesInvestorApplication}
        isProtectedRouteForRefLogin={true}
        redirectedRoute={`/authorized-signatories-investment-details/${referenceId}/strategy-details`}
      />
      <ProtectedRoute
        path={`${path}/:referenceId/photo-capture`}
        component={WebcamCapture}
        isProtectedRouteForRefLogin={true}
        redirectedRoute={`/authorized-signatories-investment-details/${referenceId}/strategy-details`}
      />
      <ProtectedRoute
        path={`${path}/:referenceId/location-capture`}
        component={LocationCapture}
        isProtectedRouteForRefLogin={true}
        redirectedRoute={`/authorized-signatories-investment-details/${referenceId}/strategy-details`}
      />
      <LoginRequireRoute
        path="*"
        routePath={`/authorized-signatories-investment-details/${referenceId}/strategy-details`}
      />
      {/* <Route
        path={`${path}/:referenceId/nonIndividual-application-details`}
        component={AuthorizedSignatoriesInvestorApplication}
      />
      <Route path={`${path}/:referenceId/photo-capture`} component={WebcamCapture} />
      <Route path={`${path}/:referenceId/location-capture`} component={LocationCapture} /> */}
      {/* <Route
        path="*"
        render={() => {
          return 'Page you are requesting is not found.....';
        }}
      /> */}
      {/* <LoginRequireRoute path="*" /> */}
    </Switch>
  );
};

export default AuthorizedSignatoriesRoutes;
