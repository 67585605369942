import React from 'react';
import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Hidden,
  LinearProgress,
  Button,
  Stack,
  Tooltip,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik, FormikHelpers } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useDispatch } from 'react-redux';
import { SubHeading } from '../investors/components';
import { Location } from 'history';
import {
  AdminStrategyDetailsType,
  DistributorListType,
  DistributorForStrategy,
  investmentAmountsType,
  operationalChargesType,
} from '../../redux-store/types/api-types';
import {
  allowOnlyNumbers,
  sortList,
  sortStrategyPerformenceFee,
} from '../../utils/utilityFunctions';
import { addStrategy, getStrategyById, updateStrategy } from '../../redux-store/actions/strategies';
import { addStrategyValidation } from '../../utils/schema';
import { ColumnType, DataTable } from '../DataTable';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import { ConfirmationDialog } from '../commonComponents';
import { MultipleSelect } from '../../lib/formik/MultipleSelectField';
import { getDistributorsListForFilters } from '../../redux-store/actions';
import { STRATEGY_TYPE_OPTIONS } from '../../utils/constant';
import MFSelectField from '../../lib/formik/SelectField';

type updatedinvestmentAmountsType = investmentAmountsType & {
  sno: number;
};
const operationChargeDetails = {
  sno: 0,
  isActive: true,
  otherOperationalCharges: '',
};
export const filterOperationalDetails = (
  operationalCharge: operationalChargesType[]
): operationalChargesType[] => {
  const filteredOperationalDetails = operationalCharge?.filter(
    (item: operationalChargesType) => item.isActive
  );
  return filteredOperationalDetails;
};
const newInvestmentAmounts: updatedinvestmentAmountsType = {
  managementFee: '',
  investmentAmount: '',
  performanceFee: '',
  isActive: true,
  sno: 0,
};

type updatedAdminStrategyDetailsType = {
  investmentamounts: updatedinvestmentAmountsType[];
  distributorIds: number[] | string[] | null;
} & AdminStrategyDetailsType;

const initialValues: updatedAdminStrategyDetailsType = {
  strategyName: '',
  strategyType: '',
  benchmark: '',
  exitLoad: '',
  entryLoad: '',
  investmentamounts: [newInvestmentAmounts],
  isActive: true,
  distributors: [],
  distributorIds: null,
  performanceFee: '',
  operationalCharges: [],
  feeType: '',
};

export default function AddStrategy({
  location,
}: {
  location: Location<{ StrategyId: string }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [strategyInitial, setStrategyInitial] = useState(initialValues);
  const [access, setAccess] = useState(true);
  const { StrategyId } = location.state || {};
  const [operationalChargeToDelete, setOperationalChargeToDelete] = useState<number | null>(null);
  const [investmentAmountsToDelete, setInvestmentAmountsToDelete] = useState<{
    index: number;
  } | null>(null);

  const [distributorList, setDistributorList] = useState<DistributorForStrategy[]>([]);

  const columns: any = [
    {
      header: 'Investment Amount',
      label: 'investmentAmount',
      renderEditDeleteCell: (row: updatedinvestmentAmountsType): JSX.Element => {
        return (
          <>
            {/* <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                display: 'inline-flex',
                width: 250,
                maxWidth: '100%',
                py: 1,
                '& .MuiFormControl-root': { width: '100%', m: 0 },
              }}> */}
            <MFTextField
              name={`investmentamounts.${row.sno}.investmentAmount`}
              placeholder="Enter Investment Amount"
            />
            {/* </Stack> */}
          </>
        );
      },
    },
    {
      header: 'Performance Fee',
      label: 'performanceFee',
      renderEditDeleteCell: (row: updatedinvestmentAmountsType): JSX.Element => {
        return (
          // <Stack
          //   sx={{
          //     flexDirection: 'row',
          //     alignItems: 'center',
          //     display: 'inline-flex',
          //     // width: 350,
          //     // maxWidth: '100%',
          //     py: 1,
          //     '& .MuiFormControl-root': { width: '100%', m: 0 },
          //   }}>
          <MFTextField
            name={`investmentamounts.${row.sno}.performanceFee`}
            placeholder="Enter Performance Fee"
          />
          // </Stack>
        );
      },
    },
    // {
    //   header: 'Management Fee',
    //   label: 'managementFee',
    //   renderEditDeleteCell: (row: updatedinvestmentAmountsType): JSX.Element => {
    //     return (
    //       <Stack
    //         sx={{
    //           flexDirection: 'row',
    //           alignItems: 'center',
    //           display: 'inline-flex',
    //           // width: 350,
    //           maxWidth: '100%',
    //           py: 1,
    //           '& .MuiFormControl-root': { width: '100%', m: 0 },
    //         }}>
    //         <MFTextField
    //           name={`investmentamounts.${row.sno}.managementFee`}
    //           placeholder="Enter Management Fee"
    //         />
    //       </Stack>
    //     );
    //   },
    // },
  ];
  const DistributorPerformenceFeeColumns = ({
    values,
    setValues,
  }: {
    values: updatedAdminStrategyDetailsType;
    setValues: FormikHelpers<updatedAdminStrategyDetailsType>['setValues'];
  }): ColumnType[] => [
    {
      header: 'Distributor Name',
      label: 'distributorIds',
      renderCell: (row: DistributorForStrategy) => {
        return (
          <Typography
            sx={{
              textTransform: 'capitalize',
              mt: { xs: 1, md: 2 },
              mb: 1,
            }}>
            {row.name}
          </Typography>
        );
      },
    },
    {
      header: 'Performance Fee',
      label: 'distributors',
      renderEditDeleteCell: (row: DistributorForStrategy, index): JSX.Element => {
        return (
          <>
            <MFTextField
              name={`distributors.${index}.performanceFee`}
              placeholder="Enter Performance Fee"
            />
          </>
        );
      },
    },
  ];
  const AccessViewOrEditCell = (investmentAmounts: updatedinvestmentAmountsType[]) => [
    {
      header: '',
      renderCell: (row: updatedinvestmentAmountsType): JSX.Element => {
        return (
          <>
            {investmentAmounts?.filter((obj: any) => obj.isActive).length > 1 && (
              <Stack
                sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  display: 'inline-flex',
                  maxWidth: '100%',
                  py: 1.5,
                  '& .MuiFormControl-root': { width: '100%', m: 0 },
                }}>
                <Tooltip title="Delete">
                  <IconButton
                    sx={{
                      ':hover': {
                        bgcolor: '#F4FCFC',
                      },
                    }}
                    onClick={() => setInvestmentAmountsToDelete({ index: row.sno })}>
                    <DeleteOutlineIcon color="error" fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const resDistributors = (await dispatch(
          getDistributorsListForFilters({ isActive: true })
        )) as unknown as DistributorForStrategy[];
        let _AllDistributors = resDistributors;

        if (StrategyId) {
          const GetStrategyDetail = (await dispatch(
            getStrategyById(StrategyId)
          )) as unknown as AdminStrategyDetailsType;
          const {
            strategyName,
            strategyType,
            benchmark,
            entryLoad,
            isActive,
            exitLoad,
            investmentamounts,
            distributors,
            performanceFee,
            otherOperationalCharges,
          } = GetStrategyDetail || {};
          _AllDistributors = resDistributors.map((item, index) => {
            if (distributors?.find((_item) => Number(_item.id) === Number(item.id))) {
              return {
                ...distributors?.find((_item) => Number(_item.id) === Number(item.id)),
                inActive: true,
                sno: index,
                feeType: 'hybrid',
              };
            }
            return {
              ...item,
              performanceFee: '',
              inActive: false,
              sno: index,
              feeType: 'hybrid',
            };
          }) as unknown as DistributorForStrategy[];
          setStrategyInitial({
            ...strategyInitial,
            strategyName,
            entryLoad,
            strategyType,
            benchmark,
            exitLoad,
            performanceFee,
            operationalCharges:
              (otherOperationalCharges || [])?.length > 0
                ? (otherOperationalCharges || [])?.map((item, index) => {
                    return {
                      otherOperationalCharges: item,
                      isActive: true,
                      sno: index,
                    };
                  })
                : [{ ...operationChargeDetails }],
            investmentamounts: investmentamounts?.length
              ? investmentamounts?.map((obj, index) => {
                  return { ...obj, sno: index };
                })
              : [newInvestmentAmounts],
            distributorIds:
              (GetStrategyDetail?.distributors?.map((item) => item.id) as number[] | string[]) ||
              [],
            distributors: sortStrategyPerformenceFee(
              _AllDistributors,
              GetStrategyDetail?.distributors?.map((item) => item.id.toString()) || []
            ) as DistributorForStrategy[],
            isActive,
          });
        } else {
          _AllDistributors = resDistributors.map((item, index) => {
            return {
              ...item,
              performanceFee: '',
              inActive: false,
              feeType: 'hybrid',
              sno: index,
            };
          }) as unknown as DistributorForStrategy[];
          setStrategyInitial({
            ...strategyInitial,
            operationalCharges: [{ ...operationChargeDetails }],
            distributors: _AllDistributors,
          });
        }
        setDistributorList(_AllDistributors);
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: updatedAdminStrategyDetailsType) => {
    try {
      const { operationalCharges, distributorIds, distributors, investmentamounts, ...rest } =
        values;
      const _values = {
        ...rest,
        otherOperationalCharges: filterOperationalDetails(operationalCharges || []).map((item) => {
          return item?.otherOperationalCharges;
        }),
        distributors: distributors
          .filter((item) => item.inActive)
          .map((item) => {
            const { inActive, sno, ..._item } = item;
            return _item;
          }),
        // investmentamounts: values.investmentamounts?.filter(
        //   (value) => value?.id || value.isActive
        // ),
      };
      if (!StrategyId) {
        await dispatch(addStrategy(_values));
      } else {
        await dispatch(updateStrategy(Number(StrategyId), _values));
      }
      history.push(StrategyId ? 'strategy-details' : 'strategy-list', {
        StrategyId: StrategyId,
      });
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={strategyInitial}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={addStrategyValidation}>
        {({ handleSubmit, values, setValues }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(StrategyId ? 'strategy-details' : 'strategy-list', {
                      StrategyId: StrategyId,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  Strategy Setup
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(StrategyId ? 'strategy-details' : 'strategy-list', {
                      // planId: StrategyId,
                      StrategyId: StrategyId,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {StrategyId ? 'Edit Strategy' : 'Add Strategy'}
              </Typography>
            </Box>

            {loading ? (
              <LinearProgress sx={{ mt: 2 }} />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                  <SubHeading
                    sx={{
                      color: 'common.blue',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                      ml: { xs: 3.5 },
                    }}>
                    {'Strategy Details'}
                  </SubHeading>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="strategyName"
                      label="Strategy Name *"
                      placeholder="Enter Strategy Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="entryLoad"
                      label="Entry Load"
                      placeholder="Enter Entry Load"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="exitLoad" label="Exit Load" placeholder="Enter Exit Load" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="performanceFee"
                      label="Performance Fee *"
                      placeholder="Enter Performance Fee"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFSelectField
                      name={`strategyType`}
                      label="Strategy type *"
                      items={Object.keys(STRATEGY_TYPE_OPTIONS)?.map((item) => ({
                        key: STRATEGY_TYPE_OPTIONS[item],
                        value: item,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name={`benchmark`}
                      label="Benchmark *"
                      placeholder="Enter Benchmark"
                    />
                  </Grid>
                  <>
                    <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                      <SubHeading
                        sx={{
                          width: '100%',
                          color: 'common.blue',
                          letterSpacing: 0.5 + 'px',
                          padding: { xs: '10px 10px', sm: '10px 30px' },
                          ml: { xs: 3.5 },
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}>
                        <Typography>Other Operational Charges </Typography>
                      </SubHeading>
                    </Grid>
                    {values?.operationalCharges &&
                      values?.operationalCharges?.length > 0 &&
                      filterOperationalDetails(values?.operationalCharges || [])?.map(
                        (charge, index) => {
                          return (
                            <React.Fragment key={index}>
                              <Box
                                sx={{
                                  width: '100%',
                                  bgcolor: 'white',
                                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.10)',
                                  borderRadius: '10px',
                                  py: { xs: 2, sm: 2 },
                                  pl: { xs: 0, sm: 2 },
                                  mt: { xs: 2, sm: 2 },
                                }}>
                                <Grid container rowSpacing={1} columnSpacing={4} px={4}>
                                  <Grid
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'flex-end'}
                                    item
                                    sx={{ pt: 0 }}
                                    xs={12}
                                    sm={12}
                                    md={12}>
                                    <IconButton
                                      disabled={
                                        filterOperationalDetails(values?.operationalCharges || [])
                                          ?.length === 1
                                      }
                                      onClick={() => {
                                        setOperationalChargeToDelete(charge.sno as number);
                                      }}>
                                      <DeleteOutlineIcon
                                        titleAccess="Delete Form"
                                        sx={{ cursor: 'pointer' }}
                                        color={
                                          filterOperationalDetails(values?.operationalCharges || [])
                                            ?.length === 1
                                            ? 'disabled'
                                            : 'error'
                                        }
                                        fontSize="small"
                                      />
                                    </IconButton>
                                  </Grid>

                                  <Grid sx={{ pt: 0 }} item xs={12}>
                                    <MFTextField
                                      name={`operationalCharges.${charge.sno}.otherOperationalCharges`}
                                      label="Operational Charge *"
                                      placeholder="Enter Operational Charge"
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            </React.Fragment>
                          );
                        }
                      )}
                  </>
                  <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                    <Grid
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'flex-end'}
                      item
                      xs={12}
                      sm={12}
                      md={12}>
                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        sx={{
                          color: 'primary.main',
                          fontWeight: 500,
                          fontSize: 14,
                          mr: { xs: 0, sm: 5 },
                          mt: { xs: 0.5, sm: 1 },
                        }}
                        onClick={() => {
                          const newOperationalCharge = {
                            ...operationChargeDetails,
                            sno: values.operationalCharges?.length,
                          };
                          setValues((val) => {
                            return {
                              ...val,
                              operationalCharges: [
                                ...(val.operationalCharges || []),
                                newOperationalCharge,
                              ],
                            };
                          });
                        }}>
                        Add Operational Charge
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <MultipleSelect
                      name="distributorIds"
                      label="Applicable Distributor(s)"
                      items={distributorList.map((item) => ({
                        key: item.id,
                        value: `${item.arnCode || 'N/A'} - ${item.name} (${item.type})`,
                      }))}
                      renderText="Selected Distributor(s)"
                      onChange={({ target: { value } }) => {
                        const _distributorIds = sortList(
                          (values?.distributorIds || []) as string[] | number[],
                          ((value as string[] | number[])[
                            (value as string[] | number[])?.length - 1
                          ] === 'all'
                            ? values?.distributorIds?.length ===
                              distributorList.map((item) => item.id).length
                              ? []
                              : distributorList.map((item) => item.id)
                            : value) as number[] | string[]
                        );

                        const _DistributorPerformenceFee: DistributorForStrategy[] =
                          values?.distributors?.map((item) => {
                            return {
                              ...item,
                              inActive: (_distributorIds || [])
                                .map((_distributorId) => _distributorId.toString())
                                .includes(item.id.toString()),
                            };
                          });
                        setValues({
                          ...values,
                          distributorIds: _distributorIds,
                          distributors: sortStrategyPerformenceFee(
                            _DistributorPerformenceFee,
                            (_distributorIds || []).map((_distributorId) =>
                              _distributorId.toString()
                            )
                          ) as DistributorForStrategy[],
                        });
                      }}
                    />
                  </Grid>
                  {values.distributorIds && (
                    <Grid item xs={12} sm={12} md={12}>
                      <DataTable
                        tableData={values.distributors?.filter((obj: any) => obj.inActive)}
                        tableHeader={DistributorPerformenceFeeColumns({ values, setValues })}
                        noOfPages={1}
                        renderAdditionalRow={true}
                        tableBodyCustomStyles={{
                          '.MuiTableCell-root': {
                            borderBottom: 'none',
                          },
                        }}
                        tableHeaderCustomStyles={{
                          '.MuiTableCell-head': {
                            fontFamily: 'Poppins, sans-serif',
                            fontSize: 14,
                            fontWeight: 500,
                            color: 'primary.main',
                            textAlign: 'left',
                            pl: 2,
                          },
                        }}
                        rowCustomStyles={{
                          '.MuiTableCell-root': {
                            py: 0,
                            overflowWrap: 'anywhere',
                            textAlign: 'left',
                            verticalAlign: 'top',
                          },
                        }}
                        boxStyles={{ minHeight: '0px', minWidth: 450, maxHeight: 'unset' }}
                      />
                    </Grid>
                  )}
                  {/* 
                  <Grid item xs={12} sm={12} md={12}>
                    <DataTable
                      tableData={values.investmentamounts?.filter((obj: any) => obj.isActive)}
                      tableHeader={[...columns, ...AccessViewOrEditCell(values.investmentamounts)]}
                      noOfPages={1}
                      renderAdditionalRow={true}
                      tableBodyCustomStyles={{
                        '.MuiTableCell-root': {
                          borderBottom: 'none',
                        },
                      }}
                      tableHeaderCustomStyles={{
                        '.MuiTableCell-head': {
                          fontFamily: 'Poppins, sans-serif',
                          fontSize: 14,
                          fontWeight: 500,
                          color: 'primary.main',
                          textAlign: 'left',
                          pl: 2,
                        },
                      }}
                      rowCustomStyles={{
                        '.MuiTableCell-root': {
                          py: 0,
                          overflowWrap: 'anywhere',
                          textAlign: 'left',
                          verticalAlign: 'top',
                        },
                      }}
                      boxStyles={{ minHeight: '0px', minWidth: 450, maxHeight: 'unset' }}
                    />
                  </Grid>
                  {values.investmentamounts?.filter((obj: any) => obj.isActive).length < 5 && (
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        sx={{
                          color: 'primary.main',
                          fontWeight: 500,
                          fontSize: 14,
                          mt: 2,
                        }}
                        onClick={() =>
                          setValues({
                            ...values,
                            investmentamounts: [
                              ...(values.investmentamounts || []),
                              { ...newInvestmentAmounts, sno: values.investmentamounts?.length },
                            ],
                          })
                        }>
                        Add Invested Amount
                      </Button>
                    </Grid>
                  )}
                  */}
                  <ConfirmationDialog
                    message={'Are you sure you want to delete Invested Amount ?'}
                    open={investmentAmountsToDelete !== null}
                    setOpen={() => {
                      setInvestmentAmountsToDelete(null);
                    }}
                    onSave={() => {
                      const remaningInvestmentAmount = values.investmentamounts.map((obj) => {
                        if (obj.sno === investmentAmountsToDelete?.index) {
                          return {
                            ...obj,
                            isActive: false,
                          };
                        }
                        return obj;
                      });
                      setValues({
                        ...values,
                        investmentamounts: remaningInvestmentAmount,
                      });
                      setInvestmentAmountsToDelete(null);
                    }}
                    onCancel={() => {
                      setInvestmentAmountsToDelete(null);
                    }}
                  />
                </Grid>
                {access && (
                  <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                    <MFSubmitButton label="Save" />
                  </Box>
                )}
              </>
            )}
            <ConfirmationDialog
              message={`Are you sure you want to delete the operational charge details ?`}
              open={operationalChargeToDelete !== null}
              setOpen={() => setOperationalChargeToDelete(null)}
              onSave={() => {
                setValues({
                  ...values,
                  operationalCharges: values.operationalCharges?.map((operationalCharge) => {
                    return operationalCharge.sno === operationalChargeToDelete
                      ? { ...operationalCharge, isActive: false }
                      : operationalCharge;
                  }),
                });
                setOperationalChargeToDelete(null);
              }}
              onCancel={() => setOperationalChargeToDelete(null)}
            />
          </Box>
        )}
      </Formik>
    </>
  );
}
