import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { ApplicationProps, ExcludedCompanyType } from '../../redux-store/types/api-types';
import { Gridstyles, Item, ItemDetails } from './index';
import { SubHeading } from '../investors/components';
import { DataTable } from '../DataTable';
import { YesNoMaster } from '../../utils/constant';
import { sectionFilledByInvestor } from '../../utils/declaration';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';

export default function DisclosureOfExclusions({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { excludedcompanies = [], isDisclosureOfExclusionsToBeFilledByInvestor } =
    application || {};
  const excludedCompanies = excludedcompanies?.map((companies, index) => ({
    ...companies,
    sno: index + 1,
  }));

  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);

  const excludedHeaders = [
    {
      header: 'No',
      label: 'no',
      valueGetter: (row: any) => row.sno,
    },
    {
      header: 'Type',
      label: 'companySectorType',
      valueGetter: (row: ExcludedCompanyType) => row.companySectorType || 'N/A',
    },
    {
      header: 'Name of Company/ Industry/  Sector',
      label: 'companySectorName',
      valueGetter: (row: ExcludedCompanyType) => row.companySectorName || 'N/A',
    },
  ];
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main', mb: 2 }}>
              Disclosure of Exclusions
            </Typography>
            {isDisclosureOfExclusionsToBeFilledByInvestor && !referenceDetail.applicant_type && (
              <Box sx={{ width: '100%', pb: 3 }}>
                <Gridstyles>
                  <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                    {/* <Checkbox defaultChecked disabled={true} /> */}
                    <Typography
                      sx={{
                        fontSize: 15,
                        fontWeight: 700,
                        color: 'rgba(0, 0, 0, 0.6)',
                        letterSpacing: '0.5px',
                      }}>
                      {sectionFilledByInvestor}
                    </Typography>
                  </Grid>
                </Gridstyles>
              </Box>
            )}
            {(application.excludedCompaniesCheck ||
              !isDisclosureOfExclusionsToBeFilledByInvestor ||
              (isDisclosureOfExclusionsToBeFilledByInvestor && referenceDetail.applicant_type)) && (
              <React.Fragment>
                <Box sx={{ width: '100%' }}>
                  <SubHeading
                    sx={{
                      color: 'common.black',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                    }}>
                    Excluded Companies
                  </SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      sx={{ pl: { xs: 0, sm: 3 } }}>
                      <Grid item xs={12}>
                        <Item>
                          Do you want to exclude any specific companies/ sectors / industries from
                          your investment portfolio?
                        </Item>
                        <ItemDetails sx={{ textTransform: 'capitalize' }}>
                          {application.excludedCompaniesCheck || '-'}
                        </ItemDetails>
                      </Grid>
                    </Grid>
                  </Gridstyles>
                  {YesNoMaster[application.excludedCompaniesCheck || ''] === YesNoMaster.yes ? (
                    excludedCompanies.length > 0 ? (
                      <>
                        <Item sx={{ mb: 1, textTransform: 'capitalize', pl: { xs: 0, sm: 3 } }}>
                          I do not wish to invest in the following bodies corporate/sectors:
                        </Item>
                        <Item sx={{ mb: 4, textTransform: 'capitalize', pl: { xs: 0, sm: 3 } }}>
                          I/We hereby agree to keep you informed of any such restriction in the
                          future
                        </Item>
                        <Box sx={{ px: { xs: 0, sm: 3 } }}>
                          <DataTable
                            tableData={excludedCompanies}
                            tableHeader={excludedHeaders}
                            renderAdditionalRow={false}
                            tableHeaderCustomStyles={{
                              '.MuiTableCell-head': {
                                fontFamily: 'Poppins, sans-serif',
                                fontSize: 14,
                                fontWeight: 500,
                                color: 'primary.main',
                                textAlign: 'left',
                                px: 2,
                                py: 1.5,
                                bgcolor: '#F3F8FB',
                              },
                            }}
                            rowCustomStyles={{
                              '.MuiTableCell-root': {
                                fontFamily: 'Poppins, sans-serif',
                                fontSize: 14,
                                fontWeight: 500,
                                color: 'rgba(19, 24, 54, 0.7)',
                                px: 2,
                                py: 1.5,
                                overflowWrap: 'anywhere',
                                textAlign: 'left',
                                verticalAlign: 'top',
                                minWidth: 100,
                              },
                              '&:nth-child(odd)': {
                                bgcolor: 'rgba(19, 24, 54, 0.02)',
                              },
                            }}
                            boxStyles={{
                              minHeight: '0px',
                              minWidth: 450,
                              maxHeight: 'unset',
                              mb: 5,
                            }}
                          />
                        </Box>
                      </>
                    ) : (
                      <Grid
                        xs={12}
                        sm={12}
                        sx={{
                          fontSize: 18,
                          fontWeight: 600,
                          color: 'common.black',
                          textAlign: 'center',
                          my: 3,
                        }}>
                        No Excluded Companies
                      </Grid>
                    )
                  ) : (
                    <></>
                  )}
                </Box>
              </React.Fragment>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
