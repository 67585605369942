import { Box, Grid, Typography } from '@mui/material';
import { KeyValue } from './contributor';
import React, { Fragment, useState } from 'react';
import { Gridstyles } from './index';
import { ApplicationProps, NomineeType } from '../../redux-store/types/api-types';
import { formatShortDate } from '../../utils/date';
import { InfoDialog, SubHeading } from '../investors/components';
import {
  DisplayAddress,
  checkAddressField,
  isCustodianICICIOrHDFC,
  isMinor,
} from '../../utils/utilityFunctions';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { updateNominee } from '../investors/nomineeDetails';

export default function NomineeDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { nominees = [] } = application || {};
  const selectedCustodian = application?.custodian || '';

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Nominee Details
            </Typography>
            {nominees.length === 0 ? (
              <Grid
                xs={12}
                sm={12}
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: 'common.black',
                  textAlign: 'center',
                  my: 3,
                }}>
                No Nominees
              </Grid>
            ) : (
              <>
                {nominees.map((nominee, index: number) => {
                  const title = [
                    'First Nominee Details',
                    'Second Nominee Details',
                    'Third Nominee Details',
                  ][index];
                  const {
                    nomineeAddress1,
                    nomineeAddress2,
                    nomineeAddress3,
                    nomineeCity,
                    nomineeState,
                    nomineeCountry,
                    nomineePincode,
                  } = nominee;
                  const addressUnitPresent =
                    nomineeAddress1 ||
                    nomineeAddress2 ||
                    nomineeAddress3 ||
                    nomineeCity ||
                    nomineeState ||
                    nomineeCountry ||
                    nomineePincode;
                  return (
                    <React.Fragment key={nominee.id}>
                      <SubHeading sx={{ color: 'common.black', letterSpacing: 0.5 + 'px' }}>
                        {title}
                      </SubHeading>
                      <Gridstyles>
                        <Grid
                          item
                          container
                          pt={2}
                          pb={5}
                          rowSpacing={6}
                          columnSpacing={13}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start">
                          <KeyValue
                            title={`Nominee Name ${index + 1}`}
                            description={nominee.nomineeName}
                          />
                          <KeyValue
                            title={'Nominee RelationShip'}
                            description={nominee.nomineeRelationship}
                          />
                          <KeyValue
                            title={'Date Of Birth'}
                            description={formatShortDate(nominee.dateOfBirth)}
                          />
                          {isCustodianICICIOrHDFC(selectedCustodian) && (
                            <Fragment>
                              <KeyValue
                                title={`Nominee Email`}
                                description={nominee.nomineeEmail}
                              />
                              <KeyValue
                                title={`Nominee Phone Number`}
                                description={
                                  nominee.nomineeCountryCode && nominee.nomineePhone
                                    ? nominee.nomineeCountryCode + '-' + nominee.nomineePhone
                                    : nominee.nomineePhone
                                }
                              />
                            </Fragment>
                          )}
                          <KeyValue
                            title={'Nominee Percentage'}
                            description={nominee.nomineePercentage}
                          />
                          <KeyValue
                            title={`Nominee ${index + 1} Address`}
                            description={DisplayAddress(
                              nominee.nomineeAddress1,
                              nominee.nomineeAddress2,
                              nominee.nomineeAddress3,
                              nominee.nomineeCity,
                              nominee.nomineeState,
                              nominee.nomineeCountry,
                              nominee.nomineePincode
                            )}
                            sx={{
                              textTransform: 'capitalize',
                            }}
                          />
                          <KeyValue title={'Nominee PAN'} description={nominee.nomineePan} />

                          {nominee.dateOfBirth && isMinor(nominee.dateOfBirth) && (
                            <>
                              <KeyValue
                                title={'Guardian Name'}
                                description={nominee.guardianName}
                              />
                              {isCustodianICICIOrHDFC(selectedCustodian) && (
                                <Fragment>
                                  <KeyValue
                                    title={`Guardian Email`}
                                    description={nominee.guardianEmail}
                                  />
                                  <KeyValue
                                    title={`Guardian Phone Number`}
                                    description={
                                      nominee.guardianCountryCode && nominee.guardianPhone
                                        ? nominee.guardianCountryCode + '-' + nominee.guardianPhone
                                        : nominee.guardianPhone
                                    }
                                  />
                                </Fragment>
                              )}
                              <KeyValue
                                title={'Guardian Pan'}
                                description={nominee.nomineeGuardianPan}
                              />
                              <KeyValue
                                title={'Guardian Address'}
                                description={DisplayAddress(
                                  nominee.guardianAddress1 || '',
                                  nominee.guardianAddress2 || '',
                                  nominee.guardianAddress3 || '',
                                  nominee.guardianCity || '',
                                  nominee.guardianState || '',
                                  nominee.guardianCountry || '',
                                  nominee.guardianPincode || ''
                                )}
                                sx={{
                                  textTransform: 'capitalize',
                                }}
                              />
                              <KeyValue
                                title={'Guardian RelationShip'}
                                description={nominee.guardianRelationship}
                              />
                            </>
                          )}
                        </Grid>
                      </Gridstyles>
                    </React.Fragment>
                  );
                })}
                <SubHeading sx={{ color: 'common.black', letterSpacing: 0.5 + 'px' }}>
                  Residual Securities
                  <Typography component="span" sx={{ position: 'relative', bottom: -5 }}>
                    <InfoOutlinedIcon color="info" fontSize="small" onClick={handleOpen} />
                  </Typography>
                </SubHeading>
                <InfoDialog
                  onClose={handleClose}
                  open={open}
                  content={
                    'Please choose any one nominee who will be credited with residual securities remaining after distribution of securities as per percentage of allocation'
                  }
                />
                <Box sx={{ width: '100%' }}>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pt={2}
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue
                        title={'Residual Securities'}
                        description={
                          'Nominee' +
                          (Number(
                            (nominees as updateNominee[])
                              .map((nominee, i) => ({ ...nominee, sno: i }))
                              .find((nominee) => nominee.nomineeResidualSecurity)?.sno
                          ) +
                            1)
                        }
                      />
                    </Grid>
                  </Gridstyles>
                </Box>
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
