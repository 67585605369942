import { Box, Grid, Link, Typography } from '@mui/material';
import { KeyValue } from './contributor';
import { SubHeading } from '../investors/components';
import React, { useState } from 'react';
import { Gridstyles, Item, ItemDetails } from './index';
import { Applicant } from '../../redux-store/types/api-types';
import { YesNoMaster } from '../../utils/constant';

export default function FatcaDetails({
  applicants = [],
}: {
  applicants: Partial<Applicant>[];
}): JSX.Element {
  const [readmoreDisclaimer, setReadmoreDisclaimer] = useState(false);
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              FATCA
            </Typography>
            {applicants.map((applicant, index: number) => {
              const title = [
                'First/Solo Applicant Details',
                'Second Applicant Details',
                'Third Applicant Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>

                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue
                        title={'Type Of Address Provided At KRA'}
                        description={applicant.typeOfAddressProvidedAtKRA}
                      />
                      <KeyValue
                        title={'Tax Resident Of Any Country Other Than India'}
                        description={
                          applicant.taxResidentOfAnyCountryOtherThanIndia === null
                            ? null
                            : applicant.taxResidentOfAnyCountryOtherThanIndia === true
                            ? 'Yes'
                            : 'No'
                        }
                      />
                      <KeyValue title={'Place Of Birth'} description={applicant.placeOfBirth} />
                      <KeyValue title={'Country Of Birth'} description={applicant.countryOfBirth} />
                      <KeyValue
                        title={'Country Of Nationality'}
                        description={applicant.countryOfNationality}
                      />
                      {applicant.taxResidentOfAnyCountryOtherThanIndia === true && (
                        <>
                          <KeyValue
                            title={'Tax Country Name'}
                            description={applicant.taxCountryName}
                          />
                          <KeyValue title={'Tax ID'} description={applicant.taxID} />
                          <KeyValue title={'Id Type'} description={applicant.idType} />
                        </>
                      )}
                      <KeyValue
                        title={
                          'Do you have a POA/mandate holder who had an address outside of India?'
                        }
                        description={
                          applicant.poaMandateHolderOutsideIndia === null
                            ? null
                            : YesNoMaster[applicant.poaMandateHolderOutsideIndia || '']
                        }
                      />
                      {/* <KeyValue
                        title={'Is your Address/Telephone Number outside of India?'}
                        description={
                          applicant.addressTelephoneOutsideIndia === null
                            ? null
                            : YesNoMaster[applicant.addressTelephoneOutsideIndia || '']
                        }
                      /> */}
                      {/* {applicant.fatcaDeclaration && (
                        <Grid item xs={12}>
                          <Item sx={{ fontWeight: 600 }}>Declaration</Item>
                          <ItemDetails>
                            <Typography>
                              <Typography>
                                I declare / I am authorised to declare that the above information
                                and information in the submitted documents, to be true, correct, and
                                updated, and the submitted documents are genuine and duly executed.
                                {!readmoreDisclaimer && (
                                  <>
                                    ..
                                    <Link
                                      sx={{
                                        ml: 2,
                                        textDecoration: 'none',
                                        fontWeight: 600,
                                        cursor: 'pointer',
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setReadmoreDisclaimer(true);
                                      }}>
                                      Read More
                                    </Link>
                                  </>
                                )}
                              </Typography>
                              {readmoreDisclaimer && (
                                <>
                                  <Typography sx={{ mt: 3 }}>
                                    I acknowledge / I am authorised to acknowledge that towards
                                    compliance with tax information-sharing laws, such as FATCA /
                                    CRS, the SEBI authorised intermediary may be required to seek
                                    additional personal, tax, and beneficial owner information and
                                    certain certifications and documentation from the Account
                                    Applicant. Such information may be sought either at the time of
                                    account opening or any time subsequently. In certain
                                    circumstances (including if the intermediary does not receive a
                                    valid self-certification from the applicant) the Intermediary
                                    may be obliged to share information on Applicant’s Account with
                                    relevant tax authorities. Should there be any change in any
                                    information provided, ensure that the Applicant will intimate
                                    the Institution promptly, i.e., within 30 days.
                                  </Typography>
                                  <Typography sx={{ mt: 3 }}>
                                    Towards compliance with such laws, the Intermediary may also be
                                    required to provide information to any institutions such as
                                    withholding agents for the purpose of ensuring appropriate
                                    withholding from the account or any proceeds in relation
                                    thereto. As may be required by domestic or overseas regulators/
                                    tax authorities, the Intermediary may also be constrained to
                                    withhold payout of any sums from the Applicant’s Account or
                                    close or suspend the Applicant’s Account.
                                  </Typography>
                                  <Typography sx={{ mt: 3 }}>
                                    I also understand that the account will be reported if any one
                                    of the aforesaid FATCA / CRS criteria for any of the Account
                                    Applicants, i.e., Primary or Joint, is met.
                                    {readmoreDisclaimer && (
                                      <Link
                                        sx={{
                                          ml: 2,
                                          textDecoration: 'none',
                                          fontWeight: 600,
                                          cursor: 'pointer',
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setReadmoreDisclaimer(false);
                                        }}>
                                        Read Less
                                      </Link>
                                    )}
                                  </Typography>
                                </>
                              )}
                            </Typography>
                          </ItemDetails>
                        </Grid>
                      )} */}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
