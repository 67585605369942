import { Box, Button, CardMedia, Grid, IconButton, Link, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { Applicant, Groups } from '../../redux-store/types/api-types';
import { riskProfileDeclaration } from '../../utils/declaration';
import { SubHeading } from '../investors/components';
import { Gridstyles, Item, ItemDetails } from '../ApplicationDetails';

export default function AuthorizedSignatoriesDocuments({
  groups = [],
}: {
  groups: Groups[];
}): JSX.Element {
  const authorizedSignatoriesReferenceDetail = useSelector(
    (store: RootStateType) => store.authorizedSignatoriesRefrenceIdReducer
  );
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main', mb: 2 }}>
              Authorized signatory Declaration(s)
            </Typography>
            {groups.map((_group) => {
              const signatories = authorizedSignatoriesReferenceDetail.referenceId
                ? [
                    _group?.groupsignatories.find(
                      (_signatory) =>
                        Number(_signatory.id) === Number(authorizedSignatoriesReferenceDetail.id)
                    ),
                  ]
                : _group?.groupsignatories;
              return (
                <React.Fragment key={_group.id}>
                  {/* {authorizedSignatoriesReferenceDetail.referenceId ? (
                    <></>
                  ) : (
                    <Grid container pt={3}>
                      <Grid item xs={12}>
                        <Typography>{_group.name}</Typography>
                      </Grid>
                    </Grid>
                  )} */}
                  {signatories &&
                    signatories.length > 0 &&
                    signatories.map((signatory, index) => {
                      const signatoriesDoc = authorizedSignatoriesReferenceDetail.referenceId
                        ? _group?.groupsignatories.find(
                            (_signatory) =>
                              Number(_signatory.id) ===
                              Number(authorizedSignatoriesReferenceDetail.id)
                          )?.groupsignatorydocuments
                        : signatory?.groupsignatorydocuments;
                      return (
                        <>
                          {signatoriesDoc && signatoriesDoc.length > 0 && (
                            <React.Fragment key={signatory?.id}>
                              {authorizedSignatoriesReferenceDetail.referenceId ? (
                                <></>
                              ) : (
                                <SubHeading
                                  sx={{
                                    letterSpacing: 0.5 + 'px',
                                    padding: { xs: '10px 10px', sm: '10px 30px' },
                                  }}>
                                  {authorizedSignatoriesReferenceDetail.referenceId
                                    ? 'Authorized signatory Declaration'
                                    : `Typed Declaration For Authorized Signatory ${index + 1}`}
                                </SubHeading>
                              )}

                              <Box sx={{ width: '100%', mt: 4 }}>
                                <Gridstyles>
                                  <Grid
                                    item
                                    container
                                    pb={5}
                                    rowSpacing={6}
                                    columnSpacing={13}
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-start">
                                    <Grid
                                      item
                                      container
                                      pt={2}
                                      // pb={2}
                                      columnSpacing={13}
                                      sx={{ pl: { xs: 0, sm: 7, md: 10, lg: 13 } }}>
                                      {signatoriesDoc &&
                                        signatoriesDoc.map((document, index: number) => {
                                          return (
                                            <React.Fragment key={document.id}>
                                              <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={document.file ? 6 : 12}
                                                sx={{ mt: index !== 0 ? 6 : 0 }}>
                                                <Item>{document.documentName}</Item>
                                                <ItemDetails>{document.file?.name}</ItemDetails>
                                              </Grid>
                                              {document.file && (
                                                <Grid item xs={12} sm={6}>
                                                  <Box
                                                    sx={{
                                                      display: 'flex',
                                                      mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                                      flexDirection: {
                                                        sm: 'column',
                                                        xs: 'row',
                                                        md: 'row',
                                                      },
                                                    }}>
                                                    <Button
                                                      variant="contained"
                                                      sx={{
                                                        color: 'common.white',
                                                        // minWidth: { xs: '90%', sm: '100%', md: '70%' },
                                                        minWidth: '70%',
                                                        // mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                                        fontWeight: 600,
                                                        lineHeight: 1.5,
                                                      }}
                                                      onClick={() =>
                                                        window.open(document.file?.viewUrl)
                                                      }>
                                                      View
                                                    </Button>
                                                    <Tooltip title={'Download'}>
                                                      <IconButton
                                                        sx={{
                                                          // p: 0,
                                                          // pl: 1.5,
                                                          ':hover': {
                                                            bgcolor: '#F4FCFC',
                                                          },
                                                          cursor: 'pointer',
                                                          pointerEvents: 'all',
                                                          // mt: index !== 0 ? { sm: 8, xs: 2 } : 2,
                                                        }}
                                                        onClick={() =>
                                                          window.open(document.file?.url)
                                                        }>
                                                        <CardMedia
                                                          component="img"
                                                          src={'/images/download-icon.png'}
                                                          alt="Download"
                                                          sx={{ width: '25px' }}
                                                        />
                                                      </IconButton>
                                                    </Tooltip>
                                                  </Box>
                                                </Grid>
                                              )}
                                            </React.Fragment>
                                          );
                                        })}
                                    </Grid>
                                  </Grid>
                                </Gridstyles>
                              </Box>
                            </React.Fragment>
                          )}
                        </>
                      );
                    })}
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
