import { Grid, Link, Typography } from '@mui/material';
import { NotesWithText, ProceedSaveLater, SubHeading } from './components';
import { Formik, useFormikContext, validateYupSchema, yupToFormErrors } from 'formik';
import MFSelectField from '../../lib/formik/SelectField';
import UseRadioGroup from '../../lib/formik/Radio';
import { MFTextField } from '../../lib/formik';
import { Applicant } from '../../redux-store/types/api-types';
import {
  addressTypesMasters,
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  applicantStatusMasters,
  USER_ROLES,
  YesNoMaster,
} from '../../utils/constant';
import {
  applicationComparison,
  applyRoleBasedStatus,
  checkEditiable,
  checkNDPMSForIndusindBank,
  checkPoaMandateHolder,
  getApplicantName,
  isApplicantNRI,
  removeSingleQuote,
  saveForLater,
  saveForLaterInvestorPhotoCapture,
} from '../../utils/utilityFunctions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useHistory, useParams } from 'react-router';
import { updateApplication } from '../../redux-store/actions/application';
import { FATCAValidationSchema } from '../../utils/schema';
import { Notes } from './components';
import { useSnackbar } from 'notistack';
import { mdmsCountriesList, nationaliyType } from '../../redux-store/types/mdms';
import { getNationalityList } from '../../redux-store/actions';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import MFCheckbox from '../../lib/formik/Checkbox';
import { ConfirmationDialog } from '../commonComponents';
import { showError } from '../../redux-store/actions/auth';
import { OnEditDialogBox } from './additionalKYCDetails';

export type Values = {
  applicants: Partial<Applicant>[];
  saveType: string;
  countryDropdown: string[];
};

const initialValues: Values = {
  applicants: [
    {
      typeOfAddressProvidedAtKRA: '',
      taxResidentOfAnyCountryOtherThanIndia: false,
      placeOfBirth: '',
      countryOfBirth: 'INDIA',
      countryOfNationality: 'INDIA',
      taxCountryName: '',
      taxID: '',
      idType: '',
      nameOfEntity: '',
      dateOfIncorporation: '',
      cityOfIncorporation: '',
      countryOfIncorporation: '',
      entityExcemptionCode: '',
      poaMandateHolderOutsideIndia: 'no',
      addressTelephoneOutsideIndia: 'no',
      fatcaDeclaration: false,
    },
  ],
  saveType: 'save and proceed',
  countryDropdown: [],
};

const FatcaDetails = ({
  applicantName,
  index,
  values,
  nationalitiesMdmsResponse,
}: {
  applicantName: string;
  index: number;
  values: Partial<Applicant>;
  nationalitiesMdmsResponse: mdmsCountriesList[];
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const isFieldDisabled = [USER_ROLES.POAAPPROVER].includes(role);
  const { setFieldValue } = useFormikContext();
  const [poaOpen, setPoaOpen] = useState(false);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);

  return (
    <>
      <SubHeading>FATCA Details of {applicantName} Applicant</SubHeading>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.typeOfAddressProvidedAtKRA`}
          label="Type of Address Provided At KRA *"
          items={addressTypesMasters.map((address) => ({ key: address, value: address }))}
          disabled={checkEditiable(referenceDetail.applicant_type, role, index + 1)}
        />
      </Grid>
      <Grid item xs={12}>
        <UseRadioGroup
          formLabel="Tax Resident of any country other than India *"
          name={`applicants.${index}.taxResidentOfAnyCountryOtherThanIndia`}
          items={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          value={values.taxResidentOfAnyCountryOtherThanIndia?.toString()}
          disabled={
            isFieldDisabled || checkEditiable(referenceDetail.applicant_type, role, index + 1)
          }
        />
      </Grid>
      {/* {applicant?.taxResidentOfAnyCountryOtherThanIndia?.toString() === 'true' && ( */}
      <>
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.placeOfBirth`}
            label="Place of Birth *"
            placeholder="Enter Place of Birth"
            disabled={checkEditiable(referenceDetail.applicant_type, role, index + 1)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <MFTextField
            name={`applicants.${index}.countryOfBirth`}
            label="Country of Birth *"
            placeholder="Enter Country of Birth"
            disabled={isFieldDisabled}
          /> */}
          <SearchableSelect
            name={`applicants.${index}.countryOfBirth`}
            label="Country of Birth *"
            items={nationalitiesMdmsResponse.map((nationality) => ({
              key: nationality.name,
              value: nationality.name,
            }))}
            disabled={
              isFieldDisabled || checkEditiable(referenceDetail.applicant_type, role, index + 1)
            }
            searchFieldPlaceholder={'Search Country'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <MFTextField
            name={`applicants.${index}.countryOfNationality`}
            label="Country of Nationality *"
            placeholder="Enter Country of Nationality"
            disabled={isFieldDisabled}
          /> */}
          <SearchableSelect
            name={`applicants.${index}.countryOfNationality`}
            label="Country of Nationality *"
            items={nationalitiesMdmsResponse.map((nationality) => ({
              key: nationality.name,
              value: nationality.name,
            }))}
            disabled={
              isFieldDisabled || checkEditiable(referenceDetail.applicant_type, role, index + 1)
            }
            searchFieldPlaceholder={'Search Country'}
          />
        </Grid>
        {values?.taxResidentOfAnyCountryOtherThanIndia?.toString() === 'true' && (
          <>
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.taxCountryName`}
                label="Tax country Name *"
                placeholder="Enter Tax country Name"
                disabled={
                  isFieldDisabled || checkEditiable(referenceDetail.applicant_type, role, index + 1)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.taxID`}
                label="Tax ID *"
                placeholder="Enter Tax ID"
                disabled={
                  isFieldDisabled || checkEditiable(referenceDetail.applicant_type, role, index + 1)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.idType`}
                label="ID Type *"
                placeholder="Enter ID Type"
                disabled={
                  isFieldDisabled || checkEditiable(referenceDetail.applicant_type, role, index + 1)
                }
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sx={{ pt: '25px !important' }}>
          <UseRadioGroup
            formLabel="Do you have a POA/mandate holder who had an address outside of India?"
            name={`applicants.${index}.poaMandateHolderOutsideIndia`}
            items={Object.keys(YesNoMaster).map((fundType) => ({
              label: YesNoMaster[fundType],
              value: fundType,
            }))}
            // value={values.taxResidentOfAnyCountryOtherThanIndia?.toString()}
            disabled={checkEditiable(referenceDetail.applicant_type, role, index + 1)}
            onChange={({ target: { value } }: any) => {
              if (YesNoMaster[value] !== YesNoMaster.yes) {
                setFieldValue(`applicants.${index}.poaMandateHolderOutsideIndia`, value);
              } else {
                setPoaOpen(value);
              }
            }}
          />
          <NotesWithText
            noteTitle="Disclaimer"
            displayContent="POA/Mandate Holder outside of India cannot invest in PMS funds."
            background="rgba(229, 67, 92, 0.15)"
            textColor="#E5435C"
          />
        </Grid>
        {/* <Grid item xs={12} sx={{ pt: '25px !important' }}>
          <UseRadioGroup
            formLabel="Is your Address/Telephone Number outside of India?"
            name={`applicants.${index}.addressTelephoneOutsideIndia`}
            items={Object.keys(YesNoMaster).map((fundType) => ({
              label: YesNoMaster[fundType],
              value: fundType,
            }))}
            // value={values.taxResidentOfAnyCountryOtherThanIndia?.toString()}
            // disabled={isFieldDisabled}
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.nameOfEntity`}
              label="Name of Entity"
              placeholder="Enter Name of Entity"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Date of Incorporation"
              inputLabelStyles={{
                transform: 'unset',
                fontSize: 14,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)',
              }}
              maxDate={minDateForContributor()}
              name={`applicants.${index}.dateOfIncorporation`}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              value={applicant.dateOfBirth || minDateForContributor().toString()}
              onChange={(newValue) => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant, ind) => {
                    if (index === ind) {
                      return {
                        ...applicant,
                        dateOfIncorporation: newValue?.toString(),
                      };
                    }
                    return applicant;
                  }),
                });
              }}
              textFieldStyles={{
                'label + &': {
                  mt: 4,
                },
                '& .MuiInputBase-root': {
                  border: '1px solid #DDEAF3',
                },
                '& .MuiOutlinedInput-root': {
                  position: 'relative',
                  backgroundColor: 'common.white',
                  border: '1px solid #DDEAF3',
                  fontSize: 16,
                  '&:hover': {
                    borderColor: 'primary.main',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 0,
                  },
                  '.MuiInputBase-input': {
                    p: '10px 12px',
                  },
                  '&:focus-visible': {
                    outline: 'none',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.cityOfIncorporation`}
              label="City of Incorporation"
              placeholder="Enter City of Incorporation"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.countryOfIncorporation`}
              label="Country of Incorporation"
              placeholder="Enter Country of Incorporation"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.entityExcemptionCode`}
              label="Entity Exemption Code"
              placeholder="Enter Entity Exemption Code"
            />
          </Grid> */}
      </>
      {/* )} */}
      <ConfirmationDialog
        message="You are not allowed to proceed further!"
        open={poaOpen}
        setOpen={() => setPoaOpen(false)}
        onSave={() => {
          setPoaOpen(false);
          setFieldValue(`applicants.${index}.poaMandateHolderOutsideIndia`, 'yes');
        }}
        onCancel={() => {
          setPoaOpen(false);
        }}
      />
    </>
  );
};

export default function Fatca(): JSX.Element {
  const [fatcaDetails, setFatcaDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const [showDialogForEdit, setShowShowForEdit] = useState(false);
  const [finalvalue, setFinalValue] = useState<any>();
  const checkNdpmsFlow = checkNDPMSForIndusindBank(
    application?.accountType,
    application?.bankDetails
  );

  useEffect(() => {
    (async function () {
      try {
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  useEffect(() => {
    const { applicants = [] } = application || {};
    (async function () {
      try {
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
        setFatcaDetails({
          ...fatcaDetails,
          applicants: applicants?.map((applicant) => ({
            typeOfAddressProvidedAtKRA: applicant.typeOfAddressProvidedAtKRA || '',
            taxResidentOfAnyCountryOtherThanIndia:
              applicant.taxResidentOfAnyCountryOtherThanIndia === false
                ? false
                : applicant.taxResidentOfAnyCountryOtherThanIndia === true
                ? true
                : isApplicantNRI(applicant.status || ''),
            placeOfBirth: applicant.placeOfBirth || '',
            countryOfBirth: applicant.countryOfBirth
              ? applicant.countryOfBirth.toUpperCase()
              : [USER_ROLES.POAAPPROVER].includes(role)
              ? ''
              : 'INDIA',
            countryOfNationality: applicant.countryOfNationality
              ? applicant.countryOfNationality.toUpperCase()
              : [USER_ROLES.POAAPPROVER].includes(role)
              ? ''
              : 'INDIA',
            taxCountryName: applicant.taxCountryName || '',
            taxID: applicant.taxID || '',
            idType: applicant.idType || '',
            nameOfEntity: applicant.nameOfEntity || '',
            dateOfIncorporation: applicant.dateOfIncorporation || '',
            cityOfIncorporation: applicant.cityOfIncorporation || '',
            countryOfIncorporation: applicant.countryOfIncorporation || '',
            entityExcemptionCode: applicant.entityExcemptionCode || '',
            poaMandateHolderOutsideIndia: applicant.poaMandateHolderOutsideIndia || 'no',
            addressTelephoneOutsideIndia: applicant.addressTelephoneOutsideIndia || 'no',
          })),
          countryDropdown: nationalitiesMdmsMasters.countries.map((list) => list.name),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();

  const handleSubmitandProceed = async (values: Values) => {
    try {
      const {
        applicants: existingApplicants = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const { applicants, saveType } = values;
      applicants.map((applicant, ind) => {
        if (
          !applicant.fatcaDeclaration &&
          role === USER_ROLES.INVESTOR &&
          Number(referenceDetail.applicant_type) === ind + 1
        ) {
          throw 'Declaration is Required';
        }
      });
      const updatedPayload = existingApplicants.map((applicant, index: number) => {
        const {
          taxResidentOfAnyCountryOtherThanIndia = false,
          // dateOfBirth = '',
          dateOfIncorporation = '',
          ...rest
        } = applicants[index];
        return {
          ...applicant,
          taxResidentOfAnyCountryOtherThanIndia:
            taxResidentOfAnyCountryOtherThanIndia.toString() === 'false' ? false : true,
          // dateOfBirth: dateOfBirth ? dateOfBirth : null,
          dateOfIncorporation: dateOfIncorporation ? dateOfIncorporation : null,
          ...rest,
          placeOfBirth: removeSingleQuote(rest.placeOfBirth),
          taxCountryName:
            taxResidentOfAnyCountryOtherThanIndia.toString() === 'false'
              ? ''
              : removeSingleQuote(rest.taxCountryName),
          taxID:
            taxResidentOfAnyCountryOtherThanIndia.toString() === 'false'
              ? ''
              : removeSingleQuote(rest.taxID),
          idType:
            taxResidentOfAnyCountryOtherThanIndia.toString() === 'false'
              ? ''
              : removeSingleQuote(rest.idType),
        };
      });
      const checkApplication = applicationComparison(application, {
        ...application,
        applicants: updatedPayload,
        currentStep:
          !!currentStep && currentStep > 4
            ? currentStep
            : !checkPoaMandateHolder(updatedPayload)
            ? 4
            : Number(currentStep) + 1,
      });
      const isSaveLater = saveType !== 'save and proceed';

      //save for later route for investor at liveliness
      const investorEditSaveelaterRoute =
        referenceIdForInvestorPhotoCapture &&
        saveForLaterInvestorPhotoCapture(referenceIdForInvestorPhotoCapture, application);
      const referenceIdForSaveProceedRoute = referenceIdForInvestorPhotoCapture
        ? referenceIdForInvestorPhotoCapture
        : referenceDetail.esignReferenceId;

      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatedPayload,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              ndpmsSectionEdited: showDialogForEdit ? true : application?.ndpmsSectionEdited,
              currentStep: !checkPoaMandateHolder(updatedPayload) ? 4 : 5,
              //!!currentStep && currentStep > 4 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('nominee-details', {
              id,
              applicant1ReferenceId: referenceIdForSaveProceedRoute,
            })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('nominee-details', {
              id,
              applicant1ReferenceId: referenceIdForSaveProceedRoute,
            })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  const manageSubmit = (values: Values) => {
    const {
      applicants: existingApplicants = [],
      bankAccountFormCreated = false,
      showBankIcon = false,
    } = application || {};
    const { applicants } = values;
    applicants.map((applicant, ind) => {
      if (
        !applicant.fatcaDeclaration &&
        role === USER_ROLES.INVESTOR &&
        Number(referenceDetail.applicant_type) === ind + 1
      ) {
        throw 'Declaration is Required';
      }
    });
    const updatedPayload = existingApplicants.map((applicant, index: number) => {
      const {
        taxResidentOfAnyCountryOtherThanIndia = false,
        // dateOfBirth = '',
        dateOfIncorporation = '',
        ...rest
      } = applicants[index];
      return {
        ...applicant,
        taxResidentOfAnyCountryOtherThanIndia:
          taxResidentOfAnyCountryOtherThanIndia.toString() === 'false' ? false : true,
        // dateOfBirth: dateOfBirth ? dateOfBirth : null,
        dateOfIncorporation: dateOfIncorporation ? dateOfIncorporation : null,
        ...rest,
        placeOfBirth: removeSingleQuote(rest.placeOfBirth),
        taxCountryName:
          taxResidentOfAnyCountryOtherThanIndia.toString() === 'false'
            ? ''
            : removeSingleQuote(rest.taxCountryName),
        taxID:
          taxResidentOfAnyCountryOtherThanIndia.toString() === 'false'
            ? ''
            : removeSingleQuote(rest.taxID),
        idType:
          taxResidentOfAnyCountryOtherThanIndia.toString() === 'false'
            ? ''
            : removeSingleQuote(rest.idType),
      };
    });
    const checkApplication = applicationComparison(application, {
      ...application,
      applicants: updatedPayload,
      currentStep: application?.currentStep,
    });
    if (
      !checkApplication &&
      checkNdpmsFlow &&
      bankAccountFormCreated &&
      showBankIcon &&
      role === USER_ROLES.RM
    ) {
      setFinalValue(values);
      setShowShowForEdit(true);
    } else {
      handleSubmitandProceed(values);
    }
  };

  const [readmoreDisclaimer, setReadmoreDisclaimer] = useState(false);
  return (
    <Formik
      initialValues={fatcaDetails}
      onSubmit={manageSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, FATCAValidationSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <Notes
            displayContent={'FATCA Information is mandatory to disclose any Foreign Citizenship'}
          />
          {values.applicants.map((applicant, index) => (
            <>
              <FatcaDetails
                applicantName={getApplicantName(index + 1)}
                index={index}
                key={index}
                values={applicant}
                nationalitiesMdmsResponse={mdmsCountriesList}
              />
              {/* <Grid item xs={12} sx={{ mt: 2 }}>
                <MFCheckbox
                  disabled={
                    role !== USER_ROLES.INVESTOR ||
                    checkEditiable(referenceDetail.applicant_type, role, index + 1)
                  }
                  name={`applicants.${index}.fatcaDeclaration`}
                  label={
                    <Typography>
                      <Typography>
                        I declare / I am authorised to declare that the above information and
                        information in the submitted documents, to be true, correct, and updated,
                        and the submitted documents are genuine and duly executed.
                        {!readmoreDisclaimer && (
                          <>
                            ..
                            <Link
                              sx={{ ml: 2, textDecoration: 'none', fontWeight: 600 }}
                              onClick={(e) => {
                                e.preventDefault();
                                setReadmoreDisclaimer(true);
                              }}>
                              Read More
                            </Link>
                          </>
                        )}
                      </Typography>
                      {readmoreDisclaimer && (
                        <>
                          <Typography sx={{ mt: 3 }}>
                            I acknowledge / I am authorised to acknowledge that towards compliance
                            with tax information-sharing laws, such as FATCA / CRS, the SEBI
                            authorised intermediary may be required to seek additional personal,
                            tax, and beneficial owner information and certain certifications and
                            documentation from the Account Applicant. Such information may be sought
                            either at the time of account opening or any time subsequently. In
                            certain circumstances (including if the intermediary does not receive a
                            valid self-certification from the applicant) the Intermediary may be
                            obliged to share information on Applicant’s Account with relevant tax
                            authorities. Should there be any change in any information provided,
                            ensure that the Applicant will intimate the Institution promptly, i.e.,
                            within 30 days.
                          </Typography>
                          <Typography sx={{ mt: 3 }}>
                            Towards compliance with such laws, the Intermediary may also be required
                            to provide information to any institutions such as withholding agents
                            for the purpose of ensuring appropriate withholding from the account or
                            any proceeds in relation thereto. As may be required by domestic or
                            overseas regulators/ tax authorities, the Intermediary may also be
                            constrained to withhold payout of any sums from the Applicant’s Account
                            or close or suspend the Applicant’s Account.
                          </Typography>
                          <Typography sx={{ mt: 3 }}>
                            I also understand that the account will be reported if any one of the
                            aforesaid FATCA / CRS criteria for any of the Account Applicants, i.e.,
                            Primary or Joint, is met.
                            {readmoreDisclaimer && (
                              <Link
                                sx={{ ml: 2, textDecoration: 'none', fontWeight: 600 }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setReadmoreDisclaimer(false);
                                }}>
                                Read Less
                              </Link>
                            )}
                          </Typography>
                        </>
                      )}
                    </Typography>
                  }
                  sx={{ display: 'flex', alignItems: 'flex-start' }}
                />
              </Grid> */}
            </>
          ))}
          <OnEditDialogBox
            open={showDialogForEdit}
            setOpen={setShowShowForEdit}
            handleSubmit={handleSubmitandProceed}
            values={finalvalue}
          />
          <ProceedSaveLater
            showSaveAndProceed={checkPoaMandateHolder(values.applicants)}
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
