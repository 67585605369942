import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import {
  Formik,
  FormikHelpers,
  useFormikContext,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { MFCheckbox } from '../../lib/formik/Checkbox';
import UseRadioGroup from '../../lib/formik/Radio';
import MFSelectField from '../../lib/formik/SelectField';
import MFTextField from '../../lib/formik/TextField';
import { updateApplication } from '../../redux-store/actions/application';
import { showError } from '../../redux-store/actions/auth';
import { getStrategies } from '../../redux-store/actions/strategies';
import { RootStateType } from '../../redux-store/reducers';
import { StrategyDetailsType, investmentAmountsType } from '../../redux-store/types/api-types';
import { GetStrategiesResponseBody } from '../../redux-store/types/strategies';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  CHECKER_EDIT_ALL_SECTIONS,
  defaultOperationalCharges,
  FeeTypeMaster,
  FundInvestStockMarket,
  ModeOfTransaction,
  STRATEGY_TYPE_OPTIONS,
  strategyFeeTypeMaster,
  strategyPerformanceFeeTypeMaster,
  USER_ROLES,
} from '../../utils/constant';
import { strategyDeclarationScheme } from '../../utils/schema';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  checkStrategyBoxSelected,
  convertToRoman,
  currencyConversion,
  feeTypeMasterFunc,
  findPerformanceKeyByValue,
  getDisableStatusForjointHolder,
  getTotalStrategyAmount,
  isSecurityTransfer,
  saveForLater,
  saveForLaterInvestorPhotoCapture,
} from '../../utils/utilityFunctions';
import { ColumnType, DataTable } from '../DataTable';
import { Notes, ProceedSaveLater, SubHeading } from './components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TextDatePicker } from '../../lib/formik/DatePickerWithTextFeild';
import { Item, ItemDetails } from '../ApplicationDetails';

export type updatedStrategyDetailsType = StrategyDetailsType & {
  feeTypeMaster?: { value: string; label: string }[];
};

type Values = {
  applicationstrategydetails: updatedStrategyDetailsType[];
  totalStrategyInvestmentAmount: string | number | null;
  ownBorrowedFunds: string | null;
  cheque: boolean;
  rtgs: boolean;
  securitiesTransfer: boolean;
  transferSecuritiesTotalMarketValue: boolean | null;
  securitiesMarketValueDate: Date | string | null;
  nameOfSecurities: string | null;
  saveType: string;
};

export const newStrategy: updatedStrategyDetailsType = {
  strategyName: '',
  applicationInvestmentAmountDetails: [],
  isActive: false,
  entryLoad: '',
  exitLoad: '',
  strategyType: '',
  benchmark: '',
  applicationStrategyManagementFee: 0,
  performanceFee: '',
  otherOperationalCharges: [],
  feeType: '',
  feeTypeMaster: [],
};

const initialValues: Values = {
  applicationstrategydetails: [],
  totalStrategyInvestmentAmount: null,
  ownBorrowedFunds: '',
  cheque: false,
  rtgs: false,
  securitiesTransfer: false,
  transferSecuritiesTotalMarketValue: false,
  securitiesMarketValueDate: new Date(),
  nameOfSecurities: '',
  saveType: 'save and proceed',
};

export const columns: ColumnType[] = [
  {
    header: 'Investment Amount',
    valueGetter: (row: Partial<investmentAmountsType>) => row.investmentAmount || '-',
  },
  // {
  //   header: 'Management Fee',
  //   valueGetter: (row: Partial<investmentAmountsType>) => row.managementFee || '',
  // },
  {
    header: 'Performance Fee',
    valueGetter: (row: Partial<investmentAmountsType>) => row.performanceFee || '-',
  },
];
export type updatedGetStrategiesResponseBody = GetStrategiesResponseBody & {
  feeTypeMaster: { value: string; label: string }[];
};

function StrategyAccordion({
  values,
  setValues,
  strategyMasterData,
  strategy,
  applicationStrategyIndex,
}: {
  values: Values;
  setValues: FormikHelpers<Values>['setValues'];
  strategyMasterData: updatedGetStrategiesResponseBody[];
  strategy: updatedStrategyDetailsType;
  applicationStrategyIndex: number;
}): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const isFieldDisabled = [USER_ROLES.INVESTOR].includes(role);

  return (
    <Accordion
      sx={{
        boxShadow: 'none',
        mb: 3,
        cursor: 'default',
        '&::before': { display: 'none' },
        '& .MuiFormControl-root': { m: 0, height: 44 },
        '& .MuiOutlinedInput-root': {
          mt: 0,
        },
        '& .label': { fontSize: 14, fontWeight: 500, color: 'rgba(0,0,0,0.7)', mb: 1 },
      }}
      expanded={strategy?.isActive}>
      <AccordionSummary
        sx={{
          bgcolor: 'rgba(221, 234, 243, 0.2)',
          borderRadius: '5px',
          cursor: 'default',
          '&.Mui-expanded': { minHeight: 'unset', cursor: 'default' },
          '& .MuiAccordionSummary-content': { m: 0, py: 0.7, '&.Mui-expanded': { m: 0 } },
        }}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Stack>
          <Typography
            sx={{
              color: '#131836',
              fontSize: 16,
              fontWeight: 500,
              width: '100%',
              cursor: 'default',
              display: 'flex',
              alignItems: 'center',
            }}>
            {strategyMasterData.length > 1 && (
              <Radio
                name={`applicationstrategydetails.${applicationStrategyIndex}.isActive`}
                onChange={({ target: { checked } }) => {
                  const updatedStrategy = values.applicationstrategydetails?.map(
                    (strategyDetails, _index) => {
                      if (applicationStrategyIndex === _index) {
                        return {
                          ...strategyDetails,
                          isActive: checked,
                          benchmark: checked
                            ? strategyDetails?.benchmark
                              ? strategyDetails?.benchmark
                              : strategyDetails?.adminBenchmark
                            : '',
                          strategyType: checked
                            ? Object.keys(STRATEGY_TYPE_OPTIONS).includes(
                                strategyDetails?.strategyType || ''
                              )
                              ? strategyDetails?.strategyType
                              : strategyDetails?.adminStrategyType
                            : '',
                          // investmentAmount: checked
                          //   ? strategyMasterData[applicationStrategyIndex].investmentAmount
                          //   : '',
                          // entryLoad: checked
                          //   ? strategyMasterData[applicationStrategyIndex].entryLoad
                          //   : '',
                          // exitLoad: checked
                          //   ? strategyMasterData[applicationStrategyIndex].exitLoad
                          //   : '',
                          // investmentamounts: checked
                          //   ? strategyMasterData[applicationStrategyIndex].investmentamounts
                          //   : [],
                          // performanceFee: findPerformanceKeyByValue(
                          //   strategyDetails.strategyId || ''
                          // ),
                        };
                      }
                      return {
                        ...strategyDetails,
                        isActive: !checked,
                      };
                    }
                  ) as updatedStrategyDetailsType[];
                  setValues({
                    ...values,
                    applicationstrategydetails: updatedStrategy,
                    totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedStrategy) || '',
                  });
                }}
                checked={strategy.isActive}
                disabled={isFieldDisabled || [USER_ROLES.AMCAPPROVER].includes(role)}
              />
            )}
            {strategy?.strategyName}{' '}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={{ xs: 4, sm: 8 }}>
          {application?.distributor_id && (
            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              <UseRadioGroup
                formLabel="Fee Type *"
                name={`applicationstrategydetails.${applicationStrategyIndex}.feeType`}
                items={
                  values.applicationstrategydetails[applicationStrategyIndex]?.feeTypeMaster || []
                }
                onChange={({ target: { value } }) => {
                  setValues({
                    ...values,
                    applicationstrategydetails: values.applicationstrategydetails.map(
                      (item, ind) => {
                        if (ind === applicationStrategyIndex) {
                          return {
                            ...item,
                            feeType: value,
                            performanceFee:
                              value !== 'hybrid'
                                ? null
                                : ((strategyMasterData[applicationStrategyIndex]
                                    .performanceFeeHybrid || '') as string),
                          };
                        }
                        return item;
                      }
                    ),
                  });
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Typography className="label">Entry Load</Typography>
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                display: 'inline-flex',
                width: '100%',
                '& .MuiFormControl-root': { m: 0 },
              }}>
              <Typography sx={{ fontSize: 13, mt: 1, textAlign: 'left' }}>
                {strategy?.entryLoad || '-'}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="label">Exit Load</Typography>
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                display: 'inline-flex',
                width: '100%',
                '& .MuiFormControl-root': { m: 0 },
              }}>
              <Typography sx={{ fontSize: 13, mt: 1, textAlign: 'left' }}>
                {strategy?.exitLoad || '-'}
              </Typography>
            </Stack>
          </Grid>{' '}
          <Grid item xs={12} sm={6}>
            <Typography className="label">Strategy type *</Typography>
            <Box
              sx={{
                width: '100%',
                '& .MuiFormControl-root': { m: 0 },
              }}>
              <MFSelectField
                name={`applicationstrategydetails.${applicationStrategyIndex}.strategyType`}
                items={Object.keys(STRATEGY_TYPE_OPTIONS)?.map((item) => ({
                  key: STRATEGY_TYPE_OPTIONS[item],
                  value: item,
                }))}
                applyLabelStyles={false}
                disabled={true}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box width={'100%'}>
              <Item>Benchmark</Item>
              <ItemDetails sx={{ marginTop: '0.5rem' }}>{strategy?.benchmark || ''}</ItemDetails>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="label">Investment Amount *</Typography>
            <Box
              sx={{
                width: '100%',
                '& .MuiFormControl-root': { m: 0 },
              }}>
              <MFTextField
                name={`applicationstrategydetails.${applicationStrategyIndex}.investmentAmount`}
                placeholder="Enter Investment Amount"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                  const updatedAmount = values.applicationstrategydetails?.map(
                    (strategyDetails, _index) => {
                      if (applicationStrategyIndex === _index) {
                        return {
                          ...strategyDetails,
                          investmentAmount: value ? Number(value) : null,
                        };
                      }
                      return strategyDetails;
                    }
                  );

                  setValues({
                    ...values,
                    applicationstrategydetails: updatedAmount,
                    totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedAmount) || '',
                  });
                }}
                trimOnBlur={false}
                disabled={isFieldDisabled}
              />
              {Number(strategy?.investmentAmount) > 0 && strategy?.isActive && (
                <Typography sx={{ fontSize: 13, mt: 1, textAlign: 'left' }}>
                  {currencyConversion(strategy?.investmentAmount)}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="label">Management Fee*</Typography>
            <Box
              sx={{
                width: '100%',
                '& .MuiFormControl-root': { m: 0 },
              }}>
              <MFTextField
                name={`applicationstrategydetails.${applicationStrategyIndex}.applicationStrategyManagementFee`}
                placeholder="Enter Management Fee"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <span>%</span>
                  </InputAdornment>
                }
                onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                  const updatedAmount = values.applicationstrategydetails?.map(
                    (strategyDetails, _index) => {
                      if (applicationStrategyIndex === _index) {
                        return {
                          ...strategyDetails,
                          applicationStrategyManagementFee: value ? Number(value) : null,
                        };
                      }
                      return strategyDetails;
                    }
                  );

                  setValues({
                    ...values,
                    applicationstrategydetails: updatedAmount,
                    totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedAmount) || '',
                  });
                }}
                trimOnBlur={false}
                disabled={isFieldDisabled}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            {strategy?.performanceFee && (
              <Typography sx={{ fontSize: 14, fontWeight: 500, mb: 6 }}>
                <Typography
                  component="span"
                  sx={{ fontWeight: 700, fontSize: 14, color: '#2057A6' }}>
                  Performance Fee:{' '}
                </Typography>
                <Typography>{strategy?.performanceFee}</Typography>
              </Typography>
            )}
            <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#2057A6', mb: 1 }}>
              <Typography component="span" sx={{ fontWeight: 700, fontSize: 14, color: '#2057A6' }}>
                Other Operational Charges :
              </Typography>
              {strategy?.otherOperationalCharges?.map((item, index) => {
                return (
                  <Typography key={index}>{`(${convertToRoman(index + 1)}) ${item}`}</Typography>
                );
              })}
            </Typography>
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <DataTable
              tableHeader={[...columns]}
              tableData={
                values.applicationstrategydetails[applicationStrategyIndex]
                  .applicationInvestmentAmountDetails || []
              }
              boxStyles={{
                minWidth: 500,
                width: '100%',
                minHeight: '0px',
                maxHeight: 'unset',
                marginTop: -4,
              }}
            />
          </Grid> */}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

// function StrategyTable({
//   values,
//   setValues,
//   strategyMasterData,
// }: {
//   values: Values;
//   setValues: FormikHelpers<Values>['setValues'];
//   strategyMasterData: GetStrategiesResponseBody[];
// }): JSX.Element {
//   const { application } = useSelector((store: RootStateType) => store.application);
//   const { staggeredStrategyFrom = '', staggeredStrategyTo = '' } = application || {};
//   const headers = [
//     {
//       header: 'Strategy',
//       label: 'strategy',
//       renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
//         return (
//           <>
//             <MFCheckbox
//               name={`applicationstrategydetails.${index}.isActive`}
//               label={params.strategyName}
//               sx={{ letterSpacing: '1px', width: 140, maxWidth: '100%', mt: 1 }}
//               onChange={({ target: { checked } }) => {
//                 const updatedStrategy = values.applicationstrategydetails?.map(
//                   (strategyDetails, _index) => {
//                     if (index === _index) {
//                       return {
//                         ...strategyDetails,
//                         isActive: checked,
//                         fee: checked ? strategyMasterData[index].fee : '',
//                         investmentAmount: checked ? strategyMasterData[index].investmentAmount : '',
//                         managementFeeNature: checked ? strategyDetails.managementFeeNature : '',
//                         performanceFeeNature: checked ? strategyDetails.performanceFeeNature : '',
//                         performanceFee: checked ? strategyMasterData[index].performanceFee : '',
//                       };
//                     }
//                     return strategyDetails;
//                   }
//                 ) as updatedStrategyDetails[];

//                 setValues({
//                   ...values,
//                   applicationstrategydetails: updatedStrategy,
//                   totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedStrategy) || '',
//                 });
//               }}
//             />
//             <Typography
//               sx={{
//                 ml: 4,
//                 mt: -2,
//               }}>
//               ({params?.strategyType})
//             </Typography>
//             {(staggeredStrategyFrom || staggeredStrategyTo) &&
//               params?.id &&
//               (Number(params.id) === Number(staggeredStrategyFrom) ||
//                 Number(params.id) === Number(staggeredStrategyTo)) && (
//                 <Typography sx={{ fontSize: 13, textAlign: 'left', color: 'primary.main' }}>
//                   This Strategy was selected in staggered investment plan
//                   {/* This Strategy is selected as{' '}
//                   {Number(params.id) === Number(staggeredStrategyFrom)
//                     ? 'strategy from'
//                     : 'strategy to'}{' '}
//                   in staggered investment plan details */}
//                 </Typography>
//               )}
//           </>
//         );
//       },
//     },
//     {
//       header: 'Management Fee Nature',
//       label: 'managementFeeNature',
//       renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
//         return (
//           <Stack
//             sx={{
//               flexDirection: 'row',
//               alignItems: 'center',
//               display: 'inline-flex',
//               width: 150,
//               maxWidth: '100%',
//               py: 1,
//               '& .MuiFormControl-root': { width: 180, m: 0 },
//             }}>
//             <MFSelectField
//               name={`applicationstrategydetails.${index}.managementFeeNature`}
//               items={Object.keys(strategyFeeTypeMaster).map((fee) => ({
//                 key: strategyFeeTypeMaster[fee],
//                 value: fee,
//               }))}
//               applyLabelStyles={true}
//               onChange={({ target: { value } }) => {
//                 const updatedStrategy = values.applicationstrategydetails?.map(
//                   (strategyDetails, _index) => {
//                     if (index === _index) {
//                       return {
//                         ...strategyDetails,
//                         managementFeeNature: value,
//                         fee:
//                           value !== strategyFeeTypeMaster.none ? strategyMasterData[index].fee : 0,
//                       };
//                     }
//                     return strategyDetails;
//                   }
//                 ) as updatedStrategyDetails[];

//                 setValues({
//                   ...values,
//                   applicationstrategydetails: updatedStrategy,
//                   totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedStrategy) || '',
//                 });
//               }}
//               disabled={!params?.isActive}
//             />
//           </Stack>
//         );
//       },
//     },
//     {
//       header: 'Management Fee',
//       label: 'fee',
//       renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
//         return (
//           <Stack
//             sx={{
//               flexDirection: 'row',
//               alignItems: 'center',
//               display: 'inline-flex',
//               width: 110,
//               maxWidth: '100%',
//               pb: 1.3,
//               pt: 2,
//               '& .MuiFormControl-root': { width: 120, m: 0 },
//             }}>
//             <MFTextField
//               name={`applicationstrategydetails.${index}.fee`}
//               placeholder="Enter Fee"
//               type="number"
//               onKeyDown={(e) => {
//                 allowOnlyNumbers(e);
//               }}
//               trimOnBlur={false}
//               disabled={
//                 !params?.isActive || params.managementFeeNature === strategyFeeTypeMaster.none
//               }
//             />
//             {strategyFeeTypeMaster[params.managementFeeNature] ===
//               strategyFeeTypeMaster.flatOnAum && <Typography sx={{ ml: 1 }}>%</Typography>}
//           </Stack>
//         );
//       },
//     },

//     {
//       header: 'Performance Fee Nature',
//       label: 'performanceFeeNature',
//       renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
//         return (
//           <Stack
//             sx={{
//               flexDirection: 'row',
//               alignItems: 'center',
//               display: 'inline-flex',
//               width: 150,
//               maxWidth: '100%',
//               py: 1,
//               '& .MuiFormControl-root': { width: 180, m: 0 },
//             }}>
//             <MFSelectField
//               name={`applicationstrategydetails.${index}.performanceFeeNature`}
//               items={Object.keys(strategyFeeTypeMaster).map((fee) => ({
//                 key: strategyFeeTypeMaster[fee],
//                 value: fee,
//               }))}
//               applyLabelStyles={true}
//               onChange={({ target: { value } }) => {
//                 const updatedStrategy = values.applicationstrategydetails?.map(
//                   (strategyDetails, _index) => {
//                     if (index === _index) {
//                       return {
//                         ...strategyDetails,
//                         performanceFeeNature: value,
//                         performanceFee:
//                           value !== strategyFeeTypeMaster.none
//                             ? strategyMasterData[index].performanceFee
//                             : 0,
//                       };
//                     }
//                     return strategyDetails;
//                   }
//                 ) as updatedStrategyDetails[];

//                 setValues({
//                   ...values,
//                   applicationstrategydetails: updatedStrategy,
//                   totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedStrategy) || '',
//                 });
//               }}
//               disabled={!params?.isActive}
//             />
//           </Stack>
//         );
//       },
//     },
//     {
//       header: 'Performance Fee',
//       label: 'performanceFee',
//       renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
//         return (
//           <Stack
//             sx={{
//               flexDirection: 'row',
//               alignItems: 'center',
//               display: 'inline-flex',
//               width: 110,
//               maxWidth: '100%',
//               pb: 1.3,
//               pt: 2,
//               '& .MuiFormControl-root': { width: 120, m: 0 },
//             }}>
//             <MFTextField
//               name={`applicationstrategydetails.${index}.performanceFee`}
//               placeholder="Enter Fee"
//               type="number"
//               onKeyDown={(e) => {
//                 allowOnlyNumbers(e);
//               }}
//               trimOnBlur={false}
//               disabled={
//                 !params?.isActive || params.performanceFeeNature === strategyFeeTypeMaster.none
//               }
//             />
//             {strategyFeeTypeMaster[params.performanceFeeNature] ===
//               strategyFeeTypeMaster.flatOnAum && <Typography sx={{ ml: 1 }}>%</Typography>}
//           </Stack>
//         );
//       },
//     },
//     {
//       header: 'Investment Amount',
//       label: 'investmentAmount',
//       renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
//         return (
//           <Box
//             sx={{
//               width: 200,
//               maxWidth: '100%',
//               pb: 1.3,
//               pt: 2,
//               '& .MuiFormControl-root': { m: 0 },
//             }}>
//             <MFTextField
//               name={`applicationstrategydetails.${index}.investmentAmount`}
//               placeholder="Enter Investment Amount"
//               type="number"
//               onKeyDown={(e) => {
//                 allowOnlyNumbers(e);
//               }}
//               onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
//                 const updatedAmount = values.applicationstrategydetails?.map(
//                   (strategyDetails, _index) => {
//                     if (index === _index) {
//                       return {
//                         ...strategyDetails,
//                         investmentAmount: value ? Number(value) : null,
//                       };
//                     }
//                     return strategyDetails;
//                   }
//                 );

//                 setValues({
//                   ...values,
//                   applicationstrategydetails: updatedAmount,
//                   totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedAmount) || '',
//                 });
//               }}
//               trimOnBlur={false}
//               disabled={!params?.isActive}
//             />
//             {Number(params?.investmentAmount) > 0 && params?.isActive && (
//               <Typography sx={{ fontSize: 13, mt: 1, textAlign: 'left' }}>
//                 {currencyConversion(params?.investmentAmount)}
//               </Typography>
//             )}
//           </Box>
//         );
//       },
//     },
//   ];

//   return (
//     <DataTable
//       tableData={values.applicationstrategydetails}
//       tableHeader={headers}
//       renderAdditionalRow={false}
//       tableHeaderCustomStyles={{
//         '.MuiTableCell-head': {
//           fontFamily: 'Poppins, sans-serif',
//           fontSize: 14,
//           fontWeight: 500,
//           color: 'primary.main',
//           textAlign: 'left',
//           pl: 2,
//           '&:nth-of-type(1)': {
//             pl: 6,
//           },
//         },
//       }}
//       rowCustomStyles={{
//         '.MuiTableCell-root': {
//           py: 0,
//           overflowWrap: 'anywhere',
//           textAlign: 'left',
//           verticalAlign: 'top',
//         },
//       }}
//       boxStyles={{ minHeight: '0px', minWidth: 450, maxHeight: 'unset' }}
//     />
//   );
// }

export default function StrategyDetails(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [strategyData, setStrategyData] = useState<updatedGetStrategiesResponseBody[]>([]);
  const [selectedStrategyData, setSelectedStrategyData] = useState(initialValues);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        const response = (await dispatch(
          getStrategies({
            isActive: true,
            ...(application?.distributor_id ? { distributorId: application?.distributor_id } : {}),
          })
        )) as unknown as GetStrategiesResponseBody[];
        if (!isComponentAlive) {
          return;
        }
        const _responce = response?.map((strategy) => ({
          ...strategy,
          strategyType: (strategy?.strategyType || '') as string,
          benchmark: (strategy?.benchmark || '') as string,
          feeTypeMaster: feeTypeMasterFunc(strategy),
        }));
        setStrategyData(_responce);
        const { applicationstrategydetails = [] } = application || {};
        setSelectedStrategyData({
          ...selectedStrategyData,
          totalStrategyInvestmentAmount: application?.totalStrategyInvestmentAmount || null,
          ownBorrowedFunds: application?.ownBorrowedFunds || '',
          cheque: application?.modeOfPayment ? application.modeOfPayment.includes('cheque') : false,
          rtgs: application?.modeOfPayment ? application.modeOfPayment.includes('rtgs') : false,
          securitiesTransfer: application?.modeOfPayment
            ? isSecurityTransfer(application.modeOfPayment)
            : false,
          transferSecuritiesTotalMarketValue:
            application?.transferSecuritiesTotalMarketValue || false,
          securitiesMarketValueDate: application?.securitiesMarketValueDate || null,
          nameOfSecurities: application?.nameOfSecurities || '',
          applicationstrategydetails: _responce
            ?.map((strategy) => {
              const existingStrategyDetails = applicationstrategydetails?.filter(
                (strategyDetails) => strategyDetails.strategyId === strategy.id
              );

              return existingStrategyDetails.length
                ? existingStrategyDetails?.map((existingStrategy) => {
                    return {
                      ...existingStrategy,
                      strategyType: (existingStrategy?.strategyType || '') as string,
                      benchmark: (existingStrategy?.benchmark || '') as string,
                      adminBenchmark: existingStrategy?.benchmark || strategy?.benchmark || '',
                      adminStrategyType:
                        existingStrategy?.strategyType &&
                        Object.keys(STRATEGY_TYPE_OPTIONS).includes(
                          existingStrategy?.strategyType || ''
                        )
                          ? existingStrategy?.strategyType
                          : strategy?.strategyType || '',
                      feeTypeMaster: strategy.feeTypeMaster,
                      entryLoad: existingStrategy.entryLoad,
                      exitLoad: existingStrategy.exitLoad,
                      otherOperationalCharges:
                        (existingStrategy.otherOperationalCharges || [])?.length > 0
                          ? existingStrategy.otherOperationalCharges
                          : defaultOperationalCharges,
                      performanceFee: existingStrategy.performanceFee
                        ? existingStrategy.performanceFee
                        : !application?.distributor_id
                        ? strategy?.performanceFee
                          ? (strategy?.performanceFee as string)
                          : null
                        : existingStrategy.feeType === 'fixed'
                        ? null
                        : strategy.performanceFeeHybrid
                        ? (strategy.performanceFeeHybrid as string)
                        : null,
                      feeType: !application?.distributor_id
                        ? null
                        : existingStrategy.feeType
                        ? existingStrategy.feeType
                        : strategy.performanceFeeHybrid
                        ? (strategy?.feeTypeHybrid as string)
                        : 'fixed',
                    };
                  })
                : [
                    {
                      ...newStrategy,
                      feeTypeMaster: strategy.feeTypeMaster,
                      strategyName: strategy.strategyName,
                      strategyId: strategy.id,
                      exitLoad: strategy.exitLoad,
                      entryLoad: strategy.entryLoad,
                      strategyType: strategy.strategyType,
                      benchmark: strategy.benchmark,
                      otherOperationalCharges:
                        (strategy?.otherOperationalCharges || [])?.length > 0
                          ? strategy?.otherOperationalCharges
                          : defaultOperationalCharges,
                      applicationInvestmentAmountDetails: strategy.investmentamounts?.map(
                        (amountDetail) => {
                          const { id = null, ...rest } = amountDetail;
                          return { ...rest, investmentAmountId: id };
                        }
                      ),
                      isActive: response.length > 1 ? false : true,
                      investmentAmount: response.length === 1 ? response[0].investmentAmount : null,
                      performanceFee: !application?.distributor_id
                        ? strategy?.performanceFee
                          ? (strategy?.performanceFee as string)
                          : null
                        : strategy.performanceFeeHybrid
                        ? (strategy.performanceFeeHybrid as string)
                        : null,
                      feeType: !application?.distributor_id
                        ? null
                        : strategy?.feeTypeHybrid
                        ? (strategy?.feeTypeHybrid as string)
                        : 'fixed',
                    },
                  ];
            })
            .flat(),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, [application]);

  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();

  const handleSubmit = async (values: Values) => {
    try {
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const {
        applicationstrategydetails,
        cheque,
        rtgs,
        securitiesTransfer,
        securitiesMarketValueDate,
        transferSecuritiesTotalMarketValue,
        nameOfSecurities,
        saveType,
        ...rest
      } = values;

      if (
        applicationstrategydetails.length &&
        !checkStrategyBoxSelected(applicationstrategydetails)
      ) {
        throw 'Please Select atleast One Strategy';
      }
      const isBenchmarkAdded = applicationstrategydetails
        .filter((item) => item?.isActive)
        .every((startegyItem) => startegyItem?.benchmark);

      if (!isBenchmarkAdded) {
        throw 'Benchmark is required';
      }
      let paymentMode = '';
      const modeArray = [cheque, rtgs, securitiesTransfer];
      paymentMode = Object.keys(ModeOfTransaction)
        ?.filter((mode, i) => (modeArray[i] ? mode : null))
        .toString();

      const updatedPayload = {
        ...application,
        ...rest,
        totalStrategyInvestmentAmount: rest.totalStrategyInvestmentAmount || null,
        modeOfPayment: paymentMode,
        transferSecuritiesTotalMarketValue: isSecurityTransfer(paymentMode)
          ? transferSecuritiesTotalMarketValue
          : null,
        securitiesMarketValueDate: isSecurityTransfer(paymentMode)
          ? securitiesMarketValueDate
          : null,
        nameOfSecurities: isSecurityTransfer(paymentMode) ? nameOfSecurities : null,
        applicationstrategydetails: applicationstrategydetails
          ?.filter((strategyDetails) => strategyDetails.id || strategyDetails.isActive)
          ?.map((strategy) => {
            const { feeTypeMaster, adminBenchmark, adminStrategyType, ..._strategy } = strategy;
            return {
              ..._strategy,
              investmentAmount: _strategy.investmentAmount || null,
            };
          }),
      };
      const checkApplication = applicationComparison(
        {
          ...application,
          applicationstrategydetails: application?.applicationstrategydetails?.sort(
            (details1, details2) => Number(details1.id) - Number(details2.id)
          ),
        },
        {
          ...updatedPayload,
          applicationstrategydetails: updatedPayload?.applicationstrategydetails?.sort(
            (details1, details2) => Number(details1.id) - Number(details2.id)
          ),
          currentStep: !!currentStep && currentStep > 8 ? currentStep : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';

      //save for later route for investor at liveliness
      const investorEditSaveelaterRoute =
        referenceIdForInvestorPhotoCapture &&
        saveForLaterInvestorPhotoCapture(referenceIdForInvestorPhotoCapture, application);
      const referenceIdForSaveProceedRoute = referenceIdForInvestorPhotoCapture
        ? referenceIdForInvestorPhotoCapture
        : referenceDetail.esignReferenceId;

      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...updatedPayload,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep:
                USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS ? currentStep : 9,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS
            ? history.push(`/application-details/${id}`)
            : history.push('disclosure-of-interest', {
                id,
                applicant1ReferenceId: referenceIdForSaveProceedRoute,
              })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS
            ? history.push(`/application-details/${id}`)
            : history.push('disclosure-of-interest', {
                id,
                applicant1ReferenceId: referenceIdForSaveProceedRoute,
              })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };
  return (
    <>
      <Formik
        initialValues={selectedStrategyData}
        validate={(values: Values) => {
          try {
            validateYupSchema(
              values,
              strategyDeclarationScheme(application?.distributor_id),
              true,
              values
            );
          } catch (e) {
            return yupToFormErrors(e);
          }
        }}
        onSubmit={handleSubmit}
        enableReinitialize={true}>
        {({ handleSubmit, setValues, values, errors }) => (
          <>
            <Box component="form" noValidate onSubmit={handleSubmit}>
              {/* <Notes
                displayContent={'Any strategy change could affect the staggered investment plan.'}
              /> */}
              {values.applicationstrategydetails?.length !== 0 && (
                <Box sx={{ mt: 3 }}>
                  {values.applicationstrategydetails?.map((strategy, index) => (
                    <StrategyAccordion
                      key={index}
                      values={values}
                      setValues={setValues}
                      strategyMasterData={strategyData}
                      strategy={strategy}
                      applicationStrategyIndex={index}
                    />
                  ))}
                </Box>
              )}
              {/* <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#2057A6', mt: 4 }}>
                <Typography
                  component="span"
                  sx={{ fontWeight: 700, fontSize: 14, color: '#2057A6' }}>
                  Note :{' '}
                </Typography>
                {`>20% pa - 20% of excess returns + GST`}
              </Typography> */}
              <Box sx={{ mt: 4 }}>
                <Typography sx={{ fontSize: 25, fontWeight: 500, color: '#131836', mb: 4 }}>
                  Source of Investment
                </Typography>
                <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#2057A6', mb: 1 }}>
                  Complete the funding source details by which the Holder(s) wish to fund this PMS
                  investment.
                </Typography>
                <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#2057A6', mb: 1 }}>
                  <Typography
                    component="span"
                    sx={{ fontWeight: 700, fontSize: 14, color: '#2057A6' }}>
                    Note :{' '}
                  </Typography>
                  This section is required to be filled based on SEBI’s regulation pertaining to the
                  prevention of money laundering act, 2002.
                </Typography>
                <SubHeading sx={{ color: '#131836', fontSize: 18, fontWeight: 500, mt: 0, mb: 3 }}>
                  Fund details
                </SubHeading>
                <Box sx={{ mb: 5, maxWidth: 310, '& .MuiFormControl-root': { mb: 0 } }}>
                  <MFTextField
                    name="totalStrategyInvestmentAmount"
                    label="Total Investment Amount (in INR)"
                    placeholder="Enter Total Investment Amount"
                    type="number"
                    onKeyDown={(e) => {
                      allowOnlyNumbers(e);
                    }}
                    trimOnBlur={false}
                    disabled={true}
                  />
                  {values.totalStrategyInvestmentAmount && (
                    <Typography sx={{ fontSize: 13, mt: 0.5 }}>
                      {currencyConversion(values.totalStrategyInvestmentAmount)}
                    </Typography>
                  )}
                </Box>
                <UseRadioGroup
                  formLabel="Do you intend to invest in the stock market with own Funds/Borrowed Funds? *"
                  name="ownBorrowedFunds"
                  items={Object.keys(FundInvestStockMarket).map((fundType) => ({
                    label: FundInvestStockMarket[fundType],
                    value: fundType,
                  }))}
                  disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
                />
                <Box sx={{ mt: 5 }}>
                  <Typography
                    sx={{ fontSize: 14, color: 'rgba(38, 48, 60, 0.7)', fontWeight: 500 }}>
                    What is the mode of transaction for this investment? *
                  </Typography>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      position: 'relative',
                      '& .MuiFormControlLabel-root .MuiFormControlLabel-label': {
                        fontSize: 14,
                        fontWeight: 500,
                        color: 'rgba(38, 48, 60, 0.6)',
                      },
                      '& .MuiFormHelperText-root': {
                        position: 'absolute',
                        left: 0,
                        bottom: -15,
                      },
                    }}>
                    <MFCheckbox
                      name="cheque"
                      label="Cheque"
                      sx={{ letterSpacing: '1px', mr: 4 }}
                      disabled={getDisableStatusForjointHolder(
                        role,
                        referenceDetail.applicant_type
                      )}
                    />
                    <MFCheckbox
                      name="rtgs"
                      label="Online Transfer (RTGS)"
                      sx={{ letterSpacing: '1px', mr: 4 }}
                      disabled={getDisableStatusForjointHolder(
                        role,
                        referenceDetail.applicant_type
                      )}
                    />
                    <MFCheckbox
                      name="securitiesTransfer"
                      label="Securities Transfer"
                      sx={{ letterSpacing: '1px', mr: 4 }}
                      disabled={getDisableStatusForjointHolder(
                        role,
                        referenceDetail.applicant_type
                      )}
                    />
                  </Stack>
                  {values.securitiesTransfer && (
                    <Grid
                      container
                      rowSpacing={1}
                      sx={{
                        width: '100%',
                        ml: 0,
                        mt: 2,
                        '.MuiGrid-item': {
                          pr: { xs: 0, sm: '30px' },
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        },
                      }}>
                      <Grid item xs={12} sm={12}>
                        <MFCheckbox
                          name="transferSecuritiesTotalMarketValue"
                          label="Total market value of the securities to be transferred"
                          sx={{ letterSpacing: '1px', mr: 4 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextDatePicker
                          label={'Date of the market value'}
                          inputLabelStyles={{
                            transform: 'unset',
                            fontSize: 14,
                            fontWeight: 500,
                            color: 'rgba(0,0,0,0.7)',
                          }}
                          placeholder={'DD/MM/YYYY'}
                          name="securitiesMarketValueDate"
                          minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 125))}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MFTextField
                          name="nameOfSecurities"
                          label="Name of the securities which are transferred"
                          placeholder="Enter name of the securities which are transferred"
                        />
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Box>
              <ProceedSaveLater
                saveLater={() => {
                  setValues({
                    ...values,
                    saveType: 'save for later',
                  });
                }}
                saveAndProceed={() => {
                  setValues({
                    ...values,
                    saveType: 'save and proceed',
                  });
                }}
                loader={loading}
                clickedButton={values.saveType}
                saveButtonText={
                  USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS
                    ? 'Save Application'
                    : 'Save and Proceed'
                }
                showSaveForLater={
                  USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS ? false : true
                }
                showEndIcon={
                  USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS ? false : true
                }
              />
            </Box>
          </>
        )}
      </Formik>
    </>
  );
}
