import {
  Box,
  Grid,
  Typography,
  Hidden,
  IconButton,
  LinearProgress,
  CardMedia,
  Chip,
} from '@mui/material';
import { SubHeading } from '../investors/components';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles, Item, ItemDetails } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { Location } from 'history';
import { KeyValue } from '../ApplicationDetails/contributor';
import { getStrategyById } from '../../redux-store/actions/strategies';
import { accessType } from '../../redux-store/types/funds';
import { fundAccess } from '../../redux-store/actions/funds';
import {
  AdminStrategyDetailsType,
  DistributorForStrategy,
  investmentAmountsType,
} from '../../redux-store/types/api-types';
import { DataTable } from '../DataTable';
import { convertToRoman } from '../../utils/utilityFunctions';
import { STRATEGY_TYPE_OPTIONS } from '../../utils/constant';

export default function StrategyDetails({
  location,
}: {
  location: Location<{ StrategyId: string }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [access, setAccess] = useState(false);
  const { StrategyId } = location.state || {};
  const [strategyDetails, setStrategyDetails] = useState<AdminStrategyDetailsType>();

  const columns: any = [
    {
      header: 'Investment Amount',
      label: 'InvestmentAmount',
      valueGetter: (row: investmentAmountsType) => row.investmentAmount || 'N/A',
    },
    {
      header: 'Performance Fees',
      label: 'performanceFee',
      valueGetter: (row: investmentAmountsType) => row.performanceFee || 'N/A',
    },
    // {
    //   header: 'Management Fee',
    //   label: 'managementFee',
    //   valueGetter: (row: investmentAmountsType) => row.managementFee || 'N/A',
    // },
  ];
  const DistributorPerformenceFeeColumns: any = [
    {
      header: 'Distributor Name',
      label: 'distributorIds',
      valueGetter: (row: DistributorForStrategy) =>
        strategyDetails?.distributors?.find((item) => Number(item?.id) === Number(row?.id))?.name ||
        'N/A',
    },
    {
      header: 'Performance Fee',
      label: 'DistributorPerformenceFee',
      valueGetter: (row: DistributorForStrategy) => row.performanceFee || 'N/A',
    },
  ];

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        if (StrategyId) {
          const res = (await dispatch(
            getStrategyById(StrategyId)
          )) as unknown as AdminStrategyDetailsType;
          setStrategyDetails({
            ...res,
            investmentamounts: res.investmentamounts,
          });
        }
        if (!isComponentActive) {
          return;
        }
        setAccess(fundSetupAccess);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}>
        <Box sx={{ p: { lg: 2, xs: 2 } }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: { lg: 'center', xs: 'flex-start' },
              flexDirection: { md: 'row', xs: 'column' },
              justifyContent: 'space-between',
              ml: { lg: 7, xs: 2 },
              pt: { xs: 2, sm: 4 },
              mr: { lg: 7, xs: 2 },
              // pb: 1,
            }}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() => history.push('strategy-list')}>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  width: '100%',
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                    pr: 5,
                  }}>
                  Strategy Setup
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() => history.push('strategy-list')}>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {'Strategy Details'}
              </Typography>
              <Hidden smUp={true}>
                {access && (
                  <IconButton sx={{ p: 0, ml: 12 }}>
                    <CardMedia
                      component="img"
                      src="/images/edit-icon-outlined.svg"
                      alt="Edit Icon"
                      sx={{ width: 'unset' }}
                      onClick={() => history.push(`edit-strategy`, { StrategyId: StrategyId })}
                    />
                  </IconButton>
                )}
              </Hidden>
            </Box>
            <Hidden only="xs">
              {access && (
                <IconButton sx={{ p: 0, m: 0 }}>
                  <CardMedia
                    component="img"
                    src="/images/edit-icon-outlined.svg"
                    alt="Edit Icon"
                    sx={{ width: 'unset' }}
                    onClick={() => history.push(`edit-strategy`, { StrategyId: StrategyId })}
                  />
                </IconButton>
              )}
            </Hidden>
          </Box>
          {isLoading && <LinearProgress sx={{ ml: 5, mr: 5 }} />}
          {strategyDetails && (
            <>
              <Box sx={{ mr: { lg: 20, xs: 4 } }}>
                <SubHeading
                  sx={{
                    color: 'common.blue',
                    letterSpacing: 0.5 + 'px',
                    padding: { xs: '10px 10px', sm: '10px 30px' },
                    ml: { lg: 8, xs: 2 },
                  }}>
                  {'Strategy Details'}
                </SubHeading>
              </Box>
              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
                <Box sx={{ width: '100%' }}>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue title="Strategy Name" description={strategyDetails?.strategyName} />
                      <KeyValue title="Entry Load" description={strategyDetails?.entryLoad} />
                      <KeyValue title="Exit Load" description={strategyDetails?.exitLoad} />
                      <KeyValue
                        title="Performance Fee"
                        description={strategyDetails?.performanceFee}
                      />
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                          }}>
                          <Item>Other Operational Charges</Item>
                          {(strategyDetails?.otherOperationalCharges || [])?.map(
                            (values, index) => {
                              return (
                                <ItemDetails key={index}>
                                  {`(${convertToRoman(index + 1)}) ${values}` || ''}
                                </ItemDetails>
                              );
                            }
                          )}
                        </Box>
                      </Grid>
                      <KeyValue
                        title="Strategy Type"
                        description={STRATEGY_TYPE_OPTIONS[strategyDetails?.strategyType] || ''}
                      />
                      <KeyValue title="Benchmark" description={strategyDetails?.benchmark} />
                      <Grid item xs={12}>
                        <Item>{`Applicable Distributor`}</Item>
                        {strategyDetails?.distributors.length > 0 ? (
                          <Box
                            sx={{
                              border: 0.5,
                              // borderColor: 'rgba(41, 49, 57, 0.7)',
                              borderColor: 'primary.main',
                              p: 3,
                              mb: 3,
                              mt: 2,
                              borderRadius: 0.5,
                            }}>
                            {strategyDetails?.distributors?.map((distributor) => {
                              return (
                                <React.Fragment key={distributor.id}>
                                  <Chip
                                    label={`${distributor.arnCode || 'N/A'} - ${
                                      distributor.name
                                    } (${distributor.type})`}
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 500,
                                      color: 'rgba(41, 49, 57, 0.7)',
                                      // p: 2,
                                      m: 0.8,
                                    }}
                                  />
                                </React.Fragment>
                              );
                            })}
                          </Box>
                        ) : (
                          <ItemDetails>-</ItemDetails>
                        )}
                      </Grid>
                      {strategyDetails?.distributors && (
                        <Grid item xs={12} sm={12} md={12}>
                          <DataTable
                            tableData={
                              strategyDetails?.distributors?.length
                                ? strategyDetails?.distributors
                                : []
                            }
                            tableHeader={DistributorPerformenceFeeColumns}
                            noOfPages={1}
                            renderAdditionalRow={true}
                            tableBodyCustomStyles={{
                              '.MuiTableCell-root': {
                                borderBottom: 'none',
                              },
                            }}
                            tableHeaderCustomStyles={{
                              '.MuiTableCell-head': {
                                fontFamily: 'Poppins, sans-serif',
                                fontSize: 14,
                                fontWeight: 500,
                                color: 'primary.main',
                                textAlign: 'left',
                                pl: 2,
                              },
                            }}
                            rowCustomStyles={{
                              '.MuiTableCell-root': {
                                py: 0,
                                overflowWrap: 'anywhere',
                                textAlign: 'left',
                                verticalAlign: 'top',
                              },
                            }}
                            boxStyles={{ minHeight: '0px', minWidth: 450, maxHeight: 'unset' }}
                          />
                        </Grid>
                      )}
                      {/* <Grid item xs={12} sm={12} md={12}>
                        <DataTable
                          tableData={
                            strategyDetails?.investmentamounts?.length
                              ? strategyDetails?.investmentamounts
                              : []
                          }
                          tableHeader={columns}
                          noOfPages={1}
                          renderAdditionalRow={true}
                          tableBodyCustomStyles={{
                            '.MuiTableCell-root': {
                              borderBottom: 'none',
                            },
                          }}
                          tableHeaderCustomStyles={{
                            '.MuiTableCell-head': {
                              fontFamily: 'Poppins, sans-serif',
                              fontSize: 14,
                              fontWeight: 500,
                              color: 'primary.main',
                              textAlign: 'left',
                              pl: 2,
                            },
                          }}
                          rowCustomStyles={{
                            '.MuiTableCell-root': {
                              py: 0,
                              overflowWrap: 'anywhere',
                              textAlign: 'left',
                              verticalAlign: 'top',
                            },
                          }}
                          boxStyles={{ minHeight: '0px', minWidth: 450, maxHeight: 'unset' }}
                        />
                      </Grid> */}
                    </Grid>
                  </Gridstyles>
                </Box>
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
