import { CardMedia, Grid, InputAdornment, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { MFTextField } from '../../lib/formik';
import { Formik, FormikHandlers, FormikHelpers, validateYupSchema, yupToFormErrors } from 'formik';
import MFCheckbox from '../../lib/formik/Checkbox';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  getAddressData,
  getAddressFields,
  getApplicantName,
  preventSpecialCharacters,
  saveForLater,
  _updatedAddresses,
  checkForCorrespondenceAddress,
  removeSingleQuote,
  saveForLaterInvestorPhotoCapture,
  checkEditiable,
  checkNDPMSForIndusindBank,
  isCustodianICICIOrHDFC,
  isCustodianNuvuma,
  isCustodianHDFC,
} from '../../utils/utilityFunctions';
import {
  Applicant,
  ApplicantAddressType,
  ApplicationProps,
} from '../../redux-store/types/api-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { contactDetailsSchema } from '../../utils/schema';
import {
  USER_ROLES,
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  applicantStatusMasters,
  ENABLE_EMAIL_OTP,
  APPLICANT_STATUS,
  ContactRelations,
  ContactRelationsForHDFC,
} from '../../utils/constant';
import { CountryCodesDropDown } from '../commonComponents';
import { getNationalityList, getStatesList } from '../../redux-store/actions';
import {
  mdmsCountriesList,
  mdmsStatesList,
  nationaliyType,
  statesType,
} from '../../redux-store/types/mdms';
import { useSnackbar } from 'notistack';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { OnEditDialogBox } from './additionalKYCDetails';
import MFSelectField from '../../lib/formik/SelectField';

type AddressType = {
  nationality?: string | null;
  status?: string | null;
  permanent?: Partial<ApplicantAddressType>;
  correspondence?: Partial<ApplicantAddressType>;
  overseas?: Partial<ApplicantAddressType>;
  [key: string]: Partial<ApplicantAddressType> | string | null | undefined;
};

type ContactDetailsProps = {
  phoneNumberoffice: string;
  phoneNumberResidence: string;
  email: string;
  mobile: string;
  countryNameAndCode: string;
  countryCode: string;
  address: AddressType;
  kraMobileNumber: string;
  kraEmail: string;
  mobileEmailDeclaration?: string | null;
  mobileDeclaration?: string | null;
  emailDeclaration?: string | null;
};

export type Values = {
  hasPOA: boolean;
  applicants: ContactDetailsProps[];
  saveType: string;
  countryDropdown: string[];
  statesDropdown: string[];
  [key: string]: string | boolean | ContactDetailsProps[] | string[];
};

export const Address = ({
  addressType = '',
  index,
  nationalitiesMdmsResponse,
  fetchedFromKRACheck,
  statesMdmsResponse = [],
  address = {},
}: {
  addressType: string;
  index: number;
  nationalitiesMdmsResponse: mdmsCountriesList[];
  statesMdmsResponse?: mdmsStatesList[];
  fetchedFromKRACheck: boolean | null;
  address?: Partial<ApplicantAddressType>;
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const isFieldDisabled =
    (['overseas', 'permanent'].includes(addressType) && [USER_ROLES.POAAPPROVER].includes(role)) ||
    (addressType !== 'correspondence' && fetchedFromKRACheck) ||
    false;
  const { application } = useSelector((store: RootStateType) => store.application);
  const selectedCustodian = application?.custodian || '';
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.address.${addressType}.address1`}
          label={`Address Line 1 *`}
          placeholder="Enter Address Line 1"
          disabled={
            isFieldDisabled || checkEditiable(referenceDetail.applicant_type, role, index + 1)
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.address.${addressType}.address2`}
          label={`Address Line 2 ${
            !fetchedFromKRACheck || (fetchedFromKRACheck && addressType !== 'permanent') ? '*' : ''
          }`}
          placeholder="Enter Address Line 2"
          disabled={
            isFieldDisabled || checkEditiable(referenceDetail.applicant_type, role, index + 1)
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.address.${addressType}.address3`}
          label={`Address Line 3`}
          placeholder="Enter Address Line 3"
          disabled={
            isFieldDisabled || checkEditiable(referenceDetail.applicant_type, role, index + 1)
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.address.${addressType}.pincode`}
          label={`Pincode *`}
          placeholder="Enter Pincode"
          disabled={
            isFieldDisabled || checkEditiable(referenceDetail.applicant_type, role, index + 1)
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.address.${addressType}.city`}
          label={`City *`}
          placeholder="Enter City"
          disabled={
            isFieldDisabled || checkEditiable(referenceDetail.applicant_type, role, index + 1)
          }
        />
      </Grid>
      {isCustodianICICIOrHDFC(selectedCustodian) && (
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.address.${addressType}.district`}
            label={`District ${addressType === 'overseas' ? '' : '*'}`}
            placeholder="Enter District"
            disabled={checkEditiable(referenceDetail.applicant_type, role, index + 1)}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        {isCustodianICICIOrHDFC(selectedCustodian) &&
        addressType !== 'overseas' &&
        address?.country === 'INDIA' ? (
          <SearchableSelect
            name={`applicants.${index}.address.${addressType}.state`}
            label={`State *`}
            items={statesMdmsResponse.map((state) => ({
              key: state.name,
              value: state.name,
            }))}
            disabled={
              isFieldDisabled || checkEditiable(referenceDetail.applicant_type, role, index + 1)
            }
            searchFieldPlaceholder={'Search State'}
          />
        ) : (
          <MFTextField
            name={`applicants.${index}.address.${addressType}.state`}
            label={addressType === 'overseas' ? `State` : `State *`}
            placeholder="Enter State"
            disabled={
              isFieldDisabled || checkEditiable(referenceDetail.applicant_type, role, index + 1)
            }
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        {/* {!nationalitiesMdmsResponse && (
          <MFTextField
            name={`applicants.${index}.address.${addressType}.country`}
            label={`Country *`}
            placeholder="Enter Country"
            disabled={isFieldDisabled}
          />
        )} */}
        {/* {nationalitiesMdmsResponse && ( */}
        <SearchableSelect
          name={`applicants.${index}.address.${addressType}.country`}
          label="Country *"
          items={nationalitiesMdmsResponse.map((nationality) => ({
            key: nationality.name,
            value: nationality.name,
          }))}
          disabled={
            isFieldDisabled || checkEditiable(referenceDetail.applicant_type, role, index + 1)
          }
          searchFieldPlaceholder={'Search Country'}
        />
        {/* )} */}
      </Grid>
    </>
  );
};

const ApplicantDetails = ({
  values,
  handleChange,
  setValues,
  mdmsCountriesList,
  mdmsStatesList,
  application,
}: {
  values: Values;
  handleChange: FormikHandlers['handleChange'];
  setValues: FormikHelpers<Values>['setValues'];
  mdmsCountriesList: mdmsCountriesList[];
  mdmsStatesList: mdmsStatesList[];
  application: ApplicationProps | null;
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const [mobileElementWidth, setMobileElementWidth] = useState(null);
  useEffect(() => {
    const mobileElement = document.getElementsByName('applicants.0.mobile');
    setMobileElementWidth(mobileElement[0]?.parentElement?.clientWidth as any);
  }, []);
  return (
    <React.Fragment>
      {values.applicants.map((applicant, index) => (
        <React.Fragment key={index}>
          <SubHeading>Contact details of {getApplicantName(index + 1)} Applicant</SubHeading>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.email`}
              label="E-Mail ID *"
              placeholder="Enter E-Mail ID"
              // readOnly={values.hasPOA ? false : true && ENABLE_EMAIL_OTP}
              startAdornment={
                <InputAdornment position="start">
                  <CardMedia component="img" alt="Email Icon" src="/images/email.svg" />
                </InputAdornment>
              }
              disabled={checkEditiable(referenceDetail.applicant_type, role, index + 1)}
            />
            {/* <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              <InfoOutlinedIcon color="info" fontSize="small" />
              <Typography
                sx={{
                  color: 'rgba(41, 49, 57, 0.7)',
                  fontSize: '12px',
                  fontWeight: 450,
                  ml: 1,
                }}>
                {`This email address cannot be modified`}
              </Typography>
            </Typography> */}
          </Grid>
          {isCustodianHDFC(application?.custodian || '') && (
            <Grid item xs={12} sm={6}>
              <MFSelectField
                name={`applicants.${index}.emailDeclaration`}
                label="This Email Id Belongs To? *"
                items={Object.keys(ContactRelationsForHDFC).map((relatedTo) => ({
                  key: ContactRelationsForHDFC[relatedTo],
                  value: relatedTo,
                }))}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.mobile`}
              label="Mobile *"
              placeholder="Enter Mobile Number"
              onKeyDown={(e) => {
                preventSpecialCharacters(e);
              }}
              // readOnly={values.hasPOA ? false : true}
              startAdornment={
                <CountryCodesDropDown
                  ElementWidth={mobileElementWidth && mobileElementWidth}
                  name={`applicants.${index}.countryNameAndCode`}
                  value={values.applicants[index].countryNameAndCode}
                  // readOnly={values.hasPOA ? false : true}
                  // disabled={[USER_ROLES.INVESTOR].includes(role)}
                  disabled={checkEditiable(referenceDetail.applicant_type, role, index + 1)}
                />
              }
              disabled={checkEditiable(referenceDetail.applicant_type, role, index + 1)}
              // disabled={[USER_ROLES.INVESTOR].includes(role)}
            />
            {/* <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              <InfoOutlinedIcon color="info" fontSize="small" />
              <Typography
                sx={{
                  color: 'rgba(41, 49, 57, 0.7)',
                  fontSize: '12px',
                  fontWeight: 450,
                  ml: 1,
                }}>
                {`This mobile number cannot be modified`}
              </Typography>
            </Typography> */}
          </Grid>
          {isCustodianNuvuma(application?.custodian || '') && (
            <Grid item xs={12} sm={6}>
              <MFSelectField
                name={`applicants.${index}.mobileEmailDeclaration`}
                label="This Email Id & Mobile Number Belongs To? *"
                items={Object.keys(ContactRelations).map((relatedTo) => ({
                  key: ContactRelations[relatedTo],
                  value: relatedTo,
                }))}
              />
            </Grid>
          )}
          {isCustodianHDFC(application?.custodian || '') && (
            <Grid item xs={12} sm={6}>
              <MFSelectField
                name={`applicants.${index}.mobileDeclaration`}
                label="This Mobile Number Belongs To? *"
                items={Object.keys(ContactRelationsForHDFC).map((relatedTo) => ({
                  key: ContactRelationsForHDFC[relatedTo],
                  value: relatedTo,
                }))}
              />
            </Grid>
          )}
          {applicant.kraMobileNumber && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.kraMobileNumber`}
                label={`KRA Mobile Number`}
                placeholder="Enter KRA Mobile Number"
                disabled={true}
              />
            </Grid>
          )}
          {applicant.kraEmail && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`applicants.${index}.kraEmail`}
                label={`KRA E-Mail ID`}
                placeholder="Enter KRA E-Mail ID"
                disabled={true}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.phoneNumberoffice`}
              label="Phone (Office)"
              placeholder="Enter Phone (Office)"
              onChange={handleChange}
              type="number"
              disabled={checkEditiable(referenceDetail.applicant_type, role, index + 1)}
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              startAdornment={
                <InputAdornment position="start">
                  <CardMedia component="img" alt="Phone Icon" src="/images/phone.svg" />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`applicants.${index}.phoneNumberResidence`}
              label="Phone (Residential)"
              placeholder="Enter Phone (Residential)"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              startAdornment={
                <InputAdornment position="start">
                  <CardMedia component="img" alt="Phone Icon" src="/images/phone.svg" />
                </InputAdornment>
              }
              disabled={checkEditiable(referenceDetail.applicant_type, role, index + 1)}
            />
          </Grid>
          {/* {applicant.address.nationality?.toLowerCase() !== 'indian' && (
            <React.Fragment>
              <SubHeading>Overseas Address of {getApplicantName(index + 1)} Applicant</SubHeading>
              <Address
                addressType={'overseas'}
                index={index}
                nationalitiesMdmsResponse={mdmsCountriesList}
              />
            </React.Fragment>
          )} */}
          {checkForCorrespondenceAddress(
            applicant.address.nationality,
            applicant.address.status || ''
          ) ? (
            <>
              <SubHeading>
                Correspondence Address of {getApplicantName(index + 1)} Applicant
              </SubHeading>
              <Address
                addressType={'correspondence'}
                index={index}
                nationalitiesMdmsResponse={mdmsCountriesList}
                key={values.applicants.length}
                fetchedFromKRACheck={applicant.address.correspondence?.fetchedFromKRA || null}
                address={values.applicants[index].address.correspondence}
                statesMdmsResponse={mdmsStatesList}
              />
            </>
          ) : (
            <React.Fragment>
              <SubHeading>Overseas Address of {getApplicantName(index + 1)} Applicant</SubHeading>
              <Address
                addressType={'overseas'}
                index={index}
                nationalitiesMdmsResponse={mdmsCountriesList}
                statesMdmsResponse={mdmsStatesList}
                address={values.applicants[index].address.overseas}
                fetchedFromKRACheck={applicant.address.overseas?.fetchedFromKRA || null}
              />
            </React.Fragment>
          )}
          {applicant.address.nationality &&
            !applicant.address.permanent?.fetchedFromKRA &&
            !applicant.address.correspondence?.fetchedFromKRA &&
            !applicant.address.overseas?.fetchedFromKRA && (
              <MFCheckbox
                onChange={({ target: { checked } }) =>
                  setValues({
                    ...values,
                    applicants: values.applicants.map((_applicant, _index) => {
                      if (_index === index) {
                        return {
                          ..._applicant,
                          address: checkForCorrespondenceAddress(
                            _applicant.address.nationality,
                            _applicant.address.status || ''
                          )
                            ? {
                                ..._applicant.address,
                                correspondence: {
                                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                  ..._applicant.address.correspondence!,
                                  permanentAddressSameAsCorresponding: checked,
                                },
                                ...(!checked && { permanent: getAddressFields('permanent') }),
                              }
                            : {
                                ..._applicant.address,
                                overseas: {
                                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                  ..._applicant.address.overseas!,
                                  permanentAddressSameAsCorresponding: checked,
                                },
                                ...(!checked && { permanent: getAddressFields('permanent') }),
                              },
                        };
                      }
                      return _applicant;
                    }),
                  })
                }
                name={`applicants.${index}.address.${
                  checkForCorrespondenceAddress(
                    applicant.address.nationality,
                    applicant.address.status || ''
                  )
                    ? 'correspondence'
                    : 'overseas'
                }.permanentAddressSameAsCorresponding`}
                label={
                  checkForCorrespondenceAddress(
                    applicant.address.nationality,
                    applicant.address.status || ''
                  )
                    ? 'Permanent address same as correspondence address.'
                    : 'Permanent address same as overseas address.'
                }
                sx={{ ml: '40px' }}
                disabled={[USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)}
              />
            )}
          {(checkForCorrespondenceAddress(
            applicant.address.nationality,
            applicant.address.status || ''
          )
            ? !values.applicants[index]?.address?.correspondence
                ?.permanentAddressSameAsCorresponding
            : !values.applicants[index]?.address?.overseas
                ?.permanentAddressSameAsCorresponding) && (
            <>
              <SubHeading>Permanent Address of {getApplicantName(index + 1)} Applicant</SubHeading>
              <Address
                addressType={'permanent'}
                index={index}
                nationalitiesMdmsResponse={mdmsCountriesList}
                fetchedFromKRACheck={applicant.address.permanent?.fetchedFromKRA || null}
                address={values.applicants[index].address.permanent}
                statesMdmsResponse={mdmsStatesList}
              />
            </>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default function ContactDetails(): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const initialValues: Values = {
    hasPOA: false,
    applicants: [
      {
        phoneNumberoffice: '',
        phoneNumberResidence: '',
        email: '',
        mobile: '',
        countryNameAndCode: 'India: +91',
        countryCode: '+91',
        address: {
          nationality: null,
          status: null,
          permanent: getAddressFields('permanent'),
          correspondence: getAddressFields('correspondence'),
          overseas: getAddressFields('overseas'),
        },
        kraMobileNumber: '',
        kraEmail: '',
        mobileEmailDeclaration: '',
        mobileDeclaration: '',
        emailDeclaration: '',
      },
    ],
    saveType: 'save and proceed',
    countryDropdown: [],
    statesDropdown: [],
  };
  const [contactDetails, setContactDetails] = useState(initialValues);
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  const [mdmsStatesList, setMdmsStatesList] = useState<mdmsStatesList[]>([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();
  const [showDialogForEdit, setShowShowForEdit] = useState(false);
  const [finalvalue, setFinalValue] = useState<any>();
  const checkNdpmsFlow = checkNDPMSForIndusindBank(
    application?.accountType,
    application?.bankDetails
  );
  const selectedCustodian = application?.custodian || '';

  const updatePayloadAndCheckApplication = (values: Values, verifyCurrentStep: boolean) => {
    try {
      const { applicants = [], currentStep } = application || {};
      const updatePayload = applicants
        .map((applicant, index: number) => {
          const { address: updatedAddresses = {}, ...rest } = values.applicants[index] || {};
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { nationality, status, ...addressProps } = updatedAddresses;
          return {
            ...applicant,
            ...rest,
            email: removeSingleQuote(rest.email),
            countryCode: rest.countryNameAndCode?.split(':')[1].trim(),
            addresses:
              !checkForCorrespondenceAddress(applicant.nationality, status) &&
              applicant.addresses?.map((address) => address.address_type).includes('correspondence')
                ? [
                    ..._updatedAddresses(updatedAddresses, applicant),
                    {
                      ...getAddressData('correspondence', applicant.addresses),
                      isActive: false,
                      address_type: 'correspondence',
                    },
                  ]
                : checkForCorrespondenceAddress(applicant.nationality, status) &&
                  applicant.addresses?.map((address) => address.address_type).includes('overseas')
                ? [
                    ..._updatedAddresses(updatedAddresses, applicant),
                    {
                      ...getAddressData('overseas', applicant.addresses),
                      isActive: false,
                      address_type: 'overseas',
                    },
                  ]
                : _updatedAddresses(updatedAddresses, applicant),
          };
        })
        .map((_applicant) => {
          return {
            ..._applicant,
            addresses: _applicant.addresses.map((address) => {
              return {
                ...address,
                address1: removeSingleQuote(address.address1),
                address2: removeSingleQuote(address.address2),
                address3: removeSingleQuote(address.address3),
                city: removeSingleQuote(address.city),
                state: removeSingleQuote(address.state),
              };
            }),
          };
        });

      let currentStepIsIncluded = {};
      if (verifyCurrentStep) {
        currentStepIsIncluded = {
          currentStep: !!currentStep && currentStep > 2 ? currentStep : Number(currentStep) + 1,
        };
      }
      const checkApplication = applicationComparison(
        {
          ...application,
          applicants: application?.applicants
            ?.map((applicant) => {
              return {
                ...applicant,
                addresses: applicant.addresses?.sort(
                  (address1, address2) => Number(address1.id) - Number(address2.id)
                ),
              };
            })
            .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
        },
        {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...application!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          applicants: updatePayload
            .map((applicant) => {
              return {
                ...applicant,
                addresses: applicant?.addresses?.sort(
                  (address1, address2) => Number(address1.id) - Number(address2.id)
                ),
              };
            })
            .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
          ...currentStepIsIncluded,
        }
      );
      return { updatePayload, checkApplication };
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  const onSubmit = async (values: Values) => {
    try {
      const { id, status, hasPOA, applicationNumber } = application || {};
      const { saveType = '' } = values;
      const updatePayloadAndCheckApplicationValue = updatePayloadAndCheckApplication(values, true);
      const checkApplication = updatePayloadAndCheckApplicationValue?.checkApplication;
      const updatePayload = updatePayloadAndCheckApplicationValue?.updatePayload;
      const isSaveLater = saveType !== 'save and proceed';

      //save for later route for investor at liveliness
      const investorEditSaveelaterRoute =
        referenceIdForInvestorPhotoCapture &&
        saveForLaterInvestorPhotoCapture(referenceIdForInvestorPhotoCapture, application);
      const referenceIdForSaveProceedRoute = referenceIdForInvestorPhotoCapture
        ? referenceIdForInvestorPhotoCapture
        : referenceDetail.esignReferenceId;

      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ...application!,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              applicants: updatePayload!,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              ndpmsSectionEdited: showDialogForEdit ? true : application?.ndpmsSectionEdited,
              currentStep: 3,
              //!!currentStep && currentStep > 2 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && {
              toastMessage: '',
            }),
          })
        );
        !isSaveLater
          ? history.push('additional-KYC-details', {
              id,
              applicant1ReferenceId: referenceIdForSaveProceedRoute,
            })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('additional-KYC-details', {
              id,
              applicant1ReferenceId: referenceIdForSaveProceedRoute,
            })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  const manageSubmit = (values: Values) => {
    const { bankAccountFormCreated = false, showBankIcon = false } = application || {};
    const updatePayloadAndCheckApplicationValue = updatePayloadAndCheckApplication(values, false);
    const checkApplication = updatePayloadAndCheckApplicationValue?.checkApplication;
    if (!checkApplication && checkNdpmsFlow && showBankIcon && bankAccountFormCreated) {
      setFinalValue(values);
      setShowShowForEdit(true);
    } else {
      onSubmit(values);
    }
  };

  // useEffect(() => {
  //   (async function () {
  //     try {
  //       const nationalitiesMdmsMasters = (await dispatch(
  //         getNationalityList()
  //       )) as unknown as nationaliyType;
  //       setMdmsCountriesList(nationalitiesMdmsMasters.countries);
  //     } catch (e) {
  //       console.error((e as Error).message);
  //     }
  //   })();
  // }, []);
  useEffect(() => {
    const { applicants: exisitingApplicants = [], hasPOA = false } = application || {};
    (async function () {
      try {
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
        const statesMdmsMasters = (await dispatch(getStatesList())) as unknown as statesType;
        setMdmsStatesList(statesMdmsMasters.states);
        setContactDetails({
          ...contactDetails,
          hasPOA,
          applicants:
            exisitingApplicants?.length === 0
              ? exisitingApplicants.map((applicant) => {
                  const {
                    nationality = '',
                    status = '',
                    phoneNumberoffice = '',
                    phoneNumberResidence = '',
                    email = '',
                    mobile = '',
                    countryNameAndCode = 'India: +91',
                    countryCode = '+91',
                    kraMobileNumber = '',
                    kraEmail = '',
                    mobileEmailDeclaration = '',
                    mobileDeclaration = '',
                    emailDeclaration = '',
                  } = applicant;
                  const defaultPayload = {
                    nationality,
                    status,
                    phoneNumberoffice,
                    phoneNumberResidence,
                    email,
                    mobile,
                    countryNameAndCode: applicant.countryNameAndCode
                      ? applicant.countryNameAndCode
                      : 'India: +91',
                    countryCode: applicant.countryCode ? applicant.countryCode : '+91',
                    address: {
                      nationality: null,
                      status: null,
                      permanent: getAddressFields('permanent'),
                    },
                    kraMobileNumber,
                    kraEmail,
                    mobileEmailDeclaration,
                    mobileDeclaration,
                    emailDeclaration,
                  };
                  if (
                    ['indian', null].includes(applicant.nationality?.toLowerCase() || '') &&
                    (applicant.status === APPLICANT_STATUS.INDIVIDUAL ||
                      applicantStatusMasters[applicant.status || ''] ===
                        applicantStatusMasters.Individual)
                  ) {
                    return {
                      ...defaultPayload,
                      address: {
                        ...defaultPayload.address,
                        correspondence: getAddressFields('correspondence'),
                      },
                    };
                  }
                  return {
                    ...defaultPayload,
                    address: {
                      ...defaultPayload.address,
                      overseasAddress: getAddressFields('overseas'),
                    },
                  };
                })
              : exisitingApplicants?.map((applicant) => {
                  const {
                    phoneNumberoffice = '',
                    phoneNumberResidence = '',
                    email = '',
                    mobile = '',
                    countryNameAndCode = 'India: +91',
                    countryCode = '+91',
                    kraMobileNumber = '',
                    kraEmail = '',
                    mobileEmailDeclaration = '',
                    mobileDeclaration = '',
                    emailDeclaration = '',
                  } = applicant;
                  const correspondence = getAddressData('correspondence', applicant.addresses);
                  const permanent = getAddressData('permanent', applicant.addresses);
                  const overseas = getAddressData('overseas', applicant.addresses);
                  const defaultPayload = {
                    phoneNumberoffice,
                    phoneNumberResidence,
                    email,
                    mobile,
                    countryNameAndCode: applicant.countryNameAndCode
                      ? applicant.countryNameAndCode
                      : 'India: +91',
                    countryCode: applicant.countryCode ? applicant.countryCode : '+91',
                    address: {
                      nationality: applicant.nationality,
                      status: applicant.status,
                    },
                    kraMobileNumber,
                    kraEmail,
                    mobileEmailDeclaration,
                    mobileDeclaration,
                    emailDeclaration,
                  };
                  const permanentAddressPayload =
                    ['indian', null].includes(applicant.nationality?.toLowerCase() || '') &&
                    (applicantStatusMasters[applicant.status || ''] ===
                      applicantStatusMasters.Individual ||
                      applicant.status === APPLICANT_STATUS.INDIVIDUAL)
                      ? correspondence.permanentAddressSameAsCorresponding
                        ? defaultPayload
                        : {
                            ...defaultPayload,
                            address: {
                              ...defaultPayload.address,
                              permanent: {
                                ...permanent,
                                state: permanent.state
                                  ? isCustodianICICIOrHDFC(selectedCustodian) &&
                                    permanent.country?.toUpperCase() === 'INDIA'
                                    ? permanent.state?.toUpperCase()
                                    : permanent.state
                                  : '',
                                country: permanent.country
                                  ? permanent.country?.toUpperCase()
                                  : 'INDIA',
                              },
                            },
                          }
                      : overseas.permanentAddressSameAsCorresponding
                      ? defaultPayload
                      : {
                          ...defaultPayload,
                          address: {
                            ...defaultPayload.address,
                            permanent: {
                              ...permanent,
                              state: permanent.state
                                ? isCustodianICICIOrHDFC(selectedCustodian) &&
                                  permanent.country?.toUpperCase() === 'INDIA'
                                  ? permanent.state?.toUpperCase()
                                  : permanent.state
                                : '',
                              country: permanent.country
                                ? permanent.country?.toUpperCase()
                                : 'INDIA',
                            },
                          },
                        };
                  return ['indian', null].includes(applicant.nationality?.toLowerCase() || '') &&
                    (applicantStatusMasters[applicant.status || ''] ===
                      applicantStatusMasters.Individual ||
                      applicant.status === APPLICANT_STATUS.INDIVIDUAL)
                    ? {
                        ...permanentAddressPayload,
                        address: {
                          ...permanentAddressPayload.address,
                          correspondence: {
                            ...correspondence,
                            state: correspondence.state
                              ? isCustodianICICIOrHDFC(selectedCustodian) &&
                                correspondence.country?.toUpperCase() === 'INDIA'
                                ? correspondence.state?.toUpperCase()
                                : correspondence.state
                              : '',
                            country: correspondence.country
                              ? correspondence.country?.toUpperCase()
                              : 'INDIA',
                          },
                        },
                      }
                    : {
                        ...permanentAddressPayload,
                        address: {
                          ...permanentAddressPayload.address,
                          overseas: {
                            ...overseas,
                            country: overseas.country ? overseas.country?.toUpperCase() : '',
                          },
                        },
                      };
                }),
          statesDropdown: statesMdmsMasters.states.map((list) => list.name),
          countryDropdown: nationalitiesMdmsMasters.countries.map((list) => list.name),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  return (
    <Formik
      initialValues={contactDetails}
      onSubmit={manageSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, contactDetailsSchema(selectedCustodian), true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues, handleChange, setFieldValue }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <ApplicantDetails
            values={values}
            handleChange={handleChange}
            setValues={setValues}
            mdmsCountriesList={mdmsCountriesList}
            mdmsStatesList={mdmsStatesList}
            application={application}
          />
          <OnEditDialogBox
            open={showDialogForEdit}
            setOpen={setShowShowForEdit}
            handleSubmit={onSubmit}
            values={finalvalue}
          />
          {!!application?.applicants.length && (
            <ProceedSaveLater
              saveLater={() => setFieldValue('saveType', 'save for later')}
              saveAndProceed={() => setFieldValue('saveType', 'save and proceed')}
              loader={loading}
              clickedButton={values.saveType}
            />
          )}
        </Grid>
      )}
    </Formik>
  );
}
