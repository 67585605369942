import { string } from 'yargs';
import {
  AdminStrategyDetailsType,
  DistributorForStrategy,
  investmentAmountsType,
  strategyOfficersDetails,
} from './api-types';

export const GET_STRATEGIES_SUCCESS = 'GET_STRATEGIES_SUCCESS';
export const GET_STRATEGIES = 'GET_STRATEGIES';

export const ADD_STRATEGY_SUCCESS = 'ADD_STRATEGY_SUCCESS';
export const ADD_STRATEGY = 'ADD_STRATEGY';

export const UPDATE_STRATEGY_SUCCESS = 'UPDATE_STRATEGY_SUCCESS';
export const UPDATE_STRATEGY = 'UPDATE_STRATEGY';

export const GET_STRATEGY_SUCCESS = 'GET_STRATEGY_SUCCESS';
export const GET_STRATEGY = 'GET_STRATEGY';

export const GET_OFFICER_STRATEGIES_SUCCESS = 'GET_OFFICER_STRATEGIES_SUCCESS';
export const GET_OFFICER_STRATEGIES = 'GET_OFFICER_STRATEGIES';

export const ADD_OFFICER_STRATEGY_SUCCESS = 'ADD_OFFICER_STRATEGY_SUCCESS';
export const ADD_OFFICER_STRATEGIE = ' ADD_OFFICER_STRATEGIE';

export const UPDATE_OFFICER_STRATEGY_SUCCESS = 'UPDATE_OFFICER_STRATEGY_SUCCESS';
export const UPDATE_OFFICER_STRATEGY = 'UPDATE_OFFICER_STRATEGY';

export const GET_OFFICER_STRATEGY_SUCCESS = 'GET_OFFICER_STRATEGY_SUCCESS';
export const GET_OFFICER_STRATEGIE = 'GET_OFFICER_STRATEGIE';

export type GetStrategiesResponseBody = {
  createdAt: string;
  updatedAt: string;
  id?: string | null;
  strategyName: string;
  strategyType?: string;
  fee: number;
  performanceFee: number | string;
  otherOperationalCharges?: string[];
  investmentamounts?: investmentAmountsType[];
  applicationInvestmentAmountDetails?: investmentAmountsType[];
  investmentAmount: number;
  isActive: boolean;
  benchmark: string;
  exitFee: number;
  maxFee: number;
  minFee: number;
  maxPerformanceFee: number;
  minPerformanceFee: number;
  maxExitFee: number;
  minExitFee: number;
  managementFeeNature: string;
  performanceFeeNature: string;
  entryLoad: string;
  exitLoad: string;
  applicationStrategyManagementFee: number | null;
  distributors?: DistributorForStrategy[];
  feeType?: string;
  performanceFeeHybrid?: number | string;
  feeTypeHybrid?: string;
};

export type GetStrategiesListResponseBody = {
  strategies: AdminStrategyDetailsType[];
};
export type GetOfficerStrategiesListResponseBody = {
  strategies: strategyOfficersDetails[];
};
