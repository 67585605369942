import { CommonLayout } from '../commonComponents';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  Router,
  useParams,
} from 'react-router';
import AdditionalKYCDetails from './additionalKYCDetails';
import BankDetails from './bankDetails';
import {
  InvestorDetailsLayout,
  investorEditDocumentDetailsObj,
  investorEditEndpointExcludingDoc,
  investorEditEndpointIncludingDoc,
} from './components';
import ContactDetails from './contactDetails';
import ContributorDetails from './contributorDetails';
import DistributorDetails from './distributorDetails';
import DocumentDetails from './documentDetails';
import Fatca from './fatca';
import InvestmentPaymentDetails from './investmentPaymentDetails';
import NomineeDetails from './nomineeDetails';
import { getApplicationDetails } from '../../redux-store/actions/application';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { Location } from 'history';
import { RootStateType } from '../../redux-store/reducers';
import { APMIFlow, USER_ROLES } from '../../utils/constant';
import StrategyDetails from './strategyDetails';
import SourceOfInvestment from './sourceOfInvestment';
import riskProfile from './riskProfileDetails';
import RelatedPartyConsent from './relatedPartyDetails';
import DisclosureOfInterest from './disclosureOfInterestDetails';
import DisclosureOfExcluded from './disclosureOfExclusions';

export const Routes = ({
  id,
  applicant1ReferenceId,
}: {
  id: string;
  applicant1ReferenceId?: string;
  applicationType?: string;
}): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  const {
    auth: { role },
    application: { application: _application },
    investor: { token = '' },
  } = useSelector((store: RootStateType) => store);

  const { applicationNumber } = _application || {};
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const { investorEditObj } = useSelector((store: RootStateType) => store.investorEditObj);

  useEffect(() => {
    async function fetchApplication() {
      try {
        if (role === USER_ROLES.INVESTOR && !token) {
          history.push(`/investment-details/${applicant1ReferenceId}/details`);
          return;
        }
        if (
          referenceDetail.applicant_type &&
          (APMIFlow && referenceDetail.applicant_type === '1'
            ? investorEditObj?.find(
                (menu) => menu.endPoint === investorEditDocumentDetailsObj.endPoint
              )
              ? !investorEditEndpointIncludingDoc.includes(
                  history?.location?.pathname?.split('/')?.pop() || ''
                )
              : !investorEditEndpointExcludingDoc.includes(
                  history?.location?.pathname?.split('/')?.pop() || ''
                )
            : !investorEditObj || (investorEditObj && investorEditObj.length === 0))
        ) {
          history.push(`/investment-details/${applicant1ReferenceId}/application-details`);
          return;
        }
        if (id) {
          (await dispatch(getApplicationDetails(id))) as unknown as ApplicationProps;
        } else {
          history.push('/');
          console.error('No application id found');
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    }
    // if (application === null) {
    fetchApplication();
    // }
  }, []);

  return (
    <InvestorDetailsLayout
      applicationId={id}
      applicant1ReferenceId={applicant1ReferenceId}
      applicationNumber={applicationNumber || ''}>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path={path}
            render={() => (
              <Redirect
                to={{
                  pathname: `${path}/distributor-details`,
                  state: { id, applicant1ReferenceId },
                }}
                from={location.pathname}
              />
            )}
          />
          <Route path={`${path}/distributor-details`} component={DistributorDetails} />
          <Route path={`${path}/contributor-details`} component={ContributorDetails} />
          <Route path={`${path}/contact-details`} component={ContactDetails} />
          <Route path={`${path}/additional-KYC-details`} component={AdditionalKYCDetails} />
          <Route path={`${path}/fatca`} component={Fatca} />
          <Route path={`${path}/nominee-details`} component={NomineeDetails} />
          <Route path={`${path}/bank-details`} component={BankDetails} />
          <Route path={`${path}/bank-details-ndpsm`} component={BankDetails} />

          <Route path={`${path}/risk-profiles`} component={riskProfile} />
          <Route path={`${path}/strategy-details`} component={StrategyDetails} />
          <Route path={`${path}/investment-payment-details`} component={InvestmentPaymentDetails} />
          <Route path={`${path}/related-party-consent`} component={RelatedPartyConsent} />
          <Route path={`${path}/disclosure-of-interest`} component={DisclosureOfInterest} />
          <Route path={`${path}/disclosure-of-exclusions`} component={DisclosureOfExcluded} />
          <Route path={`${path}/document-details`} component={DocumentDetails} />
          <Route path={`${path}/document-details-ndpms`} component={DocumentDetails} />
          {/* <Route path={`${path}/source-of-investment`} component={SourceOfInvestment} /> */}
        </Switch>
      </Router>
    </InvestorDetailsLayout>
  );
};

export const InvestorPhotoCaptureEditRoutes = ({
  id,
  applicant1ReferenceId,
}: {
  id: string;
  applicant1ReferenceId?: string;
}): JSX.Element => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const {
    application: { application: _application },
  } = useSelector((store: RootStateType) => store);

  const { applicationNumber } = _application || {};

  return (
    <InvestorDetailsLayout
      applicationId={id}
      applicant1ReferenceId={applicant1ReferenceId}
      applicationNumber={applicationNumber || ''}
      investorLoginType={'investor_photoCapture'}>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path={path}
            render={() => (
              <Redirect
                to={{
                  pathname: `${path}/distributor-details`,
                  state: { id, applicant1ReferenceId },
                }}
                from={location.pathname}
              />
            )}
          />
          <Route path={`${path}/distributor-details`} component={DistributorDetails} />
          <Route path={`${path}/contributor-details`} component={ContributorDetails} />
          <Route path={`${path}/contact-details`} component={ContactDetails} />
          <Route path={`${path}/additional-KYC-details`} component={AdditionalKYCDetails} />
          <Route path={`${path}/fatca`} component={Fatca} />
          <Route path={`${path}/nominee-details`} component={NomineeDetails} />
          <Route path={`${path}/bank-details`} component={BankDetails} />
          <Route path={`${path}/risk-profiles`} component={riskProfile} />
          <Route path={`${path}/strategy-details`} component={StrategyDetails} />
          <Route path={`${path}/investment-payment-details`} component={InvestmentPaymentDetails} />
          <Route path={`${path}/related-party-consent`} component={RelatedPartyConsent} />
          <Route path={`${path}/disclosure-of-interest`} component={DisclosureOfInterest} />
          <Route path={`${path}/disclosure-of-exclusions`} component={DisclosureOfExcluded} />
          <Route path={`${path}/document-details`} component={DocumentDetails} />
          {/* <Route path={`${path}/source-of-investment`} component={SourceOfInvestment} /> */}
        </Switch>
      </Router>
    </InvestorDetailsLayout>
  );
};

const ApplicationRoutes = ({
  location,
}: {
  location: Location<{ id: string; applicant1ReferenceId?: string; applicationType?: string }>;
}): JSX.Element => {
  const { id = '', applicant1ReferenceId = '', applicationType = '' } = location.state || {};
  return (
    <CommonLayout>
      <Routes
        id={id}
        applicant1ReferenceId={applicant1ReferenceId}
        applicationType={applicationType}
      />
    </CommonLayout>
  );
};

export default ApplicationRoutes;
