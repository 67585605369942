import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Hidden,
  LinearProgress,
  SelectChangeEvent,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {
  AddDistributorRequestBody,
  DistributorListType,
  newRmType,
  RmsType,
  StrategyTypeInDistributorSetup,
} from '../../redux-store/types/api-types';
import { useDispatch } from 'react-redux';
import {
  addDistributor,
  editDistributor,
  getDistributorById,
  getRMsList,
} from '../../redux-store/actions/userManagement';
import { DistributorSchema } from '../../utils/schema';
import MFSelectField from '../../lib/formik/SelectField';
import { Location } from 'history';
import { CountryCodesDropDown } from '../commonComponents';
import { DistributorTypesForUserManageMent } from '../../utils/constant';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { ColumnType, DataTable } from '../DataTable';
import { MultipleSelect } from '../../lib/formik/MultipleSelectField';
import { getStrategies } from '../../redux-store/actions/strategies';
import { GetStrategiesResponseBody } from '../../redux-store/types/strategies';
import { sortList, sortStrategyPerformenceFee } from '../../utils/utilityFunctions';

export default function AddDistributor({
  location,
}: {
  location: Location<{ typeOfDistributor: string; distributorId: number }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  //const [formikValues, setFormikValues] = useState<AddDistributorRequestBody | null>();
  const { typeOfDistributor, distributorId } = location.state || {};
  const [rmDropDownMaster, setRmDropDownMaster] = useState<RmsType[]>([]);
  type UpdatedAddDistributorRequestBody = AddDistributorRequestBody & {
    strategyIds: string[] | number[] | null;
  };
  const initialValues: UpdatedAddDistributorRequestBody = {
    name: '',
    email: '',
    countryNameAndCode: 'India: +91',
    countryCode: '+91',
    phone: '',
    panNumber: '',
    arnCode: '',
    pincode: '',
    buildingNo: '',
    streetName: '',
    city: '',
    state: '',
    type: typeOfDistributor || '',
    country: '',
    isActive: true,
    rmId: null,
    strategyIds: [],
    strategies: [],
  };

  // useEffect(() => {
  //   let isComponentActive = true;
  //   (async function () {
  //     try {
  //       if (!formikValues) return;
  //       if (distributor) {
  //         await dispatch(editDistributor(id, formikValues));
  //       } else {
  //         await dispatch(addDistributor(formikValues));
  //       }

  //       if (!isComponentActive) return;
  //       history.push('distributors', { distributorType: formikValues.type });
  //     } catch (e) {
  //       console.error((e as Error).message);
  //     }
  //   })();
  //   return () => {
  //     isComponentActive = false;
  //   };
  // }, [formikValues]);

  const [distributorDetails, setDistributorDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  const [strategyList, setStrategyList] = useState<GetStrategiesResponseBody[]>([]);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const resStrategies = (await dispatch(
          getStrategies({ distributorId: distributorId })
        )) as unknown as GetStrategiesResponseBody[];
        setStrategyList(resStrategies);
        const res = (await dispatch(getRMsList({ isActive: true }))) as unknown as newRmType;
        if (distributorId) {
          const getDistributorDetails = (await dispatch(
            getDistributorById(distributorId)
          )) as unknown as DistributorListType;
          const {
            name,
            panNumber,
            arnCode,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            id,
            type,
            rmId,
            isActive,
            strategies,
          } = getDistributorDetails;
          const { countryCode, countryNameAndCode, email, phone } = getDistributorDetails.user || {
            countryCode: '+91',
            countryNameAndCode: 'India: +91',
            email: '',
            phone: '',
          };
          const _AllStrategies = (await resStrategies?.map((item, index) => {
            const { performanceFee, feeType, performanceFeeHybrid, feeTypeHybrid, ...rest } = item;
            const _strategies = strategies?.find((_item) => Number(_item.id) === Number(item.id));
            if (_strategies) {
              return {
                ..._strategies,
                performanceFee: _strategies?.performanceFee ? _strategies?.performanceFee : '',
                feeType: _strategies?.feeType ? _strategies?.feeType : '',
                inActive: true,
                sno: index,
              };
            }
            return {
              ...rest,
              performanceFee: '',
              feeType: '',
              inActive: false,
              sno: index,
            };
          })) as unknown as StrategyTypeInDistributorSetup[];
          setDistributorDetails({
            ...distributorDetails,
            name,
            panNumber,
            arnCode,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            email,
            phone,
            id,
            type,
            rmId,
            isActive,
            countryNameAndCode: countryNameAndCode ? countryNameAndCode : 'India: +91',
            countryCode: countryCode ? countryCode : '+91',
            strategies: sortStrategyPerformenceFee(
              _AllStrategies,
              strategies?.map((item) => item.id.toString()) || []
            ) as StrategyTypeInDistributorSetup[],
            strategyIds: (strategies?.map((item) => item.id) as number[] | string[]) || [],
          });
        } else {
          const _AllStrategies = resStrategies?.map((item, index) => {
            return {
              ...item,
              performanceFee: '',
              feeType: '',
              inActive: false,
              sno: index,
            };
          }) as unknown as StrategyTypeInDistributorSetup[];
          setDistributorDetails({ ...distributorDetails, strategies: _AllStrategies });
        }
        if (!isComponentAlive) return;
        const { rms } = res || {};
        setRmDropDownMaster(rms);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);
  const onSubmit = async (values: UpdatedAddDistributorRequestBody) => {
    try {
      const { strategyIds, ...rest } = values;
      const _values = {
        ...rest,
        countryCode: values.countryNameAndCode?.split(':')[1].trim(),
        strategies: values.strategies
          ?.filter((item) => item.inActive)
          .map((item) => {
            const { inActive, sno, ...rest } = item;
            return {
              ...rest,
              performanceFee: item.performanceFee,
              feeType: 'hybrid',
            };
          }),
      };
      if (distributorId) {
        await dispatch(editDistributor(distributorId, _values));
        history.push('distributor-details', { distributorType: values.type, distributorId });
      } else {
        await dispatch(addDistributor(_values));
        history.push('distributors', { distributorType: values.type });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
    //setFormikValues({ ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() });
  };

  const strategyPerformenceFeeColumns: ColumnType[] = [
    {
      header: 'Strategy Name',
      label: 'strategyIds',
      renderCell: (row: StrategyTypeInDistributorSetup) => {
        return (
          <Typography
            sx={{
              textTransform: 'capitalize',
              mt: { xs: 1, md: 2 },
              mb: 1,
            }}>
            {row.strategyName}
          </Typography>
        );
      },
    },
    {
      header: 'Performance Fee',
      label: 'performanceFee',
      renderEditDeleteCell: (row: StrategyTypeInDistributorSetup, index): JSX.Element => {
        return (
          <>
            <MFTextField
              name={`strategies.${index}.performanceFee`}
              placeholder="Enter Performance Fee"
            />
          </>
        );
      },
    },
  ];
  return (
    <>
      <Formik
        initialValues={distributorDetails}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={DistributorSchema}>
        {({ handleSubmit, values, setFieldValue, setValues, errors }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            {/* {console.log(errors)} */}
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    distributorId
                      ? history.push(`distributor-details`, {
                          distributorType: values.type,
                          distributorId,
                        })
                      : history.push('distributors', { distributorType: values.type })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  User Management
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    distributorId
                      ? history.push(`distributor-details`, {
                          distributorType: values.type,
                          distributorId,
                        })
                      : history.push('distributors', { distributorType: values.type })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                  textTransform: 'capitalize',
                }}>
                {typeOfDistributor
                  ? 'Onboarding Distributor Details'
                  : `${values.type} Distributor Detail`}
              </Typography>
            </Box>
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                {typeOfDistributor && (
                  <>
                    <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <MFSelectField
                          name="type"
                          items={Object.keys(DistributorTypesForUserManageMent).map((_ele) => ({
                            key: _ele,
                            value: DistributorTypesForUserManageMent[_ele],
                          }))}
                          label="Distributor Type *"
                          onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                            setValues({
                              ...values,
                              ...initialValues,
                              strategies: values.strategies.map((item) => ({
                                ...item,
                                performanceFee: '',
                                inActive: false,
                              })),
                              type: value as unknown as string,
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="name"
                      label="Distributor Name *"
                      placeholder="Enter Distributor Name"
                    />
                  </Grid>
                  {values.type === 'individual' && (
                    <>
                      <Grid item xs={12} sm={6} md={4}>
                        <MFTextField name="email" label="Email Id *" placeholder="Enter Email Id" />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <MFTextField
                          name="phone"
                          label="Mobile Number *"
                          placeholder="Enter Mobile Number"
                          // startAdornment={
                          //   <CountryCodesDropDown
                          //     name={`countryNameAndCode`}
                          //     value={values.countryNameAndCode}
                          //   />
                          // }
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="panNumber"
                      label="PAN"
                      placeholder="Enter Pan"
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      onChange={(e) => {
                        setFieldValue('panNumber', e.target.value.toUpperCase());
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="arnCode"
                      label="ARN Code *"
                      placeholder="Enter ARN Code"
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      onChange={(e) => {
                        setFieldValue('arnCode', e.target.value.toUpperCase());
                      }}
                    />
                  </Grid>
                  {values.type === 'individual' && (
                    <Grid item xs={12} sm={6} md={4}>
                      {/* <MFTextField name="rmName" label="RM Name" placeholder="Enter RM Name" /> */}
                      <SearchableSelect
                        name="rmId"
                        label="RM Name *"
                        items={rmDropDownMaster
                          .sort((rm1, rm2) =>
                            (rm1['name'] || '')
                              .toString()
                              .localeCompare((rm2['name'] || '').toString())
                          )
                          .map((item) => ({
                            key: item.name,
                            value: item.id,
                          }))}
                        searchFieldPlaceholder={'Search RM'}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="pincode" label="Pincode" placeholder="Enter Pincode" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="buildingNo"
                      label="Building Number"
                      placeholder="Enter Building Number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="streetName"
                      label="Street Name"
                      placeholder="Enter Street Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="state" label="State" placeholder="Enter State" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="city" label="City" placeholder="Enter City" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="country" label="Country" placeholder="Enter Country" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <MultipleSelect
                      name="strategyIds"
                      label="Applicable Strategies"
                      items={strategyList?.map((item) => ({
                        key: item.id as string | number,
                        value: item?.strategyName,
                      }))}
                      renderText="Selected Strategies"
                      onChange={({ target: { value } }) => {
                        const _strategyIds = sortList(
                          (values?.strategyIds || []) as string[] | number[],
                          ((value as string[] | number[])[
                            (value as string[] | number[])?.length - 1
                          ] === 'all'
                            ? values?.strategyIds?.length ===
                              strategyList.map((item) => item.id).length
                              ? []
                              : strategyList.map((item) => item.id)
                            : value) as number[] | string[]
                        ) as number[] | string[];

                        const _strategies = values?.strategies?.map((item) => {
                          return {
                            ...item,
                            inActive: (_strategyIds || [])
                              .map((_strategyIds) => _strategyIds.toString())
                              .includes(item.id.toString()),
                          };
                        });
                        setValues({
                          ...values,
                          strategyIds: _strategyIds,
                          strategies: sortStrategyPerformenceFee(
                            _strategies,
                            (_strategyIds || []).map((_strategyIds) => _strategyIds.toString())
                          ) as StrategyTypeInDistributorSetup[],
                        });
                      }}
                    />
                  </Grid>
                  {values.strategyIds && (
                    <Grid item xs={12} sm={12} md={12}>
                      <DataTable
                        tableData={values.strategies?.filter((obj: any) => obj.inActive)}
                        tableHeader={strategyPerformenceFeeColumns}
                        noOfPages={1}
                        renderAdditionalRow={true}
                        tableBodyCustomStyles={{
                          '.MuiTableCell-root': {
                            borderBottom: 'none',
                          },
                        }}
                        tableHeaderCustomStyles={{
                          '.MuiTableCell-head': {
                            fontFamily: 'Poppins, sans-serif',
                            fontSize: 14,
                            fontWeight: 500,
                            color: 'primary.main',
                            textAlign: 'left',
                            pl: 2,
                          },
                        }}
                        rowCustomStyles={{
                          '.MuiTableCell-root': {
                            py: 0,
                            overflowWrap: 'anywhere',
                            textAlign: 'left',
                            verticalAlign: 'top',
                          },
                        }}
                        boxStyles={{ minHeight: '0px', minWidth: 450, maxHeight: 'unset' }}
                      />
                    </Grid>
                  )}
                </Grid>
                <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                  <MFSubmitButton label="Save" />
                </Box>
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
