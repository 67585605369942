import {
  Box,
  Grid,
  Typography,
  Hidden,
  IconButton,
  LinearProgress,
  CardMedia,
  Chip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles, Item, ItemDetails } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/contributor';
import {
  DistributorListType,
  RmsType,
  StrategyTypeInDistributorSetup,
} from '../../redux-store/types/api-types';
import { getDistributorById, getRMById } from '../../redux-store/actions/userManagement';
import { Location } from 'history';
import { ColumnType, DataTable } from '../DataTable';

export default function UserManagementDistributorDetails({
  location,
}: {
  location: Location<{ distributorId: number }>;
}): JSX.Element {
  const { distributorId: id } = location.state || { distributorId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [distributorDetails, setDistributorDetails] = useState<DistributorListType>();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        if (id) {
          const getDistributorDetails = (await dispatch(
            getDistributorById(id)
          )) as unknown as DistributorListType;
          setDistributorDetails(getDistributorDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);
  const strategyPerformenceFeeColumns: ColumnType[] = [
    {
      header: 'Strategy Name',
      label: 'strategyId',
      valueGetter: (row: StrategyTypeInDistributorSetup) =>
        distributorDetails?.strategies?.find((item) => item?.id === row?.id)?.strategyName || 'N/A',
    },
    {
      header: 'Performance Fee',
      label: 'performanceFee',
      valueGetter: (row: StrategyTypeInDistributorSetup) => row.performanceFee || 'N/A',
    },
  ];
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
          py: { xs: 2, sm: 5 },
          px: { xs: 0, sm: 5 },
          mt: { xs: 2, sm: 5 },
        }}>
        <Hidden smUp={true}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              onClick={() =>
                history.push('distributors', {
                  distributorType: distributorDetails?.type,
                })
              }>
              <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
              }}>
              Back
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              my: 2,
              py: 2,
              pl: { xs: 3, sm: 0 },
              color: 'primary.main',
              bgcolor: 'rgba(238, 244, 251, 0.5)',
            }}>
            <PersonOutlineOutlinedIcon fontSize="large" />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                pl: 1,
              }}>
              User Management
            </Typography>
          </Box>
        </Hidden>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Hidden only="xs">
              <IconButton
                sx={{ height: 'fit-content', p: 0 }}
                onClick={() =>
                  history.push('distributors', {
                    distributorType: distributorDetails?.type,
                  })
                }>
                <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
              </IconButton>
            </Hidden>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
                pl: { xs: 2, sm: 0 },
                textTransform: 'capitalize',
              }}>
              {distributorDetails?.type
                ? distributorDetails?.type + ' Distributor Detail'
                : 'Distributor Detail'}
            </Typography>
          </Box>
          <IconButton>
            <CardMedia
              component="img"
              src="/images/edit-icon-outlined.svg"
              alt="Edit Icon"
              sx={{ width: 'unset' }}
              onClick={() =>
                history.push('edit-distributor', { distributorId: distributorDetails?.id })
              }
            />
          </IconButton>
        </Box>
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          distributorDetails && (
            <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
              <React.Fragment>
                <Gridstyles>
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={6}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <KeyValue title={'Distributor Name'} description={distributorDetails.name} />
                    {distributorDetails.type === 'individual' && (
                      <>
                        <KeyValue title={'Email Id'} description={distributorDetails.user.email} />
                        <KeyValue
                          title={'Mobile Number'}
                          description={distributorDetails.user.phone}
                        />
                      </>
                    )}
                    <KeyValue title={'Pan'} description={distributorDetails.panNumber} />
                    <KeyValue title={'ARN Code'} description={distributorDetails.arnCode} />
                    {distributorDetails.type === 'individual' && (
                      <KeyValue title={'RM Name'} description={distributorDetails.rm.name} />
                    )}
                    <KeyValue title={'Pincode'} description={distributorDetails.pincode} />
                    <KeyValue
                      title={'Building Number'}
                      description={distributorDetails.buildingNo}
                    />
                    <KeyValue title={'Street Name'} description={distributorDetails.streetName} />
                    <KeyValue title={'State'} description={distributorDetails.state} />
                    <KeyValue title={'City'} description={distributorDetails.city} />
                    <KeyValue title={'Country'} description={distributorDetails.country} />
                    <Grid item xs={12}>
                      <Item>{`Applicable Strategies`}</Item>
                      {Number(distributorDetails?.strategies?.length) > 0 ? (
                        <Box
                          sx={{
                            border: 0.5,
                            // borderColor: 'rgba(41, 49, 57, 0.7)',
                            borderColor: 'primary.main',
                            p: 3,
                            mb: 3,
                            mt: 2,
                            borderRadius: 0.5,
                          }}>
                          {distributorDetails?.strategies?.map((strategy) => {
                            return (
                              <React.Fragment key={strategy.id}>
                                <Chip
                                  label={`${strategy.strategyName}`}
                                  sx={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: 'rgba(41, 49, 57, 0.7)',
                                    // p: 2,
                                    m: 0.8,
                                  }}
                                />
                              </React.Fragment>
                            );
                          })}
                        </Box>
                      ) : (
                        <ItemDetails>-</ItemDetails>
                      )}
                    </Grid>
                    {distributorDetails?.strategies && (
                      <Grid item xs={12} sm={12} md={12}>
                        <DataTable
                          tableData={
                            distributorDetails?.strategies?.length
                              ? distributorDetails?.strategies
                              : []
                          }
                          tableHeader={strategyPerformenceFeeColumns}
                          noOfPages={1}
                          renderAdditionalRow={true}
                          tableBodyCustomStyles={{
                            '.MuiTableCell-root': {
                              borderBottom: 'none',
                            },
                          }}
                          tableHeaderCustomStyles={{
                            '.MuiTableCell-head': {
                              fontFamily: 'Poppins, sans-serif',
                              fontSize: 14,
                              fontWeight: 500,
                              color: 'primary.main',
                              textAlign: 'left',
                              pl: 2,
                            },
                          }}
                          rowCustomStyles={{
                            '.MuiTableCell-root': {
                              py: 0,
                              overflowWrap: 'anywhere',
                              textAlign: 'left',
                              verticalAlign: 'top',
                            },
                          }}
                          boxStyles={{ minHeight: '0px', minWidth: 450, maxHeight: 'unset' }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Gridstyles>
              </React.Fragment>
            </Grid>
          )
        )}
      </Box>
    </>
  );
}
