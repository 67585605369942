import trimOnPayload from '../../utils/utilityFunctions';
import { CALL_API } from '../middleware';
import { mitcAccessType, PortfolioManagerDetailsType } from '../types/api-types';

import {
  GET_PORTFOLIOMANAGERBYID,
  GET_PORTFOLIOMANAGERBYID_SUCCESS,
  ADD_PORTFOLIOMANAGER_SUCCESS,
  ADD_PORTFOLIOMANAGER,
  EDIT_PORTFOLIOMANAGER_SUCCESS,
  EDIT_PORTFOLIOMANAGER,
  GET_PORTFOLIOMANAGER_SUCCESS,
  GET_PORTFOLIOMANAGER,
  GET_MITC_ACCESS_SUCCESS,
  GET_MITC_ACCESS,
} from '../types/portFolioManager';

export const addPorfolioManager =
  (body: PortfolioManagerDetailsType) =>
  async (dispatch: any): Promise<PortfolioManagerDetailsType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/mitc`,
        method: 'POST',
        types: [ADD_PORTFOLIOMANAGER_SUCCESS, ADD_PORTFOLIOMANAGER],
        body: trimOnPayload(body),
      },
    });
  };
export const getPorfolioManager =
  () =>
  async (dispatch: any): Promise<PortfolioManagerDetailsType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/mitc`,
        method: 'GET',
        types: [GET_PORTFOLIOMANAGER_SUCCESS, GET_PORTFOLIOMANAGER],
      },
    });
  };
export const getPorfolioManagerById =
  (Id: number) =>
  async (dispatch: any): Promise<PortfolioManagerDetailsType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/mitc/${Id}`,
        method: 'GET',
        types: [GET_PORTFOLIOMANAGERBYID_SUCCESS, GET_PORTFOLIOMANAGERBYID],
      },
    });
  };

export const editPorfolioManager =
  (Id: number, body: PortfolioManagerDetailsType) =>
  async (dispatch: any): Promise<PortfolioManagerDetailsType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/mitc/${Id}`,
        method: 'POST',
        types: [EDIT_PORTFOLIOMANAGER_SUCCESS, EDIT_PORTFOLIOMANAGER],
        body: trimOnPayload(body),
      },
    });
  };

export const mitcAccess =
  () =>
  async (dispatch: any): Promise<mitcAccessType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/mitcAccess`,
        method: 'GET',
        types: [GET_MITC_ACCESS_SUCCESS, GET_MITC_ACCESS],
      },
    });
  };
