import {
  Button,
  Dialog,
  Hidden,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import AddIcon from '@mui/icons-material/Add';
import { DataTable } from '../DataTable';
import { useEffect, useState, useMemo, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { ConfirmationDialog } from '../commonComponents';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { GetStrategiesResponseBody } from '../../redux-store/types/strategies';
import {
  getOfficers,
  getStrategies,
  getStrategyById,
  updateStrategy,
} from '../../redux-store/actions/strategies';
import { accessType } from '../../redux-store/types/funds';
import { fundAccess } from '../../redux-store/actions/funds';
import {
  AdminStrategyDetailsType,
  strategyOfficersDetails,
} from '../../redux-store/types/api-types';
import { StrategyAndOfficersTypeManagement } from '../../utils/constant';
import { Location } from 'history';

export const isTypeStrategy = (val: string): boolean => {
  return (
    StrategyAndOfficersTypeManagement[val || ''] === StrategyAndOfficersTypeManagement?.strategy
  );
};
export const isTypeOfficers = (val: string): boolean => {
  return (
    StrategyAndOfficersTypeManagement[val || ''] === StrategyAndOfficersTypeManagement?.principal ||
    StrategyAndOfficersTypeManagement[val || ''] === StrategyAndOfficersTypeManagement?.compliance
  );
};
export const AccessHeaderForStrategy = (): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box display={'flex'} flexDirection={'column'}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: 14,
            color: 'primary.main',
            fontWeight: 500,
          }}
          component="span">
          Access
          <InfoOutlinedIcon
            color="info"
            fontSize="small"
            onClick={handleOpen}
            sx={{ position: 'relative', top: 4 }}
          />
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: 12,
            color: 'primary.main',
            fontWeight: 500,
          }}>
          Enable/Disable
        </Typography>
      </Box>
      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          '.MuiPaper-root ': {
            maxWidth: 550,
            p: { xs: 2, sm: '15px 20px 45px' },
            borderRadius: '10px',
            height: 'unset',
            overflowY: 'auto',
          },
        }}>
        <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
          <CloseIcon fontSize="medium" />
        </IconButton>
        <Box sx={{ mb: 2, textAlign: 'center', '.MuiSvgIcon-root': { fontSize: '3.1875rem' } }}>
          <InfoOutlinedIcon color="info" fontSize="large" />
        </Box>
        <Box
          sx={{
            width: { xs: '100%', sm: '80%' },
            mx: 'auto',
            '.MuiTypography-root': {
              fontSize: 20,
              fontWeight: 500,
              textAlign: 'center',
            },
          }}>
          {/* <Typography sx={{ color: 'secondary.main', lineHeight: '35px' }}>
            Are you sure you want to disable this strategy ?
          </Typography> */}
          <Typography sx={{ color: 'secondary.main', lineHeight: '35px', mt: 2 }}>
            Please ensure that you have at least one active strategy before disabling the strategy.
          </Typography>
        </Box>
      </Dialog>
    </>
  );
};

export default function StrategyList({
  location,
}: {
  location: Location<{ officersType: string }>;
}): JSX.Element {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [officersType, setOfficerType] = useState(
    location.state?.officersType ? location.state.officersType : 'strategy'
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [strategyData, setStrategyData] = useState<AdminStrategyDetailsType[]>();
  const [officersData, setOfficersData] = useState<strategyOfficersDetails[]>();
  const [access, setAccess] = useState(false);
  const [openDialog, setDialog] = useState<{ StrategyData: AdminStrategyDetailsType } | null>(null);
  const history = useHistory();
  const handleTablesChange = async (type: string) => {
    if (isTypeStrategy(type)) {
      const res = (await dispatch(getStrategies())) as unknown as AdminStrategyDetailsType[];
      setStrategyData(res);
    } else if (isTypeOfficers(type)) {
      const officerRes = (await dispatch(getOfficers())) as unknown as strategyOfficersDetails[];
      const officersData = officerRes.filter((officer) => officer?.type === type) || [];
      setOfficersData(officersData);
    }
  };
  const getHeadingName = (type: string): string => {
    if (isTypeStrategy(type)) {
      return 'Strategies';
    } else if (isTypeOfficers(type)) {
      return `${StrategyAndOfficersTypeManagement[type || '']}s`;
    }
    return '';
  };
  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        handleTablesChange(officersType);
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        if (!isComponentActive) {
          return;
        }
        setAccess(fundSetupAccess);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  const AccessViewOrEditCell = [
    {
      header: AccessHeaderForStrategy,
      renderCell: (row: AdminStrategyDetailsType): JSX.Element => {
        return (
          <>
            <Stack
              sx={{
                alignItems: { xs: 'baseline', sm: 'center' },
                '.MuiSwitch-root': {
                  paddingLeft: { xs: '0px', sm: '12px' },
                  width: { xs: '52px', sm: '58px' },
                },
                '.MuiSwitch-switchBase': { paddingLeft: { xs: '0px', sm: '9px' } },
              }}>
              <Switch
                sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                checked={row.isActive}
                onChange={() => setDialog({ StrategyData: row })}
                disabled={!access}
              />
            </Stack>
          </>
        );
      },
    },
  ];

  const columns: any = [
    {
      header: 'Strategy Name',
      renderCell: (row: AdminStrategyDetailsType) => {
        return (
          <Typography
            variant="h5"
            sx={{
              color: '#61D1D6',
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: '14px',
              ':hover': {
                color: 'primary.main',
              },
            }}
            component={RouterLink}
            to={{ pathname: 'strategy-details', state: { StrategyId: row.id } }}>
            {row.strategyName || 'N/A'}
          </Typography>
        );
      },
    },
  ];

  const officersColumn: any = [
    {
      header: 'Name',
      renderCell: (row: strategyOfficersDetails) => {
        return (
          <Typography
            variant="h5"
            sx={{
              color: '#61D1D6',
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: '14px',
              ':hover': {
                color: 'primary.main',
              },
            }}
            component={RouterLink}
            to={{
              pathname: 'officer-details',
              state: { officerId: row.id, officersType: row?.type },
            }}>
            {row.name || 'N/A'}
          </Typography>
        );
      },
    },
    {
      header: 'Email Id',
      renderCell: (row: strategyOfficersDetails) => {
        return <Typography>{row.email || 'N/A'}</Typography>;
      },
    },
    {
      header: 'Mobile Number',
      renderCell: (row: strategyOfficersDetails) => {
        return <Typography> {row.phone || 'N/A'}</Typography>;
      },
    },
  ];

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const handleChange = async (event: SelectChangeEvent) => {
    setLoading(true);
    const type = event.target.value || '';
    setOfficerType(type);
    await handleTablesChange(type);
    setLoading(false);
  };
  return (
    <>
      <Box sx={{ bgcolor: 'common.white', px: 4 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pt: { xs: 2, sm: 7 },
            pb: { xs: 2, sm: 4 },
            flexDirection: { lg: 'row', xs: 'column' },
          }}>
          <Typography sx={{ fontSize: { xs: 15, sm: 25 }, fontWeight: 500 }}>
            {getHeadingName(officersType)}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // flexDirection: { lg: 'row', xs: 'column' },
              justifyContent: 'space-between',
            }}>
            <Select
              sx={{ mr: { xs: 0, sm: 4 } }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={officersType}
              onChange={handleChange}>
              {Object.keys(StrategyAndOfficersTypeManagement).map((item) => (
                <MenuItem value={item} key={item}>
                  {StrategyAndOfficersTypeManagement[item]}
                </MenuItem>
              ))}
            </Select>

            {(isTypeStrategy(officersType) ? access : (officersData || [])?.length < 1 && access) &&
              !isLoading && (
                <>
                  <Hidden only="xs">
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      sx={{
                        color: 'primary.main',
                        fontWeight: 500,
                        fontSize: 14,
                        mr: 2.5,
                        py: 0.5,
                        px: 3,
                      }}
                      onClick={() =>
                        officersType && isTypeOfficers(officersType)
                          ? history.push('add-officer', { officersType: officersType })
                          : history.push('add-strategy')
                      }>
                      Add {StrategyAndOfficersTypeManagement[officersType || '']}
                    </Button>
                  </Hidden>
                  <Hidden smUp={true}>
                    <Button
                      variant="text"
                      startIcon={<AddIcon />}
                      sx={{
                        color: 'primary.main',
                        fontWeight: 500,
                        fontSize: 13,
                        mr: 2.5,
                        py: 0.5,
                        px: 3,
                        whiteSpace: 'nowrap',
                      }}
                      onClick={() =>
                        officersType && officersType && isTypeOfficers(officersType)
                          ? history.push('add-officer', { officersType: officersType })
                          : history.push('add-strategy')
                      }>
                      Add {StrategyAndOfficersTypeManagement[officersType || '']}
                    </Button>
                  </Hidden>
                </>
              )}
          </Box>
        </Box>
        {useMemo(
          () => (
            <DataTable
              tableData={
                (officersType && isTypeStrategy(officersType)
                  ? strategyData
                  : isTypeOfficers(officersType)
                  ? officersData
                  : []) || []
              }
              tableHeader={
                isTypeStrategy(officersType)
                  ? [...columns, ...AccessViewOrEditCell]
                  : [...officersColumn]
              }
              currentPage={currentPage}
              noOfPages={1}
              isLoading={isLoading}
              paginationAction={handlePageChange}
              renderAdditionalRow={true}
              tableBodyCustomStyles={{
                '.MuiTableCell-root': {
                  borderBottom: 'none',
                },
              }}
              tableHeaderCustomStyles={{
                '&:last-child th': { border: 0 },
                '.MuiTableCell-head': {
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: 14,
                  fontWeight: 500,
                  color: 'primary.main',
                },
              }}
            />
          ),
          [columns, isLoading, officersType]
        )}
      </Box>
      <ConfirmationDialog
        message={`Are you sure you want to ${
          openDialog?.StrategyData.isActive ? 'disable' : 'enable'
        } the this Strategy ?`}
        open={openDialog !== null}
        setOpen={() => setDialog(null)}
        onSave={async () => {
          setDialog(null);
          try {
            if (openDialog) {
              const GetStrategyDetail = (await dispatch(
                getStrategyById(openDialog.StrategyData.id as string)
              )) as unknown as AdminStrategyDetailsType;
              const updatedRes = (await dispatch(
                updateStrategy(Number(openDialog.StrategyData.id), {
                  ...openDialog.StrategyData,
                  ...GetStrategyDetail,
                  isActive: !openDialog?.StrategyData.isActive,
                })
              )) as unknown as AdminStrategyDetailsType;
              setStrategyData(
                strategyData?.map((strategy) => {
                  if (Number(strategy.id) === Number(updatedRes.id)) {
                    return {
                      ...strategy,
                      ...updatedRes,
                    };
                  }
                  return strategy;
                })
              );
            }
          } catch (e) {
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setDialog(null)}
      />
    </>
  );
}
